<template>
  <div>
    <v-btn
      v-model="isOpen"
      depressed
      large
      color="white"
      @click="toggleNotificationDrawer"
    >
      <v-badge
        v-if="notifications.length > 0"
        color="error"
        :content="notifications.length"
      >
        <v-icon>mdi-bell-outline</v-icon>
      </v-badge>
      <v-icon v-else>
        mdi-bell-outline
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
  },
  watch: {
    isOpen(value) {
      this.toggleDrawer(value);
    },
  },
  methods: {
    ...mapMutations({
      toggleDrawer: "drawer/toggleDrawer",
    }),
    toggleNotificationDrawer(value) {
      this.toggleDrawer(value);
    },
  },
};
</script>

<style></style>
