import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {
    async identityIqLogin(context, payload) {
      try {
        const response = await axios.post(
          `/api/v1/profile/IdentityIQ/${payload.id}`,
          JSON.stringify(payload.identityIq)
        );
        return response.data;
        // const response = await fetch("/api/v1/identityIq/login", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async identityIqLogout() {
      try {
        const response = await axios.post("/api/v1/identityIq/logout");
        return response.data;
        // const response = await fetch("/api/v1/identityIq/logout", {
        //   method: "POST",
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async myFreeScoreNowLogin(context, payload) {
      try {
        const response = await axios.post(
          `/api/v1/profile/MyFreeScoreNow/${payload.id}`,
          JSON.stringify(payload.myFreeScoreNow)
        );
        return response.data;
        // const response = await fetch("/api/v1/myFreeScoreNow/login", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async myFreeScoreNowLogout() {
      try {
        const response = await axios.post("/api/v1/myFreeScoreNow/logout");
        return response.data;
        // const response = await fetch("/api/v1/myFreeScoreNow/logout", {
        //   method: "POST",
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async smartCreditLogin(context, payload) {
      try {
        const response = await axios.post(
          `/api/v1/profile/SmartCredit/${payload.id}`,
          JSON.stringify(payload.smartCredit)
        );
        return response.data;
        // const response = await fetch("/api/v1/smartCredit/login", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async smartCreditLogout() {
      try {
        const response = await axios.post("/api/v1/smartCredit/logout");
        return response.data;
        // const response = await fetch("/api/v1/smartCredit/logout", {
        //   method: "POST",
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
