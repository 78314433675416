import Vue from 'vue'
import Vuex from 'vuex'

import drawer from './modules/drawer.js'
import client from './modules/client.js'
import profile from './modules/profile.js'
import credit from './modules/credit.js'
import setting from './modules/setting.js'
import cdn from './modules/cdn.js'
import employee from './modules/employee.js'
import affiliate from './modules/affiliate.js'
import creditProdiver from './modules/creditProdiver.js'
import notification from './modules/notification.js'
import dashboard from './modules/dashboard.js'
import attackCenter from './modules/attackCenter.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    drawer,
    client,
    profile,
    credit,
    setting,
    cdn,
    employee,
    affiliate,
    creditProdiver,
    notification,
    dashboard,
    attackCenter
  }
})
