import axios from "axios";


axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axios;
