import Vue from "vue";

import { authGuard } from "../auth/authGuard";

import VueRouter from "vue-router";
import dashboard from "../views/dashboard.vue";
import dashboardB2C from "../views/dashboardB2C.vue";
import creditAnalysis from "../views/credit-analysis.vue";
import createProfile from "../views/profile/create.vue";
import editProfile from "../views/profile/_id.vue";
import settings from "../views/settings.vue";
import attackCenter from "../views/attack-center.vue";
import print from "../views/print/_id.vue";
import notFound from "../views/notFound.vue";

Vue.use(VueRouter);

window.VUE_APP_APP_TYPE = process.env.VUE_APP_APP_TYPE;
if (location.origin.includes("https://business.credified.com")) {
  window.VUE_APP_APP_TYPE = "b2b";
}
// meta type indicates that the which route is specific to b2b or b2c and which is for both
const routes = [
  window.VUE_APP_APP_TYPE === "b2b"
    ? {
        path: "/",
        redirect: "/dashboard",
        meta: { type: "b2b" },
      }
    : {
        path: "/",
        redirect: "/home",
        meta: { type: "b2c" },
      },
  window.VUE_APP_APP_TYPE === "b2b"
    ? {
        path: "/dashboard",
        name: "dashboard",
        component: dashboard,
        meta: { type: "b2b" },
        beforeEnter: authGuard,
      }
    : {
        path: "/home",
        name: "home",
        component: dashboardB2C,
        meta: { type: "b2c" },
        beforeEnter: authGuard,
      },
  {
    path: "/credit-analysis",
    name: "credit",
    component: creditAnalysis,
    meta: { type: "b2b" },
    beforeEnter: authGuard,
  },
  {
    path: "/create-profile",
    name: "profile-create",
    component: createProfile,
    meta: { type: "both" },
    beforeEnter: authGuard,
  },
  {
    path: "/edit-profile/:id",
    name: "profile-edit",
    component: editProfile,
    meta: { type: "both" },
    beforeEnter: authGuard,
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: { type: "both" },
    beforeEnter: authGuard,
  },
  {
    path: "/repair-center",
    name: "repairCenter",
    component: attackCenter,
    meta: { type: "both" },
    beforeEnter: authGuard,
  },
  {
    path: "/repair-center/:id",
    name: "repairCenterId",
    component: attackCenter,
    meta: { type: "both" },
    beforeEnter: authGuard,
  },
  {
    path: "/print/:id",
    name: "print",
    component: print,
    meta: { type: "both" },
    beforeEnter: authGuard,
  },
  {
    path: "*",
    name: "404",
    component: notFound,
    meta: { type: "both" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
