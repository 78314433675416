import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      creditReports: [],
      automatedOptions: [],
      manualCreditAccounts: [],
      creditInfo: [],
      manualDestinations: [],
      isLoading: false,
      isAccountInfoLoading: false,
      isGenLoading: false, // is generations loading
      isFileDownloading: false,
      fileProgress: 0,
    };
  },
  getters: {
    creditReports(state) {
      return state.creditReports;
    },
    automatedOptions(state) {
      return state.automatedOptions;
    },
    manualCreditAccounts(state) {
      return state.manualCreditAccounts;
    },
    creditInfo(state) {
      return state.creditInfo;
    },
    manualDestinations(state) {
      return state.manualDestinations;
    },
    isLoading(state) {
      return state.isLoading;
    },
    isAccountInfoLoading(state) {
      return state.isAccountInfoLoading;
    },
    isGenLoading(state) {
      return state.isGenLoading;
    },
    isFileDownloading(state) {
      return state.isFileDownloading;
    },
    fileProgress(state) {
      return state.fileProgress;
    },
  },
  mutations: {
    SET_CREDIT_REPORTS(state, payload) {
      state.creditReports = payload;
    },
    SET_OPTIONS(state, payload) {
      state.automatedOptions = payload;
    },
    SET_MANUAL_CREDIT_ACCOUNTS(state, payload) {
      state.manualCreditAccounts = payload;
    },
    SET_CREDIT_INFO(state, payload) {
      state.creditInfo = payload;
    },
    SET_DESTINATIONS(state, payload) {
      state.manualDestinations = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    IS_ACCOUNTINFO_LOADING(state, payload) {
      state.isAccountInfoLoading = payload;
    },
    IS_GENLOADING(state, payload) {
      state.isGenLoading = payload;
    },
    IS_FILE_DOWNLOADING(state, payload) {
      state.isFileDownloading = payload;
    },
    SET_PROGRESS(state, payload) {
      state.fileProgress = payload;
    },
  },
  actions: {
    async loadSingleCreditReport(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/creditReports/${payload.id}`);
        let sorted = response.data.sort((a, b) => {
          if (a.name < b.name) {
            return 1;
          } else if (a.name > b.name) {
            return -1;
          }
          return 0;
        });
        context.commit("SET_CREDIT_REPORTS", sorted);
        context.commit("IS_LOADING", false);
        return sorted;
      } catch (error) {
        context.commit("IS_LOADING", false);
        return console.warn(error);
      }
    },
    async loadAutomatedGenerations(context, payload) {
      context.commit("IS_GENLOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/automateGenerationsType",
          JSON.stringify(payload)
        );
        context.commit("SET_OPTIONS", response.data);
        context.commit("IS_GENLOADING", false);
        return response.data;
        // const response = await fetch("/api/v1/automateGenerationsType", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // context.commit("SET_OPTIONS", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_GENLOADING", false);
        return console.warn(error);
      }
    },
    async automatedGenerations(context, payload) {
      context.commit("IS_GENLOADING", true);
      try {
        const response = await axios.post(
          `/api/v1/automatedGenerations/${payload.reportId}/${payload.profileId}/${payload.selectedOptions}`,
          JSON.stringify(payload.selectedOptions)
        );
        context.commit("IS_GENLOADING", false);
        return response.data;
        // const response = await fetch("/api/v1/automatedGenerations", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_GENLOADING", false);
        return console.warn(error);
      }
    },
    async getManualCreditAccounts(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/manualGenerateInformations/${payload.profileId}/${payload.reportId}`
        );
        context.commit("SET_MANUAL_CREDIT_ACCOUNTS", response.data);
        context.commit("IS_LOADING", false);
        return response.data;
        // const response = await fetch("/api/v1/manualGenerateInformations", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        return console.warn(error);
      }
    },
    async getNegativeManualCreditAccounts(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/negativeManualGenerateInformations/${payload.profileId}/${payload.reportId}`
        );
        context.commit("SET_MANUAL_CREDIT_ACCOUNTS", response.data);
        context.commit("IS_LOADING", false);
        return response.data;
        // const response = await fetch(
        //   "/api/v1/negativeManualGenerateInformations",
        //   {
        //     method: "GET",
        //   }
        // );
        // const responseData = await response.json();
        // context.commit("SET_MANUAL_CREDIT_ACCOUNTS", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        return console.warn(error);
      }
    },
    async getAccountInformation(context, payload) {
      if (payload.accountId) {
        context.commit("IS_ACCOUNTINFO_LOADING", true);
        try {
          const response = await axios.post(
            `/api/v1/getAccountInformation/${payload.accountId}/${payload.reportId}`,
            JSON.stringify(payload.selectedAccounts)
          );
          context.commit("SET_CREDIT_INFO", response.data);
          context.commit("IS_ACCOUNTINFO_LOADING", false);
          return response.data;
          // const response = await fetch("/api/v1/getAccountInformation", {
          //   method: "POST",
          //   body: JSON.stringify(payload),
          // });
          // const responseData = await response.json();
          // context.commit("SET_CREDIT_INFO", responseData);
          // return responseData;
        } catch (error) {
          context.commit("IS_ACCOUNTINFO_LOADING", false);
          return console.warn(error);
        }
      }
    },
    async getDestinations(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/getDestinations/${payload.profileId}/${payload.reportId}`
        );
        context.commit("SET_DESTINATIONS", response.data);
        context.commit("IS_LOADING", false);
        return response.data;
        // const response = await fetch("/api/v1/getDestinations", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_DESTINATIONS", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        return console.warn(error);
      }
    },
    async getGenerationSome(context, payload) {
      // context.commit("IS_LOADING", true);
      try {
        const response = await axios.get(
          `/api/generations/${payload.profileId}/${payload.reportId}`
        );
        console.log("response =>", response);
        console.log("response data =>", response.data);
        // context.commit("SET_DESTINATIONS", response.data);
        // context.commit("IS_LOADING", false);
        return response.data;
      } catch (error) {
        // context.commit("IS_LOADING", false);
        return console.warn(error);
      }
    },
    async manualGenerations(context, payload) {
      context.commit("IS_GENLOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/generateManual",
          JSON.stringify(payload)
        );
        context.commit("SET_CREDIT_INFO", response.data);
        context.commit("IS_GENLOADING", false);
        return response.data;
        // const response = await fetch("/api/v1/generateManual", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_GENLOADING", false);
        return console.warn(error);
      }
    },
    async fileGenerationFunc(context, { sessionId, profile }) {
      try {
        const response = await axios.get(
          `/api/v1/attack/session/download/${sessionId}`,
          {
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              context.commit("SET_PROGRESS", progress);
            },
          }
        );
        context.commit("IS_FILE_DOWNLOADING", true);
        const blob = new Blob([response.data], { type: "application/zip" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${profile.profileName}.zip`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        context.commit("SET_PROGRESS", 0);
        context.commit("IS_FILE_DOWNLOADING", false);
        return true;
      } catch (error) {
        context.commit("IS_FILE_DOWNLOADING", false);
        console.warn(error);
        return false;
      }
    },
    async getCSVFileFunc(context, { sessionId }) {
      context.commit("IS_FILE_DOWNLOADING", true);
      try {
        const response = await axios.get(
          `/api/v1/attack/session/csv/${sessionId}`,
          {
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              context.commit("SET_PROGRESS", progress);
            },
          }
        );
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${sessionId}.csv`);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        context.commit("SET_PROGRESS", 0);
        context.commit("IS_FILE_DOWNLOADING", false);
      } catch (error) {
        context.commit("IS_FILE_DOWNLOADING", false);
        console.warn(error);
      }
    },
  },
};
