<template>
  <div>
    <div v-if="isLoading" class="tw-w-full tw-h-full">
      <Loader />
    </div>
    <div v-if="!isLoading">
      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4">
        <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-col tw-space-y-4">
          <div
            class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 md:tw-space-x-6"
          >
            <v-card
              id="gc-lastSignIn"
              class="tw-w-full md:tw-w-2/5 tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col">
                <span class="tw-text-xl tw-font-extrabold">
                  {{ userInfo.fullName }}
                </span>
                <span class="tw-text-gray-400 tw-text-sm">
                  Last sign in {{ userInfo.lastSignIn | MwithDate }}
                </span>
              </div>
            </v-card>
            <v-card
              id="gc-generationsLeft"
              class="tw-w-full tw-flex md:tw-w-3/5 tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-items-center">
                <v-progress-circular
                  :rotate="270"
                  :size="65"
                  :width="5"
                  :value="userInfo.generationsLeft.value"
                  color="primary"
                >
                  <span class="tw-text-black tw-font-extrabold tw-text-xl">
                    {{ userInfo.generationsLeft.value }}
                  </span>
                </v-progress-circular>
                <div class="tw-px-3 tw-flex tw-flex-col">
                  <span class="tw-text-lg tw-font-extrabold w-100">
                    {{ $t("homepage.CreditsRemaining") }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="align-items: baseline"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span
                        >Credits are the chances, that how many times you can
                        generate letters</span
                      >
                    </v-tooltip>
                  </span>
                  <span class="tw-text-sm">
                    {{ $t("homepage.Account") }} #{{
                      userInfo.generationsLeft.account
                    }}
                  </span>
                  <v-btn outlined color="primary" class="tw-mt-2 tw-w-max">
                    Buy now
                  </v-btn>
                </div>
              </div>
            </v-card>

            <!-- <v-card
              id="gc-generationsLeft"
              @click="$router.push('/settings')"
              class="tw-w-full md:tw-w-3/5 tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-items-center">
                <v-progress-circular
                  :rotate="270"
                  :size="65"
                  :width="5"
                  :value="userInfo.generationsLeft.value"
                  color="primary"
                >
                  <span class="tw-text-black tw-font-extrabold tw-text-xl">
                    {{ userInfo.generationsLeft.value }}
                  </span>
                </v-progress-circular>
                <div class="tw-px-3 tw-flex tw-flex-col">
                  <span class="tw-text-lg tw-font-extrabold w-100">
                    {{ $t("homepage.GenerationsLeft") }}
                  </span>
                  <span class="tw-text-sm">
                    {{ $t("homepage.Account") }} #{{
                      userInfo.generationsLeft.account
                    }}
                  </span>
                </div>
              </div>
            </v-card> -->
          </div>
          <!-- --------------------------- -->
          <div
            class="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-space-y-4 md:tw-space-y-0 md:tw-space-x-4"
          >
            <v-card class="tw-w-full tw-px-4 tw-py-4 tw-rounded-lg">
              <p class="tw-text-base tw-font-semibold">
                <v-icon class="tw-pr-2">mdi-format-list-checks</v-icon>Checklist
              </p>
              <div class="tw-mt-4">
                <v-checkbox
                  class="tw-mt-0"
                  v-for="item in checklistItems"
                  :disabled="item.disabled"
                  :key="item.id"
                  v-model="item.checked"
                  :label="item.label"
                ></v-checkbox>
              </div>
            </v-card>
          </div>
          <!-- --------------------------- -->
          <div
            class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 md:tw-space-x-6"
          >
            <v-card
              id="gc-dateJoined"
              class="tw-w-full md:tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col">
                <span class="tw-text-gray-500 tw-text-xs pb-2 tw-font-bold">
                  Date Joined
                </span>
                <p class="tw-text-dark tw-font-extrabold tw-text-xl">
                  {{ userInfo.dateJoined | MwithDate }}
                </p>
              </div>
            </v-card>
            <v-card
              id="gc-step3"
              class="tw-w-full md:tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col">
                <span class="tw-text-gray-500 tw-text-xs pb-2 tw-font-bold">
                  {{ $t("homepage.DateLastActive") }}
                </span>
                <p class="tw-text-dark tw-font-extrabold tw-text-xl">
                  {{ userInfo.lastActiveDate | MwithDate }}
                </p>
              </div>
            </v-card>
            <!-- <v-card class="tw-w-full md:tw-w-1/3 tw-px-4 tw-py-4 tw-rounded-lg">
            <div class="tw-flex tw-flex-col">
              <span class="tw-text-gray-500 tw-text-xs pb-2 tw-font-bold">
                Access Until
              </span>
              <p class="tw-text-dark tw-font-extrabold tw-text-xl">
                {{ userInfo.accessUntil | MwithDate }}
              </p>
            </div>
          </v-card> -->
          </div>
          <!-- credified learning for mobile -->
          <div
            class="tw-w-full tw-flex tw-flex-col-reverse tw-space-y-4 md:tw-hidden"
          >
            <div
              v-if="!profiles || !profiles?.results"
              class="tw-bg-white tw-w-full tw-my-3 md:tw-my-0 md:tw-w-1/2 tw-rounded-lg tw-overflow-hidden"
            >
              <v-sheet color="tw-grey lighten-4'" class="pa-3">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="100%"
                  type="image"
                />
              </v-sheet>
            </div>
            <div
              v-else-if="profiles && profiles?.results"
              class="tw-bg-white tw-w-full tw-my-3 md:tw-my-0 md:tw-w-1/2 tw-rounded-lg tw-overflow-hidden"
            >
              <custom-calendar :profiles="profiles.results" />
            </div>
            <div
              class="tw-w-full md:tw-w-1/2 tw-p-5 tw-bg-primary tw-shadow-primary tw-shadow-lg tw-rounded-lg tw-flex tw-flex-col tw-h-max"
            >
              <div class="tw-flex tw-flex-row tw-justify-center tw-w-full">
                <img
                  src="../assets/icons/circle-check.svg"
                  style="width: 100px"
                  alt=""
                />
              </div>
              <div class="tw-py-3 tw-pt-7 tw-w-full">
                <p
                  class="tw-mb-0 tw-text-2xl tw-text-white tw-text-center tw-font-extrabold mb-8"
                >
                  Credified Guide
                </p>
                <p class="tw-text-gray-100 tw-text-center">
                  Click on Get Started to learn about your dashboard
                </p>
              </div>
              <div>
                <v-btn
                  class="tw-w-full my-3 tw-text-primary"
                  large
                  depressed
                  @click="startWalkthrough"
                >
                  Get Started
                </v-btn>
                <v-btn
                  class="tw-w-full mb-3 tw-text-primary"
                  color="white"
                  large
                  outlined
                  depressed
                  @click="dialogueHandler"
                >
                  <img
                    src="../assets/icons/play-button.svg"
                    class="tw-w-8 tw-mr-2"
                    alt="play-button"
                  />
                  Watch Tutorial
                </v-btn>
              </div>
            </div>
          </div>
          <!-- credified learning for mobile -->
          <div
            id="gc-statusCounts"
            class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 md:tw-space-x-6"
          >
            <div
              class="tw-w-full md:tw-w-1/4 tw-bg-success tw-p-3 tw-rounded-lg tw-bg-opacity-10 tw-border tw-border-success tw-border-opacity-20"
            >
              <img src="../assets/icons/TotalRepaired.svg" alt="" />
              <div class="tw-mt-12">
                <p class="tw-text-gray-600 tw-text-sm">
                  {{ $t("homepage.TotalFinished") }}
                </p>
                <p
                  v-if="userInfo.statitics.finished"
                  class="tw-text-3xl tw-font-extrabold tw-text-success"
                >
                  {{ userInfo.statitics.finished.value }}
                </p>
              </div>
            </div>
            <div
              class="tw-w-full md:tw-w-1/4 tw-bg-error tw-p-3 tw-rounded-lg tw-bg-opacity-10 tw-border tw-border-error tw-border-opacity-20"
            >
              <img src="../assets/icons/ItemsDeleted.svg" alt="" />
              <div class="tw-mt-12">
                <p class="tw-text-gray-600 tw-text-sm">
                  {{ $t("homepage.NeedsReview") }}
                </p>
                <p
                  v-if="userInfo.statitics.needsReview"
                  class="tw-text-3xl tw-font-extrabold tw-text-error"
                >
                  {{ userInfo.statitics.needsReview.value }}
                </p>
              </div>
            </div>
            <div
              class="tw-w-full md:tw-w-1/4 tw-bg-warning tw-p-3 tw-rounded-lg tw-bg-opacity-10 tw-border tw-border-warning tw-border-opacity-20"
            >
              <img src="../assets/icons/InDispute.svg" alt="" />
              <div class="tw-mt-12">
                <p class="tw-text-gray-600 tw-text-sm">
                  {{ $t("homepage.ItemsWaiting") }}
                </p>
                <p
                  v-if="userInfo.statitics.waiting"
                  class="tw-text-3xl tw-font-extrabold tw-text-warning"
                >
                  {{ userInfo.statitics.waiting.value }}
                </p>
              </div>
            </div>
            <div
              class="tw-w-full md:tw-w-1/4 tw-bg-primary tw-p-3 tw-rounded-lg tw-bg-opacity-10 tw-border tw-border-primary tw-border-opacity-20"
            >
              <img src="../assets/icons/Remaining.svg" alt="" />
              <div class="tw-mt-12">
                <p class="tw-text-gray-600 tw-text-sm">
                  {{ $t("homepage.Onboarding") }}
                </p>
                <p
                  v-if="userInfo.statitics.onboarding"
                  class="tw-text-3xl tw-font-extrabold tw-text-primary"
                >
                  {{ userInfo.statitics.onboarding.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tw-w-full tw-hidden md:tw-w-1/2 md:tw-flex md:tw-flex-row md:tw-space-x-4"
        >
          <div
            v-if="!profiles || !profiles?.results"
            class="tw-bg-white tw-w-full tw-my-3 md:tw-my-0 md:tw-w-1/2 tw-rounded-lg tw-overflow-hidden"
          >
            <v-sheet color="tw-grey lighten-4'" class="pa-3">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="image"
              />
            </v-sheet>
          </div>
          <div
            v-else-if="profiles && profiles?.results"
            class="tw-bg-white tw-w-full tw-my-3 md:tw-my-0 md:tw-w-1/2 tw-rounded-lg tw-overflow-hidden"
          >
            <custom-calendar :profiles="profiles.results" />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-p-5 tw-bg-primary tw-shadow-primary tw-shadow-lg tw-rounded-lg tw-flex tw-flex-col tw-h-max"
          >
            <div class="tw-flex tw-flex-row tw-justify-center tw-w-full">
              <img
                src="../assets/icons/circle-check.svg"
                style="width: 100px"
                alt=""
              />
              <!-- <v-progress-circular
                :rotate="360"
                :size="50"
                :width="3"
                :value="generationsLeft"
                color="white"
              >
                5/10
              </v-progress-circular> -->
            </div>
            <div class="tw-py-3 tw-pt-7 tw-w-full">
              <p
                class="tw-mb-0 tw-text-2xl tw-text-white tw-text-center tw-font-extrabold mb-8"
              >
                Credified Guide
              </p>
              <p class="tw-text-gray-100 tw-text-center">
                Click on Get Started to learn about your dashboard
              </p>
            </div>
            <div>
              <v-btn
                class="tw-w-full my-3 tw-text-primary"
                large
                depressed
                @click="startWalkthrough"
              >
                Get Started
              </v-btn>
              <v-btn
                class="tw-w-full mb-3 tw-text-primary"
                color="white"
                large
                outlined
                depressed
                @click="dialogueHandler"
              >
                <img
                  src="../assets/icons/play-button.svg"
                  class="tw-w-8 tw-mr-2"
                  alt="play-button"
                />
                Watch Tutorial
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <clients-list />
      <generations-history-logs ref="generationsTable" />
      <profile-update-logs-list />
      <watch-tutorial-dialogue ref="tutDialogue" />
      <get-phone-number-dialogue ref="getPhoneNumberDialogue" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomCalendar from "@/components/global/CustomCalendar.vue";
import ClientsList from "@/components/pages/dashboard/ClientsList.vue";
import ProfileUpdateLogsList from "@/components/pages/dashboard/ProfileUpdateLogsList.vue";
import Loader from "@/components/global/Loader.vue";
import GuideChimp from "guidechimp";
import WatchTutorialDialogue from "@/components/global/WatchTutorialDialogue.vue";
import GetPhoneNumberDialogue from "@/components/global/GetPhoneNumberDialogue.vue";
import GenerationsHistoryLogs from "@/components/pages/dashboard/GenerationsHistoryLogs.vue";

export default {
  components: {
    CustomCalendar,
    ClientsList,
    ProfileUpdateLogsList,
    GetPhoneNumberDialogue,
    Loader,
    WatchTutorialDialogue,
    GenerationsHistoryLogs,
  },
  data() {
    return {
      generationsLeft: 30,
      checklistItems: [
        {
          id: 1,
          label: "First purchase generations credits",
          checked: false,
          disabled: false,
        },
        {
          id: 2,
          label:
            "Second Create Client Profile and connect their credit report.",
          checked: false,
          disabled: false,
        },
        {
          id: 3,
          label: "Third, generate personalized letters in Repair Center",
          checked: false,
          disabled: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "dashboard/userInfo",
      isLoading: "dashboard/isLoading",
      profiles: "profile/profiles",
    }),
  },
  async mounted() {
    await this.loadUserInfo();
    await this.loadProfiles();

    if (this.userInfo?.generationsLeft.value > 0) {
      this.checklistItems = this.checklistItems.map((item) =>
        item.id == 1 ? { ...item, checked: true, disabled: true } : { ...item }
      );
    }
    if (this.userInfo?.onboarding.report) {
      this.checklistItems = this.checklistItems.map((item) =>
        item.id == 2 ? { ...item, checked: true, disabled: true } : { ...item }
      );
    }
    if (this.$refs.generationsTable?.generationsLog.length > 0) {
      this.checklistItems = this.checklistItems.map((item) =>
        item.id == 3 ? { ...item, checked: true, disabled: true } : { ...item }
      );
    }

    // check if phone number is available
    if (this.userInfo?.phoneNumber && !this.userInfo?.phoneNumber) {
      this.$refs.getPhoneNumberDialogue.dialog = true;
    }
  },
  methods: {
    ...mapActions({
      loadProfiles: "profile/loadProfiles",
      loadUserInfo: "dashboard/loadUserInfo",
    }),
    startWalkthrough() {
      var guideChimp = GuideChimp([
        {
          element: "#gc-lastSignIn",
          title: "Last Sign In",
          description: "This shows, your last sign In, to platform.",
        },
        {
          element: "#gc-generationsLeft",
          title: "Generations Left",
          description:
            "These are the generations that are left in your account, You can always buy them upon clicking on it!",
        },
        {
          element: "#gc-dateJoined",
          title: "Date joined",
          description: "This is showing your joining date",
        },
        {
          element: "#gcc-dateJoined",
          title: "Last Active",
          description: "This is the date showing your Last Active",
        },
        {
          element: "#gc-statusCounts",
          title: "Status Counts",
          description:
            "This is the section showing statuses of your clients available",
        },
        {
          element: "#gc-clientsTable",
          title: "Clients",
          description: "Here, all your clients will show up!",
        },
        {
          element: "#gc-quickImport",
          title: "Quick Import",
          description:
            "Here, you can import your clients collectively, at once!",
        },
        {
          element: "#gc-addClient",
          title: "Clients",
          description:
            "Or if you want to add client by yourself, You can click here!",
        },
        {
          element: "#gc-sideMenu",
          title: "Main Menu",
          description:
            "This is the main menu, there comes alot of options for you to interact",
        },
        {
          element: "#gc-createProfile",
          title: "Lets Explore Create Profile",
          description: `Click on the <b>Button</b> to move to create profile page`,
        },
        {
          element: "#gc-personalInfo",
          title: "Personal Info of Client",
          description: `Here will go, all the information of your client`,
        },
        {
          element: "#gc-pod",
          title: "Personal Documents of Client",
          description: `Personal Documents of Client, supporting abbove information, will be uploaded here`,
        },
        {
          element: "#gc-attackCenter",
          title: "Lets Explore Repair center",
          description: `Click on the <b>Button</b> to move to Repair Center`,
        },
        {
          element: "#gc-selectClient",
          title: "Select Clients",
          description: `Select <b>Client</b>, to get its data`,
        },
        {
          element: "#gc-selectCredit",
          title: "Select Credits for Respective Client",
          description: `Select <b>Credit Report</b>, to attack`,
        },
        {
          element: "#gc-settings",
          title: "Lets Explore Settings",
          description: `Click on the <b>Button</b> to move to Settings`,
        },
        {
          element: "#gc-purchaseGens",
          title: "Generations",
          description: "Buy Generations to use them in Repair Center",
        },
        {
          element: "#gc-companyInfo",
          title: "Company Info",
          description: "Here, edit the company info, logo and website etc.",
        },
      ]);
      guideChimp.start();
    },
    dialogueHandler() {
      this.$refs.tutDialogue.dialog = !this.$refs.tutDialogue.dialog;
    },
  },
};
</script>

<style></style>
