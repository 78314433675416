import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./assets/css/index.css";
// not working
import VueI18n from "vue-i18n";

import VCalendar from "v-calendar";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth/auth0";
import filters from "./filters/index";
import VueMask from "v-mask";
import { messages } from "./plugins/vueI18n";

Vue.use(VueMask);
Vue.use(VueI18n);

// window.VUE_APP_APP_TYPE = process.env.VUE_APP_APP_TYPE;
// if (location.origin.includes("http://localhost:3001")) {
//   window.VUE_APP_APP_TYPE = "b2c";
// }

const savedLang = localStorage.getItem("language") || "en";

const i18n = new VueI18n({
  locale: savedLang,
  messages,
});

Vue.use(Auth0Plugin, {
  domain,
  audience,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

import axios from "./axios";
Vue.prototype.$http = axios;
// const token = localStorage.getItem("_token");
// axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
axios.defaults.baseURL = process.env.VUE_APP_API;

Vue.config.productionTip = false;

// async function init() {
//   if (process.env.NODE_ENV === "development") {
//     const { startMockApiService } = await import("../public/mock/browser");
//     startMockApiService();
//   }
// }

// init();

new Vue({
  vuetify,
  router,
  store,
  i18n,
  filters,
  render: (h) => h(App),
}).$mount("#app");
