<template>
  <div class="tw-w-full md:tw-w-1/2">
    <v-dialog v-model="dialog" persistent width="700">
      <v-card>
        <v-card-title> Downloading Letters... </v-card-title>
        <v-card-text>
          <v-progress-linear :value="fileProgress" color="primary" height="10">
            <strong
              :class="fileProgress >= 50 ? 'tw-text-white' : 'tw-text-inherit'"
              class="tw-text-xs"
            >
              {{ Math.ceil(fileProgress) }}%
              {{ timeRemaining ? timeRemaining : "" }}
            </strong>
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      progress: 0,
      startTime: null,
      timeRemaining: null,
    };
  },
  computed: {
    ...mapGetters({
      isFileDownloading: "attackCenter/isFileDownloading",
      fileProgress: "attackCenter/fileProgress",
    }),
  },
  watch: {
    isFileDownloading: {
      handler: "fileDownloadingHandler",
      immediate: true,
    },
    fileProgress: {
      handler: "fileProgressHandler",
      immediate: true,
    },
  },
  methods: {
    fileDownloadingHandler() {
      if (this.isFileDownloading) {
        this.dialog = this.isFileDownloading;
        this.startTime = new Date();
        return;
      }
      this.dialog = this.isFileDownloading;
    },
    fileProgressHandler() {
      if (this.fileProgress == 100) {
        this.dialog = false;
      } else {
        this.updateTimeRemaining();
      }
    },
    updateTimeRemaining() {
      const elapsedTime = new Date() - this.startTime;
      const estimatedTotalTime = (elapsedTime / this.fileProgress) * 100;
      const remainingTime = estimatedTotalTime - elapsedTime;
      const seconds = Math.round(remainingTime / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      this.timeRemaining = `(${minutes == Infinity ? 0 : minutes} min ${
        isNaN(remainingSeconds) ? 0 : remainingSeconds
      } sec remaining)`;
    },
  },
};
</script>
