<template>
  <div class="tw-w-full">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <v-btn depressed large color="white" @click="$router.push('/')">
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
      <div>
        <p class="tw-font-extrabold tw-text-3xl">Credit Analysis</p>
      </div>
      <notification-toggler />
      <notes-toggler />
    </div>
  </div>
</template>

<script>
import NotesToggler from "@/components/global/NotesToggler.vue";
import NotificationToggler from "@/components/global/NotificationToggler.vue";
export default {
  components: { NotificationToggler, NotesToggler },
};
</script>
