<template>
  <div>
    <vc-calendar :attributes="attributes" is-expanded />
  </div>
</template>

<script>
export default {
  props: {
    profiles: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isOpen: false,
      attributes: [],
    };
  },
  mounted() {
    let profileAttributes = this.profiles.map((profile) => {
      if (profile.date != "" || profile.status != "") {
        return {
          highlight: {
            color: this.selectColor(profile.status),
            fillMode: "outline",
          },
          dates: new Date(profile.date),
        };
      }
      return profile;
    });

    this.attributes = [
      {
        key: "today",
        highlight: "blue",
        dates: new Date(),
      },
      ...profileAttributes,
    ];
  },
  methods: {
    selectColor: (status) => {
      let color;
      switch (status) {
        case "Onboarding":
          color = "blue";
          break;
        case "Waiting":
          color = "orange";
          break;
        case "Needs Review":
          color = "red";
          break;
        case "Finished":
          color = "green";
          break;
        default:
          color = "blue";
          break;
      }

      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vc-container.vc-is-expanded {
    background: transparent;
    border: 0;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    background: rgba(0, 0, 0, 0.05) !important;
  }
}
</style>
