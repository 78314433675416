import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      employees: [],
    };
  },
  getters: {
    employees(state) {
      return state.employees;
    },
  },
  mutations: {
    SET_EMPLOYEES(state, payload) {
      state.employees = payload;
    },
  },
  actions: {
    async loadEmployees(context) {
      try {
        const response = await axios.get("/api/v1/employees");
        context.commit("SET_EMPLOYEES", response.data);
        return response.data;
        // const response = await fetch("/api/v1/employees", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_EMPLOYEES", responseData);
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
