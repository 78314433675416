<template>
  <v-navigation-drawer
    v-model="isDrowerOpen"
    left
    overlay-opacity="0.9"
    width="20%"
    temporary
    fixed
  >
    <div class="image-container tw-border-b-2 tw-px-5 tw-py-3.5 tw-mx-auto">
      <img src="../../../assets/icons/credified.svg" alt="credified" />
    </div>
    <ul class="mt-1">
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        @click="$router.push(item.route).catch((err) => {})"
      >
        <div v-if="typeof item === 'object'">
          <div
            class="tw-bg-white tw-rounded-md tw-w-10/12 first-of-type:tw-mt-3 tw-h-12 hover:tw-border-r"
            :class="[
              item.isActive
                ? 'tw-bg-gradient-to-l tw-from-primary-light tw-to-primary tw-bg-primary/90 tw-shadow-lg tw-shadow-primary/50'
                : 'tw-bg-white',
            ]"
          >
            <img
              v-if="!item.isActive"
              :src="require(`../../../assets/icons/${item.name}.svg`)"
              class="tw-mx-auto tw-py-3"
              height="100"
              :alt="item.name"
            />
            <img
              v-else
              :src="require(`../../../assets/icons/${item.name}Active.svg`)"
              class="tw-mx-auto tw-py-3"
              height="100"
              :alt="item.name"
            />
          </div>
        </div>
      </li>
      <!-- Sign OUt -->
      <li @click="$auth.logout()">
        <div>
          <div
            class="tw-bg-gradient-to-l tw-from-error-light tw-to-error tw-bg-error/90 tw-shadow-lg tw-shadow-error/50 tw-rounded-md tw-w-10/12 first-of-type:tw-mt-3 tw-h-12 hover:tw-border-r tw-flex tw-justify-center"
          >
            <v-icon class="" style="color: white"> mdi-logout-variant </v-icon>
          </div>
        </div>
      </li>
    </ul>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      menuItems: [
        window.VUE_APP_APP_TYPE === "b2b"
          ? {
              id: "gc-dashboard",
              name: "Dashboard",
              route: "/dashboard",
              isActive: false,
            }
          : {
              id: "gc-dashboard",
              name: "Home",
              route: "/home",
              isActive: false,
            },
        // window.VUE_APP_APP_TYPE === "b2b" && {
        //   name: "Analytics",
        //   route: "/credit-analysis",
        //   isActive: false,
        // },
        {
          name: "Profile",
          route: "/create-profile",
          isActive: false,
        },
        {
          name: "RepairCenter",
          route: "/repair-center",
          isActive: false,
        },
        {
          name: "Settings",
          route: "/settings",
          isActive: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: "drawer/isSidebarOpen",
    }),
    isDrowerOpen: {
      get() {
        return this.isSidebarOpen;
      },
      set(value) {
        return value;
      },
    },
  },
  watch: {
    $route: {
      handler(e) {
        this.menuItems.find((r) => {
          if (typeof r === "object") {
            if (r?.route === e.path) {
              r.isActive = true;
            } else {
              r.isActive = false;
            }
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      toggleSidebar: "drawer/toggleSidebar",
    }),
    toggleXsSidebar(value) {
      this.toggleSidebar(value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  ul {
    padding-left: 0 !important;
    li {
      margin-left: 0.5rem;
    }
  }
}
</style>
