<template>
  <v-card id="gc-generationLogs" class="my-8 md:my-5">
    <div
      class="tw-flex tw-flex-row tw-justify-between tw-items-baseline tw-mb-4 tw-p-4 md:tw-p-8"
    >
      <div class="tw-flex tw-flex-col">
        <p class="tw-font-bold tw-text-xs tw-text-slate-500">Generations</p>
        <p class="tw-font-bold tw-text-xl">
          {{ $t("homepage.GenerationsLog") }}
        </p>
      </div>
    </div>
    <div class="tw-p-0 md:tw-p-8">
      <v-data-table
        :headers="columns"
        :items-per-page="1000000"
        :items="tableData"
        hide-default-footer
        item-key="id"
        height="480px"
        :loading="isGenLoading"
        disable-sort
        striped
        :no-data-text="DataText"
        style="border: 1px solid #9ca5b362; border-radius: 8px"
      >
        <template #[`item.id`]="{ item }">
          <p>{{ item.id }}</p>
        </template>
        <template #[`item.profileName`]="{ item }">
          <p>{{ item.profileName }}</p>
        </template>
        <template #[`item.reportId`]="{ item }">
          <p>{{ item.reportId }}</p>
        </template>
        <template #[`item.date`]="{ item }">
          <p>{{ item.date }}</p>
        </template>
        <template #[`item.destinationAddress`]="{ item }">
          <p>{{ item.destinationAddress }}</p>
        </template>
        <template #[`item.numberOfLetters`]="{ item }">
          <p>{{ item.numberOfLetters }}</p>
        </template>
        <template #[`item.actions`]="{ on, item }">
          <!-- <v-btn
            color="primary"
            v-on="on"
            @click="download(item.id, item.profileName)"
            >Download</v-btn
          > -->
          <span
            class="fa-solid fa-envelope tw-text-xl tw-text-[#0c48af] tw-pr-3 -tw-mb-0.5 tw-cursor-pointer"
            v-on="on"
            @click="download(item.id, item.profileName)"
          ></span>
          <span
            v-if="item.variantIDs && item.variantIDs[0]"
            class="fa-solid fa-download tw-text-xl tw-text-[#0c48af] tw-pr-3 tw-cursor-pointer"
            v-on="on"
            @click="download(item.id, item.profileName)"
          ></span>
        </template>
      </v-data-table>
    </div>
    <Snackbar
      variant="error"
      timeout="-1"
      :text="snackbarText"
      ref="snackbar"
    />
  </v-card>
</template>

<script>
import Snackbar from "@/components/global/Snackbar.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      snackbarText: "Error fetching Generations Info",
      tableData: [],
    };
  },
  computed: {
    ...mapGetters({
      isGenLoading: "profile/isLoading",
      generationsLog: "profile/generationsLog",
    }),
    DataText() {
      return this.$t("homepage.NoDataAvailable");
    },
    columns() {
      return [
        {
          text: this.$t("homepage.GenerationsId"),
          align: "start",
          value: "id",
        },
        {
          text: this.$t("homepage.ProfileName"),
          align: "start",
          value: "profileName",
        },
        {
          text: this.$t("homepage.ReportId"),
          align: "start",
          value: "reportId",
        },
        {
          text: this.$t("homepage.DestinationAddress"),
          align: "start",
          value: "destinationAddress",
        },
        {
          text: this.$t("homepage.Date"),
          align: "start",
          value: "date",
        },
        {
          text: "Actions",
          align: "start",
          value: "actions",
        },
      ];
    },
  },
  async mounted() {
    await this.loadGenerationsLog();
    if (!this.generationsLog?.ok && this.generationsLog?.error == "No Data") {
      console.log("this.generationsLog2", this.generationsLog);
      this.$refs.snackbar.snackbar = true;
      this.tableData = [];
    } else {
      this.tableData = this.generationsLog;
    }
  },
  methods: {
    ...mapActions({
      loadGenerationsLog: "profile/loadGenerationsLog",
      fileGenerationFunc: "attackCenter/fileGenerationFunc",
    }),
    async download(id, profileName) {
      await this.fileGenerationFunc({
        sessionId: id,
        profile: { profileName },
      });
    },
  },
  components: { Snackbar },
};
</script>

<style></style>
