<template>
  <v-card class="my-8 md:my-5">
    <div
      class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-baseline tw-mb-2 tw-p-4 md:tw-p-8"
    >
      <div class="tw-flex tw-flex-col md:tw-flex-col tw-w-full md:tw-w-auto">
        <div class="tw-flex tw-justify-between">
          <p class="tw-font-bold tw-text-xl">{{ $t("homepage.Clients") }}</p>
          <!-- for mobile -->
          <div class="tw-flex md:tw-hidden">
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="black"> mdi-dots-vertical </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="$refs.clientTable?.selected.length > 1">
                  <v-list-item-action
                    style="width: 100%; margin-right: 0px; margin-bottom: 0px"
                  >
                    <v-btn
                      color="error"
                      min-height="40"
                      style="width: 100%"
                      @click="
                        $refs.clientTable.isDeleteModalOpen =
                          !$refs.clientTable.isDeleteModalOpen
                      "
                    >
                      Delete
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action
                    style="width: 100%; margin-right: 0px; margin-bottom: 0px"
                  >
                    <v-btn
                      id="gc-quickImport"
                      color="success"
                      min-height="40"
                      style="width: 100%"
                    >
                      <span
                        class="icon-Download md:tw-pr-0.5 tw-text-xl tw-cursor-pointer"
                      />
                      Quick Import
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action
                    style="width: 100%; margin-right: 0px; margin-bottom: 0px"
                  >
                    <v-btn
                      id="gc-addClient"
                      color="success"
                      min-height="40"
                      style="width: 100%"
                      @click="$router.push('/create-profile')"
                    >
                      <v-icon>mdi-account-plus</v-icon>
                      Add Client
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- for mobile -->
        </div>
        <div>
          <ol
            class="tw-flex tw-flex-col md:tw-flex-row md:tw-flex-wrap tw-mx-4 md:tw-mx-0 tw-list-disc md:tw-justify-between"
          >
            <li class="tw-text-gray-400 tw-pr-5 tw-text-sm tw-text-success">
              {{ profiles.meta ? profiles.meta.finished.value : 0 }}
              {{ $t("homepage.Finished") }}
            </li>
            <li class="tw-text-gray-400 tw-pr-5 tw-text-sm tw-text-error">
              {{ profiles.meta ? profiles.meta.needsReview.value : 0 }}
              {{ $t("homepage.NeedsReview") }}
            </li>
            <li class="tw-text-gray-400 tw-pr-5 tw-text-sm tw-text-warning">
              {{ profiles.meta ? profiles.meta.waiting.value : 0 }}
              {{ $t("homepage.ItemsWaiting") }}
            </li>
            <li class="tw-text-gray-400 tw-pr-5 tw-text-sm tw-text-primary">
              {{ profiles.meta ? profiles.meta.onboarding.value : 0 }}
              {{ $t("homepage.Onboarding") }}
            </li>
          </ol>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-3 md:tw-space-y-0 md:tw-space-x-5"
      >
        <div
          class="tw-hidden md:tw-flex tw-space-y-3 md:tw-space-y-0 md:tw-space-x-5"
        >
          <v-btn
            v-if="this.$refs.clientTable?.selected.length > 1"
            color="error"
            min-height="40"
            @click="
              $refs.clientTable.isDeleteModalOpen =
                !$refs.clientTable.isDeleteModalOpen
            "
          >
            <!-- <span
            class="icon-Download md:tw-pr-0.5 tw-text-xl tw-cursor-pointer"
          /> -->
            Delete
          </v-btn>
          <v-btn
            id="gc-quickImport"
            color="success"
            min-height="40"
            @click="
              $refs.quickImportDialog.dialog = !$refs.quickImportDialog.dialog
            "
          >
            <span
              class="icon-Download md:tw-pr-0.5 tw-text-xl tw-cursor-pointer"
            />
            Quick Import
          </v-btn>
          <v-btn
            id="gc-addClient"
            color="success"
            min-height="40"
            @click="$router.push('/create-profile')"
          >
            <v-icon>mdi-account-plus</v-icon>
            Add Client
          </v-btn>
        </div>

        <form-item
          v-model="searchText"
          dense
          prepend-inner-icon="mdi-magnify"
          place-holder="Search Clients"
          class="custom-search tw-w-full"
          label=" "
        />
      </div>
    </div>
    <div class="tw-p-0 md:tw-p-8">
      <custom-table
        id="gc-clientsTable"
        ref="clientTable"
        :columns="columns"
        :data-tables="profileResults"
        :is-selectable="true"
        :has-delete="true"
        :has-edit="true"
        :loading="isLoading"
        @remove-record="removeClient"
        @remove-multiple="removeMultipleClients"
        @edit="$router.push(`/edit-profile/${$event.id}`)"
        @attack-center="$router.push(`/repair-center/${$event.id}`)"
      />
    </div>
    <QuickImportDialog ref="quickImportDialog" />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomTable from "@/components/global/CustomTable.vue";
import FormItem from "@/components/global/FormItem.vue";
import QuickImportDialog from "@/components/pages/dashboard/QuickImportDialog.vue";
export default {
  components: { CustomTable, FormItem, QuickImportDialog },
  data() {
    return {
      isLoading: false,
      profileResults: [],
      searchText: "",
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      profiles: "profile/profiles",
    }),
    columns() {
      return [
        {
          text: this.$t("homepage.Name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("homepage.Date"),
          align: "start",
          value: "date",
        },
        {
          text: this.$t("homepage.Status"),
          align: "start",
          value: "status",
        },
        {
          text: "",
          align: "end",
          value: "more",
        },
      ];
    },
  },
  watch: {
    searchText: {
      handler: "searchHandler",
      immediate: true,
    },
  },
  async mounted() {
    this.isLoading = true;
    const res = await this.loadProfiles();
    this.profileResults = res.results;
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      loadProfiles: "profile/loadProfiles",
      removeProfileById: "profile/removeProfileById",
      removeProfiles: "profile/removeProfiles",
    }),
    async removeClient(e) {
      this.isLoading = true;
      await this.removeProfileById(e.id);
      const res = await this.loadProfiles();
      this.profileResults = res.results;
      this.isLoading = false;
    },
    async removeMultipleClients(e) {
      this.isLoading = true;
      let ids = e.map((item) => item.id);
      await this.removeProfiles(ids);
      const res = await this.loadProfiles();
      this.profileResults = res.results;
      this.isLoading = false;
      this.$refs.clientTable.selected = [];
    },
    searchHandler() {
      if (this.searchText.length >= 1) {
        let tempArr = this.profiles.results;
        this.profileResults = tempArr.filter((profile) =>
          profile.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        this.profileResults = this.profiles.results;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-search {
    .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
      background-color: #747c8914 !important;
    }
  }
}
</style>
