<template>
  <div
    class="tw-flex md:tw-flex-row tw-justify-between tw-flex-col md:tw-space-x-5"
  >
    <div
      class="tw-w-full tw-max-h-screen md:tw-w-2/5 tw-flex-col tw-space-y-4 tw-mb-3 md:tw-mb-0"
    >
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <p>{{ $t("attackcenter.SelectAccount") }}</p>
        <div
          class="tw-flex tw-flex-row tw-bg-white tw-pt-3 tw-px-4 tw-rounded-xl tw-border tw-border-gray-300"
        >
          <span class="tw-text-gray-500">
            {{ $t("attackcenter.ShowOnlyNegative") }}
          </span>
          <v-checkbox v-model="showNegatives" :disabled="!selectedReport" />
        </div>
      </div>
      <div
        style="max-height: 580px; scroll: auto"
        class="tw-space-y-4 tw-overflow-y-scroll"
      >
        <div
          v-for="(item, index) in manualCreditAccounts.generationsAccounts"
          :key="index"
          class="tw-px-5 tw-py-3 tw-border-2 tw-border-gray-200 tw-rounded-lg tw-bg-white tw-cursor-pointer"
          :class="[
            item.status == 'Derogatory'
              ? 'hover:tw-bg-error hover:tw-bg-opacity-10 hover:tw-border-error'
              : item.status == 'Open'
              ? 'hover:tw-bg-success hover:tw-bg-opacity-10 hover:tw-border-success'
              : 'hover:tw-bg-warning hover:tw-bg-opacity-10 hover:tw-border-warning',
            selectedAccounts.includes(item.id) && item.status === 'Derogatory'
              ? 'tw-bg-red-500 tw-bg-opacity-10 tw-border-red-400'
              : selectedAccounts.includes(item.id) && item.status === 2
              ? 'tw-bg-orange-500 tw-bg-opacity-10 tw-border-orange-400'
              : selectedAccounts.includes(item.id) && item.status === 'Open'
              ? 'tw-bg-green-100 tw-border-green-600'
              : '',
          ]"
          @click="toggleReportCheckbox(item.id)"
        >
          <div class="tw-w-full tw-flex tw-justify-between tw-items-baseline">
            <div>
              <p class="tw-text-lg tw-font-bold">
                {{ item.name }}
              </p>
              <p class="tw-text-sm">
                {{ item.type }}
              </p>
            </div>
            <v-checkbox
              v-model="isReportChecked[item.id]"
              :color="
                item.status === 'Derogatory'
                  ? 'error'
                  : item.status === 'Open'
                  ? 'success'
                  : 'warning'
              "
              @click="toggleReportCheckbox(item.id)"
            />
          </div>
          <div>
            <p class="tw-text-lg tw-font-bold">
              ${{ item.amount | formatPrice }}
            </p>
            <p
              class="tw-font-bold"
              :class="[
                item.status === 'Derogatory'
                  ? 'tw-text-error '
                  : item.status === 'Open'
                  ? 'tw-text-success'
                  : 'tw-text-warning',
              ]"
            >
              <v-icon v-if="item.status === 'Derogatory'" color="error">
                mdi-alert
              </v-icon>
              <v-icon v-if="item.status === 2" color="warning">
                mdi-alert-circle-outline
              </v-icon>
              <v-icon v-if="item.status === 'Open'" color="success">
                mdi-checkbox-marked-circle-outline
              </v-icon>
              {{ attackCenterStatus(item) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-w-full md:tw-w-3/5 tw-flex tw-flex-col">
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <p class="tw-font-bold">
          {{ creditInfo.accountName }}
        </p>
        <div class="tw-flex tw-flex-row tw-justify-center">
          <span
            v-if="selectedAccounts.length > 0"
            class="mdi mdi-checkbox-marked tw-pr-2"
            style="color: #0a3d94; font-size: 25px"
          ></span>
          <p class="tw-font-bold" style="padding-top: 6px">
            {{ selectedAccounts.length }} Account(s) Selected
          </p>
        </div>
      </div>
      <v-divider class="tw-my-3" />
      <div v-if="isAccountInfoLoading">
        <LocalLoader />
      </div>
      <div v-else-if="!isAccountInfoLoading">
        <div
          class="tw-flex-col md:tw-flex-row tw-justify-between tw-flex md:tw-space-x-4"
        >
          <div class="tw-flex-col tw-w-full md:tw-w-1/2 tw-space-y-4">
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                {{ $t("attackcenter.AccountNumber") }}:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.accountNumber }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                {{ $t("attackcenter.AccountType") }} - Detail:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.accountDetail }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Account Status:
              </p>
              <p
                class="tw-font-bold tw-text-md"
                :class="[
                  accountDetails?.accountStatus === 1
                    ? 'tw-text-error'
                    : 'tw-text-success',
                ]"
              >
                {{
                  accountDetails?.accountStatus === 1 ? "Negative" : "Positive"
                }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Date Opened:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.dateOpened | fullDate }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                # of Months:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.ofMonths }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Credit Limit:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                $ {{ accountDetails?.creditLimit }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Payment Status:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.paymentStatus }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Date Last Active:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.dateLastActive | fullDate }}
              </p>
            </div>
          </div>
          <div class="tw-flex-col tw-w-full md:tw-w-1/2 tw-space-y-4">
            <div
              class="tw-flex tw-flex-row tw-justify-between tw-mt-4 md:tw-mt-0"
            >
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                {{ $t("attackcenter.AccountType") }}:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.accountType }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Bureau Code:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.bureauCode }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Monthly Payment:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                ${{ accountDetails?.monthlyPayment }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">Balance</p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                ${{ accountDetails?.balance }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                High Credit:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                ${{ accountDetails?.highCredit }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">Past Due:</p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                ${{ accountDetails?.pastDue }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Last Reported:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.lastReport }}
              </p>
            </div>
            <div class="tw-flex tw-flex-row tw-justify-between">
              <p class="tw-text-gray-500 tw-text-md tw-font-bold">
                Last Payment Date:
              </p>
              <p class="tw-text-black tw-font-bold tw-text-md">
                {{ accountDetails?.lastPaymentDate }}
              </p>
            </div>
          </div>
        </div>
        <div class="tw-my-5">
          <p class="tw-text-gray-500 tw-text-md tw-font-bold">Comments:</p>
          <p class="tw-text-black tw-font-bold">
            {{ accountDetails?.commnet }}
          </p>
        </div>
      </div>
      <v-divider class="tw-mb-4" />
      <div>
        <p class="tw-text-gray-500 tw-text-md tw-font-bold">
          REPORTING ONLY ON:
        </p>
        <v-radio-group v-model="reportingOnly" @click="reportingToggler">
          <div
            class="tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-space-y-4 md:tw-space-y-0 md:tw-space-x-4"
          >
            <div
              class="tw-w-full md:tw-w-1/3 tw-w-max tw-cursor-pointer tw-pr-32 tw-rounded-xl tw-border-2 tw-px-4 tw-py-3 tw-flex tw-flex-row tw-justify-between tw-items-center"
              :class="[
                reportingOnly === 'TransUnion'
                  ? 'tw-border tw-border-success'
                  : creditInfo?.TransUnion?.reporting
                  ? 'tw-border-gray-300'
                  : 'tw-border-gray-300 tw-blur-sm tw-pointer-events-none',
              ]"
              @click="reportingToggler('TransUnion')"
            >
              <img src="../../../assets/icons/Transunion.svg" alt="" />
              <p class="tw-font-bold tw-text-lg">TransUnion</p>
              <!-- <v-checkbox> mdi-close-circle </v-checkbox> -->
              <v-radio value="TransUnion"></v-radio>
              <!-- <v-icon color="success" small>
              mdi-checkbox-marked-circle-outline
            </v-icon> -->
            </div>
            <div
              class="tw-w-full md:tw-w-1/3 tw-cursor-pointer tw-rounded-xl tw-border-2 tw-px-4 tw-py-3 tw-flex tw-flex-row tw-justify-between tw-items-center"
              :class="[
                reportingOnly === 'Experian'
                  ? 'tw-border tw-border-success'
                  : creditInfo?.Experian?.reporting
                  ? 'tw-border-gray-300'
                  : 'tw-border-gray-300 tw-blur-sm tw-pointer-events-none',
              ]"
              @click="reportingToggler('Experian')"
            >
              <img src="../../../assets/icons/Experian.svg" alt="" />
              <p class="tw-font-bold tw-text-lg">Experian</p>
              <!-- <v-checkbox> mdi-close-circle </v-checkbox> -->
              <v-radio value="Experian"></v-radio>
            </div>
            <div
              class="tw-w-full md:tw-w-1/3 tw-cursor-pointer tw-rounded-xl tw-border-2 tw-px-4 tw-py-3 tw-flex tw-flex-row tw-justify-between tw-items-center"
              :class="[
                reportingOnly === 'Equifax'
                  ? 'tw-border tw-border-success'
                  : creditInfo?.Equifax?.reporting
                  ? 'tw-border-gray-300'
                  : 'tw-border-gray-300 tw-blur-sm tw-pointer-events-none',
              ]"
              @click="reportingToggler('Equifax')"
            >
              <img src="../../../assets/icons/Equifax.svg" alt="" />
              <p class="tw-font-bold tw-text-lg">Equifax</p>
              <!-- <v-icon color="error" small> mdi-close-circle </v-icon> -->
              <v-radio value="Equifax"></v-radio>
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>
    <div
      class="tw-w-full tw-mt-4 md:tw-mt-0 md:tw-w-2/5 tw-flex tw-flex-col tw-space-y-4 md:tw-space-y-0"
    >
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
        <p>{{ $t("attackcenter.Destinations") }}</p>
      </div>
      <div class="tw-pt-3">
        <form-item
          label=" "
          v-model="selectedDestinations"
          type="combobox"
          place-holder="Select"
          :items="destinations"
          item-text="name"
          item-value="id"
          multiple
        />
      </div>
      <div>
        <div
          v-for="(item, index) in selectedDestinations"
          :key="index"
          class="tw-flex tw-flex-row tw-justify-between"
        >
          <span class="tw-text-md tw-text-gray-500">
            {{ item.name }}
          </span>
          <v-checkbox v-model="selectedDestinations" :value="item.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormItem from "@/components/global/FormItem.vue";
import LocalLoader from "../../global/LocalLoader.vue";

export default {
  components: { FormItem, LocalLoader },
  props: {
    selectedReport: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isReportChecked: [],
      selectedAccounts: [],
      showNegatives: false,
      selectedDestinations: [],
      reportingOnly: null,
      accountDetails: null,
    };
  },
  computed: {
    ...mapGetters({
      manualCreditAccounts: "attackCenter/manualCreditAccounts",
      creditInfo: "attackCenter/creditInfo",
      isAccountInfoLoading: "attackCenter/isAccountInfoLoading",
      destinations: "attackCenter/manualDestinations",
    }),
  },
  watch: {
    selectedReport() {
      if (this.selectedReport) {
        this.getManualCreditAccounts({
          profileId: this.$route.params.id,
          reportId: this.selectedReport,
        });
        this.getDestinations({
          profileId: this.$route.params.id,
          reportId: this.selectedReport,
        });
        this.getGenerationSome({
          profileId: this.$route.params.id,
          reportId: this.selectedReport,
        });
      }
    },
    showNegatives(val) {
      if (val) {
        this.getNegativeManualCreditAccounts({
          profileId: this.$route.params.id,
          reportId: this.selectedReport,
        });
      } else {
        this.getManualCreditAccounts({
          profileId: this.$route.params.id,
          reportId: this.selectedReport,
        });
      }
    },
    selectedDestinations() {
      const destinationsId = this.selectedDestinations.map((el) => el.id);
      this.$emit("selected-destinations", destinationsId);
    },
  },
  created() {},
  methods: {
    ...mapActions({
      getManualCreditAccounts: "attackCenter/getManualCreditAccounts",
      getNegativeManualCreditAccounts:
        "attackCenter/getNegativeManualCreditAccounts",
      getAccountInformation: "attackCenter/getAccountInformation",
      getDestinations: "attackCenter/getDestinations",
      getGenerationSome: "attackCenter/getGenerationSome",
    }),
    async toggleReportCheckbox(id) {
      this.isReportChecked[id] = !this.isReportChecked[id];
      if (this.isReportChecked[id]) {
        this.selectedAccounts.push(id);
      } else {
        this.selectedAccounts = this.selectedAccounts.filter(
          (val) => val !== id
        );
      }
      if (this.selectedAccounts.length > 0) {
        let details = await this.getAccountInformation({
          accountId: this.selectedAccounts.slice(-1),
          reportId: this.selectedReport,
          selectedAccounts: this.selectedAccounts,
        });
        this.selectPositiveReporting(details);
        this.$emit("selected-account", this.selectedAccounts);
      }
      this.$emit("selected-account", this.selectedAccounts);
    },
    reportingToggler(reportName) {
      this.reportingOnly = reportName;
      this.accountDetails = this.creditInfo[reportName];
    },
    selectPositiveReporting(details) {
      for (let item in details) {
        if (details[item].reporting) {
          this.reportingOnly = item;
          this.accountDetails = this.creditInfo[item];
          break;
        }
      }
    },
    attackCenterStatus(item) {
      let returnable;
      let { status } = item;
      switch (status) {
        case "Open":
          returnable = this.$t(`attackcenter.Open`);
          break;
        case "Closed":
          returnable = this.$t(`attackcenter.Closed`);
          break;
        case "Paid":
          returnable = this.$t(`attackcenter.Paid`);
          break;
        case "Derogatory":
          returnable = this.$t(`attackcenter.Derogatory`);
          break;
        default:
          returnable = this.$t(`attackcenter.Open`);
          break;
      }
      return returnable;
    },
  },
};
</script>

<style></style>
