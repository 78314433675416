import Vue from "vue";
import moment from "moment";
Vue.filter("fullDate", function (value) {
  if (!value) return "";
  return moment(String(value)).format("YY/MM/DD");
});

Vue.filter("MwithDate", function (value) {
  if (!value) return "";
  return moment(String(value)).format("MMMM Do, YYYY");
});

Vue.filter("formatPrice", function (value) {
  let val = (value / 1).toFixed(0).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
});

