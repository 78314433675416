<template lang="">
  <div v-if="simpleLoader" class="tw-w-full tw-py-2">
    <div class="tw-mb-3 tw-flex tw-flex-col tw-items-center tw-space-y-2"></div>
    <v-progress-linear
      v-model="value"
      :buffer-value="bufferValue"
      height="15"
      rounded
    >
      <strong
        :class="value >= 50 ? 'tw-text-white' : 'tw-text-inherit'"
        class="tw-text-xs"
        >{{ Math.ceil(value) }}% ({{ timeRemaining }})
      </strong>
    </v-progress-linear>
  </div>
  <div
    v-else
    class="tw-w-full h-50 tw-py-8 tw-flex tw-items-center tw-justify-center"
  >
    <v-card elevation="2" class="tw-w-full md:tw-w-1/2 tw-px-6 tw-py-4">
      <div class="tw-mb-3 tw-flex tw-flex-col tw-items-center tw-space-y-2">
        <img alt="credified_logo" src="../../assets/icons/credified.svg" />
        <p>Credified loves working with you 😉...</p>
        <p v-if="timeRemaining !== null" class="tw-text-xs">
          {{ timeRemaining }}
        </p>
      </div>
      <v-progress-linear
        v-model="value"
        :buffer-value="bufferValue"
        height="15"
        rounded
      >
        <strong
          :class="value >= 50 ? 'tw-text-white' : 'tw-text-inherit'"
          class="tw-text-xs"
          >{{ Math.ceil(value) }}%
        </strong>
      </v-progress-linear>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    simpleLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 10,
      bufferValue: 20,
      interval: 0,
      startTime: null,
    };
  },

  watch: {
    value(val) {
      if (val < 100) return;

      this.value = 30;
      this.bufferValue = 40;
      this.startBuffer();
    },
  },

  mounted() {
    this.startBuffer();
  },

  beforeDestroy() {
    this.value = 98;
    this.bufferValue = 100;
    clearInterval(this.interval);
  },

  computed: {
    timeRemaining() {
      if (this.startTime === null) {
        return null;
      }
      const elapsedTime = (new Date() - this.startTime) / 1000;
      const progress = this.value / 100;
      const estimatedTotalTime = elapsedTime / progress;
      const estimatedRemainingTime = estimatedTotalTime - elapsedTime;
      return `${Math.ceil(estimatedRemainingTime)} seconds left`;
    },
  },

  methods: {
    startBuffer() {
      clearInterval(this.interval);
      this.startTime = new Date();

      this.interval = setInterval(() => {
        this.value += Math.random() * (15 - 5) + 5;
        this.bufferValue += Math.random() * (15 - 5) + 6;
      }, 3000);
    },
  },
};
</script>
