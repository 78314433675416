<template>
  <!-- <div v-if="isDownloading">
    <Loader />
  </div> -->
  <div>
    <v-dialog v-model="isOpen" width="700">
      <v-card width="700" class="tw-mx-auto">
        <v-card-title class="tw-flex tw-flex-row tw-justify-center">
          <p class="tw-font-extrabold tw-text-xl tw-text-center">
            {{ $t("attackcenter.SelectOptions") }}
          </p>
        </v-card-title>
        <div class="tw-py-1 tw-px-4">
          <!-- <div v-for="(item, index) in automatedOptions" :key="index">
            <v-checkbox
              v-model="selectedOptions"
              :label="item.name"
              :value="item.id"
              class="tw-rounded-md tw-px-3 tw-pt-2 tw-font-bold tw-text-black"
              :class="[
                selectedOptions.includes(item.id)
                  ? 'tw-border-2 tw-border-primary tw-bg-primary tw-bg-opacity-10'
                  : 'tw-border-2 tw-border-gray-100 tw-bg-white',
              ]"
            />
          </div> -->
          <div v-for="(item, index) in newOptions" :key="index">
            <v-checkbox
              v-model="newOptionsSelected"
              :label="item.name"
              :value="item.id"
              class="tw-rounded-md tw-px-3 tw-pt-2 tw-font-bold tw-text-black"
              :class="[
                newOptionsSelected.includes(item.id)
                  ? 'tw-border-2 tw-border-primary tw-bg-primary tw-bg-opacity-10'
                  : 'tw-border-2 tw-border-gray-100 tw-bg-white',
              ]"
            />
          </div>
        </div>
        <div class="tw-px-5">
          <Loader v-if="isGenerationsLoading" :simpleLoader="true" />
        </div>
        <v-card-actions class="tw-mb-3">
          <div class="tw-flex tw-flex-col tw-w-full">
            <div
              v-if="generationError"
              class="tw-text-xs tw-text-red-600 tw-mb-1 tw-px-2"
            >
              Error: Unable to process request right now, Try again later!
            </div>
            <div class="tw-w-full tw-flex tw-flex-row tw-justify-between">
              <div class="tw-w-1/2 tw-pr-2">
                <v-btn
                  large
                  class="tw-w-full"
                  color="white"
                  depressed
                  @click="isOpen = false"
                >
                  {{ $t("attackcenter.Cancel") }}
                </v-btn>
              </div>
              <div class="tw-w-1/2 tw-pl-2">
                <v-btn
                  large
                  class="tw-w-full"
                  color="primary"
                  depressed
                  :loading="isGenerationsLoading"
                  :disabled="newOptionsSelected.length < 1"
                  @click="generate"
                >
                  {{ $t("attackcenter.Generate") }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog ref="dialog" width="1000">
      <v-card>
        <div class="tw-px-5 tw-py-3 tw-mx-auto">
          <div class="tw-mx-auto tw-text-center tw-mb-2">
            <p class="tw-text-2xl tw-font-bold">Generation Success</p>
            <span class="tw-text-sm tw-text-gray-500">
              Your repair letters have been freshly generated, please download
              and mail them.
            </span>
          </div>
          <v-divider class="tw-mt-3" />
          <div class="tw-py-5">
            <div
              class="md:tw-w-8/12 tw-rounded-lg tw-bg-white h-124 tw-mx-auto"
            >
              <img
                class="tw-w-full md:tw-w-8/12 tw-rounded-lg tw-bg-white h-124 tw-mx-auto"
                src="https://i.imgur.com/j98kBYZ.png"
              />
            </div>
          </div>
          <v-divider />
          <div
            v-if="generationError"
            class="tw-text-xs tw-text-red-600 tw-mb-1 tw-mt-2 tw-px-2"
          >
            Error: Unable to process request right now, Try again later!
          </div>
          <div
            class="tw-flex tw-flex-col-reverse md:tw-flex md:tw-flex-row md:tw-justify-between tw-items-center tw-pt-3"
          >
            <div class="tw-w-full md:tw-w-max tw-mt-2 md:tw-mt-0">
              <v-btn
                class="tw-w-full"
                large
                color="white"
                @click="$refs.dialog.isActive = false"
              >
                {{ $t("attackcenter.Close") }}
              </v-btn>
            </div>
            <div class="tw-flex tw-items-center tw-pr-3 tw-space-x-2">
              <v-btn
                large
                class=""
                color="success"
                depressed
                @click="printLetter"
              >
                <v-icon>mdi-printer</v-icon>
                Mail Letters ($)
              </v-btn>
              <v-btn
                large
                class=""
                color="primary"
                depressed
                :loading="isGenerationsLoading"
                @click="generateLetter"
              >
                <img src="@/assets/icons/download.svg" alt="" class="tw-pr-2" />
                Download Letter(s)
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <FileDownloadingDialog ref="downloadingDialog" />
  </div>
</template>

<script>
// import Loader from "@/components/global/Loader.vue";
import Loader from "@/components/global/Loader.vue";
import { mapGetters, mapActions } from "vuex";
import FileDownloadingDialog from "./fileDownloadingDialog.vue";

export default {
  components: { FileDownloadingDialog, Loader },
  props: {
    reportId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
      isLetterOpen: false,
      isGenerationsLoading: false,
      selectedOptions: [],
      newOptions: [
        { id: "1", name: "Attack All Negative Accounts" },
        { id: "2", name: "Personal information only" },
        { id: "3", name: "Late Payments Only" },
        { id: "4", name: "Charge offs / Collections Only" },
        { id: "5", name: "Inquiries Only" },
        { id: "6", name: "Attack Everything Seperately" },
      ],
      newOptionsSelected: [],
      sessionId: null,
      isDownloading: false,
      variantId: 8150122299676,
      hasPrinted: false,
      generationError: false,
    };
  },
  computed: {
    ...mapGetters({
      automatedOptions: "attackCenter/automatedOptions",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      automatedGenerations: "attackCenter/automatedGenerations",
      isGenLoading: "attackCenter/isGenLoading",
      fileGenerationFunc: "attackCenter/fileGenerationFunc",
      getProfile: "profile/getProfile",
    }),
    async generate() {
      this.generationError = false;
      this.isGenerationsLoading = true;
      await this.automatedGenerations({
        // selectedOptions: this.selectedOptions,
        selectedOptions: this.newOptionsSelected,
        reportId: this.reportId,
        profileId: this.$route.params.id,
      })
        .then((res) => {
          if (res?.sessionId) {
            this.$refs.dialog.isActive = true;
            this.sessionId = res.sessionId;
            this.variantId = res.printVariantId;
            this.isOpen = false;
            // this.selectedOptions = [];
            this.newOptionsSelected = [];
          } else {
            this.generationError = true;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });

      this.isGenerationsLoading = false;
    },
    async generateLetter() {
      this.generationError = false;
      this.isGenerationsLoading = true;
      const profile = await this.getProfile(this.$route.params.id);
      const result = await this.fileGenerationFunc({
        sessionId: this.sessionId,
        profile: profile[0],
      });
      if (result) {
        this.$refs.dialog.isActive = false;
      } else {
        this.$refs.dialog.isActive = true;
        this.generationError = true;
      }
      this.isGenerationsLoading = false;
    },
    async printLetter() {
      const downloadLink = `https://app.credified.com/print/${this.sessionId}`;

      const counts = {};
      for (let index = 0; index < this.variantId.length; index++) {
        const id = this.variantId[index];

        if (!counts[id]) {
          counts[id] = 1;
        } else {
          counts[id] += 1;
        }
      }

      let products = [];
      for (const [key, value] of Object.entries(counts)) {
        products.push(key + ":" + value);
      }

      const url = `https://print-moose-mail.myshopify.com/cart/${products.join(
        ","
      )}?channel=buy_button&attributes[note]=${downloadLink}`;

      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-sheet.v-card {
    background: #edf3f8 !important;
  }
  .v-input__slot {
    flex-direction: row-reverse !important;
    margin-bottom: 0 !important;
  }
  .v-label {
    color: black !important;
  }
  .h-124 {
    height: 500px !important;
  }
}
</style>
