<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <v-card>
        <v-card-title>
          <h1>Request for Import</h1>
        </v-card-title>
        <v-card-text>
          <div class="">
            Click to browse the file and Submit the request to import the file.
          </div>

          <v-file-input
            v-model="file"
            :show-size="true"
            label="Click to browse"
            @change="handleFileUpload"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            style="color: white"
            :disabled="!file"
            @click="submitFile"
          >
            Send Request
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar
      ref="snackbar"
      variant="primary"
      timeout="10000"
      :text="snackbarText"
    />
  </v-row>
</template>

<script>
import Snackbar from "@/components/global/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  data() {
    return {
      dialog: false,
      snackbarText: "",
      file: null,
    };
  },
  methods: {
    handleFileUpload() {
      // Handle file upload logic if needed
    },
    submitFile() {
      if (this.file) {
        console.log("Uploaded file:", this.file);
        this.snackbarText = "Request Submitted Successfully!";
        this.$refs.snackbar.snackbar = true;
        this.dialog = false;
      } else {
        console.log("No file selected.");
      }
    },
  },
};
</script>
