import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      paymentData: {},
      generations: {
        generationsLeft: {
          value: 0,
          until: "",
        },
        generationsUsed: {
          value: 0,
          until: "",
        },
      },
    };
  },
  getters: {
    generations(state) {
      return state.generations;
    },
    paymentData(state) {
      return state.paymentData;
    },
  },
  mutations: {
    SET_GENERATIONS(state, payload) {
      state.generations = payload;
    },
    SET_PAYMENT(state, payload) {
      state.paymentData = payload;
    },
  },
  actions: {
    async createPayment(context, payload) {
      try {
        const response = await axios.get("/api/v1/payment/create/" + payload);
        context.commit("SET_PAYMENT", response.data);
        return response.data;
      } catch (error) {
        return console.warn(error);
      }
    },
    async loadGenerations(context) {
      try {
        const response = await axios.get("/api/v1/allgenerations");
        context.commit("SET_GENERATIONS", response.data);
        return response.data;
      } catch (error) {
        return console.warn(error);
      }
    },
    async AdminAddGeneration() {
      try {
        await axios.get("/api/v1/payment/add");
        // const response = await fetch("/api/v1/allgenerations", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_GENERATIONS", responseData);
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async saveSettings(context, payload) {
      try {
        const response = await axios.post(
          "/api/v1/settings",
          JSON.stringify(payload)
        );
        return response.data;
      } catch (error) {
        return console.warn(error);
      }
    },
    async sendPhoneNumber(context, payload) {
      try {
        console.log("payload", payload);
        const response = await axios.post("/api/v1/settings/phone", {
          phone: payload,
        });
        return response.data;
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
