export const messages = {
  en: {
    homepage: {
      Dashboard: "Dashboard",
      CreditsRemaining: "Credits Remaining",
      Account: "Account",
      DateLastActive: "Date Last Active",
      TotalFinished: "Total Finished",
      ItemsWaiting: "Items Waiting",
      Clients: "Clients",
      Onboarding: "Onboarding",
      Finished: "Finished",
      NeedsReview: "Needs Review",
      Name: "Name",
      Date: "Date",
      Status: "Status",
      ProfileUpdateLogs: "Profile Update Logs",
      Username: "Username",
      AccountID: "Account ID",
      NoDataAvailable: "No Data Available",
      Report: "Report",
      DateGenerated: "Date Generated",
      Bureau: "Bureau",
      Download: "Download",
      GenerationsLog: "Here's a list of your recent generations",
      ReportId: "Report ID",
      DestinationAddress: "Destination Address",
      GenerationsId: "Generations Id",
      ProfileName: "Profile Name",
      ProfileId: "Profile Id",
      NumberOfLetters: "Number Of letters",
    },
    attackcenter: {
      AttackCenter: "Repair Center",
      SelectClient: "Select Client",
      SelectProfile: "Select Profile",
      SelectClientReport: "Select Client Report",
      SelectProfileReport: "Select Profile Report",
      Select: "Select",
      Automatic: "Automatic",
      Manual: "Manual",
      Generate: "Generate",
      AutomaticallySelectCreditors: "Automatically Select Creditors",
      SelectAccount: "Select Account",
      AccountNumber: "Account Number",
      AccountType: "Account Type",
      Destinations: "Destinations",
      ShowOnlyNegative: "Show Only Negative",
      Receiving: "Receiving",
      Open: "Open",
      Derogatory: "Derogatory",
      Closed: "Closed",
      Close: "Close",
      Paid: "Paid",
      AddCreditReport: "Add credit report",
      ProfileDoesNotHaveAnyCreditReports:
        "Profile does not have any credit reports",
      ReportingOnlyOn: "Reporting only on",
      AttackEverything: "Attack everything",
      SelectOptions: "Select Options",
      Cancel: "Cancel",
      LetterPreview: "Letter Preview",
      HereIsTheFreshlyGeneratedLetterPreviewFor:
        "Here Is The Freshly Generated Letter",
      PrintDocument: "Print Document",
      DownloadPDF: "Download PDF",
      SendToEmail: "Sent To Email",
    },
    profile: {
      Profile: "Profile",
      PersonalInformation: "Personal Information",
      Name: "Name",
      Email: "Email",
      Phone: "Phone",
      Address: "Address",
      City: "City",
      State: "State",
      Zip: "Zip",
      Country: "Country",
      ChangePassword: "Change Password",
      CurrentPassword: "Current Password",
      NewPassword: "New Password",
      ConfirmPassword: "Confirm Password",
      Update: "Update",
      Cancel: "Cancel",
      UpdateSuccessful: "Update Successful",
      Waiting: "Esperando",
      Onboarding: "Inducción",
      NeedsReview: "Revisión de necesidades",
      Finished: "Acabado",
    },
    settings: {
      Settings: "Settings",
      Language: "Language",
      English: "English",
      Spanish: "Spanish",
      Timezone: "Timezone",
      Save: "Save",
      Cancel: "Cancel",
      SaveSuccessful: "Save Successful",
    },
  },
  es: {
    homepage: {
      Dashboard: "Tablero",
      CreditsRemaining: "Créditos restantes",
      Account: "Cuenta",
      DateLastActive: "Fecha Ultima Vez Activo",
      TotalFinished: "Total Terminados",
      NeedsReview: "Necesita Revisión",
      ItemsWaiting: "Esperando",
      Onboarding: "Incorporando",
      Finished: "Terminados",
      Name: "Nombre",
      Clients: "Clientes",
      Date: "Fecha",
      Status: "Estatus",
      ProfileUpdateLogs: "Bitácoras de Actualización de Perfil",
      Username: "Nombre Usuario",
      AccountID: "Cuenta ID",
      NoDataAvailable: "Sin Datos Disponibles",
      Report: "Informe",
      DateGenerated: "Fecha de generación",
      Bureau: "Oficina",
      Download: "Descargar",
      GenerationsLog: "Aquí hay una lista de sus generaciones recientes.",
      ReportId: "ID de informe",
      DestinationAddress: "Dirección de destino",
      GenerationsId: "Identificación de generaciones",
      ProfileName: "nombre de perfil",
      ProfileId: "Perfil Id",
      NumberOfLetters: "Número de letras",
    },
    attackcenter: {
      AttackCenter: "Centro de reparación",
      SelectClient: "Seleccionar cliente",
      SelectProfile: "Seleccionar Perfil",
      Recents: "Recientes",
      SelectClientReport: "Seleccione reporte de crédito",
      SelectProfileReport: "Seleccionar informe de perfil",
      Select: "Seleccionar",
      Automatic: "Automático",
      Manual: "Manual",
      Generate: "Generar",
      AutomaticallySelectCreditors: "Seleccionar acreedores automáticamente",
      SelectAccount: "Seleccionar cuenta",
      AccountNumber: "Número de cuenta",
      AccountType: "Tipo de cuenta",
      Destinations: "Destinos",
      ShowOnlyNegative: "Mostrar solo negativo",
      Receiving: "Recibiendo",
      Open: "Abierto",
      Derogatory: "Desfavorable",
      Closed: "Cerrar",
      Close: "Cerca",
      Paid: "pagado",
      AddCreditReport: "Agregar reporte de crédito",
      ProfileDoesNotHaveAnyCreditReports:
        "El perfil no tiene reportes de crédito",
      ReportingOnlyOn: "Reportando solo en",
      AttackEverything: "Atacar todo",
      SelectOptions: "Seleccionar opciones",
      Cancel: "Cancelar",
      LetterPreview: "Vista previa de carta",
      HereIsTheFreshlyGeneratedLetterPreviewFor:
        "Aquí esta la vista previa de la carta generada recientemente",
      PrintDocument: "Imprimir documento",
      DownloadPDF: "Descargar PDF",
      SendToEmail: "Enviar a correo electrónico",
    },
    profile: {
      Profile: "Perfil",
      PersonalInformation: "Información personal",
      Name: "Nombre",
      Email: "Correo electrónico",
      Phone: "Teléfono",
      Address: "Dirección",
      City: "Ciudad",
      State: "Estado",
      Zip: "Código Postal",
      Country: "País",
      ChangePassword: "Cambiar contraseña",
      CurrentPassword: "Contraseña actual",
      NewPassword: "Nueva contraseña",
      ConfirmPassword: "Confirmar contraseña",
      Update: "Actualizar",
      Cancel: "Cancelar",
      UpdateSuccessful: "Actualización exitosa",
    },
    settings: {
      Settings: "Configuraciones",
      Language: "Idioma",
      English: "Inglés",
      Spanish: "Español",
      Timezone: "Zona horaria",
      Save: "Guardar",
      Cancel: "Cancelar",
      SaveSuccessful: "Guardado exitoso",
    },
  },
};
