import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      profileLogs: [],
      profileInfo: {},
      states: [],
      profiles: [],
      generationsLog: [],
      isLoading: true,
      errorFetching: false,
    };
  },
  getters: {
    profileLogs(state) {
      return state.profileLogs;
    },
    profileInfo(state) {
      return state.profileInfo;
    },
    states(state) {
      return state.states;
    },
    profiles(state) {
      return state.profiles;
    },
    generationsLog(state) {
      return state.generationsLog;
    },
    isLoading(state) {
      return state.isLoading;
    },
    errorFetching(state) {
      return state.errorFetching;
    },
  },
  mutations: {
    SET_PROFILE_LOGS(state, payload) {
      state.profileLogs = payload;
    },
    SET_PROFILE(state, payload) {
      state.profileInfo = payload;
    },
    SET_SINGLE_PROFILES(state, payload) {
      state.singleProfileInfo = payload;
    },
    SET_STATES(state, payload) {
      state.states = payload;
    },
    SET_PROFILES(state, payload) {
      state.profiles = payload;
    },
    SET_GENERATIONSLOG(state, payload) {
      state.generationsLog = payload || [];
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    ERROR_FETCHING(state, payload) {
      state.errorFetching = payload;
    },
  },
  actions: {
    async loadProfileLogs(context) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get("/api/v1/profile/logs");
        context.commit("SET_PROFILE_LOGS", response.data);
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch("/api/v1/profile/logs", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_PROFILE_LOGS", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async createProfile(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/profile/create",
          JSON.stringify(payload)
        );
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch("/api/v1/profile/create", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async getStates(context) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get("/api/v1/getStates");
        context.commit("SET_STATES", response.data);
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch("/api/v1/getStates", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_STATES", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async getProfile(context, payload) {
      try {
        // context.commit("IS_LOADING", true);
        const response = await axios.get(`/api/v1/profile/details/${payload}`);
        context.commit("SET_PROFILE", [response.data]);
        // context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return [response.data];
      } catch (error) {
        // context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async editProfile(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.put(
          `/api/v1/profile/${payload.id}`,
          JSON.stringify(payload)
        );
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return response.data;
        // const response = await fetch("/api/v1/profile/1", {
        //   method: "PUT",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async uploadSsnDocuments(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/profile/documents/ssn",
          JSON.stringify(payload)
        );
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch("/api/v1/profile/documents/ssn", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async uploadPoaDocuments(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/profile/documents/address",
          JSON.stringify(payload)
        );
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch("/api/v1/profile/documents/address", {
        //   method: "POST",
        //   body: JSON.stringify(payload),
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async uploadIdOrLicenseDocuments(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.post(
          "/api/v1/profile/documents/idOrDriverLicense",
          JSON.stringify(payload)
        );
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch(
        //   "/api/v1/profile/documents/idOrDriverLicense",
        //   {
        //     method: "POST",
        //     body: JSON.stringify(payload),
        //   }
        // );
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async loadProfiles(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get("/api/v1/profiles");
        let resultArr = response.data.results;

        if (payload?.id) {
          const newData = resultArr.find((item) => item.id == payload.id);
          response.data.results = [newData];
        }
        context.commit("SET_PROFILES", response.data);
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = await fetch("/api/v1/clients", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_CLIENTS", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async loadGenerationsLog(context) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get("/api/v1/generations");
        if (response.data?.error === "No Data" || response.data?.ok == false) {
          context.commit("SET_GENERATIONSLOG", response.data);
          context.commit("IS_LOADING", false);
          context.commit("ERROR_FETCHING", true);
          return response.data;
        } else {
          context.commit("SET_GENERATIONSLOG", response.data.generations);
          context.commit("IS_LOADING", false);
          context.commit("ERROR_FETCHING", false);
          return response.data.generations;
        }
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async removeProfileById(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.delete(`/api/v1/profile/${payload}`);
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
        // const response = fetch(`/api/v1/clients/${payload}`, {
        //   method: "DELETE",
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async removeProfiles(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.delete(`/api/v1/profiles`, {
          profiles: JSON.stringify(payload),
        });
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
    async viewReports(context, payload) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get(`/api/v1/profile/${payload.profileId}/${payload.reportId}/view`);
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", false);
        return response.data;
      } catch (error) {
        context.commit("IS_LOADING", false);
        context.commit("ERROR_FETCHING", true);
        return console.warn(error);
      }
    },
  },
};
