<template>
  <div class="tw-w-full">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <div>
        <p class="tw-font-extrabold tw-text-3xl">
          {{ $t("attackcenter.AttackCenter") }}
        </p>
      </div>
      <div class="tw-flex tw-space-x-4 tw-flex-row tw-align-baseline">
        <div id="language-select">
          <v-select
            v-model="$i18n.locale"
            v-on:change="setLanguage"
            :items="langs"
            item-value="code"
            item-text="name"
            solo
          />
        </div>
        <notification-toggler />
        <notes-toggler />
      </div>
    </div>
  </div>
</template>

<script>
import NotesToggler from "@/components/global/NotesToggler.vue";
import NotificationToggler from "@/components/global/NotificationToggler.vue";
export default {
  components: { NotificationToggler, NotesToggler },
  data() {
    return {
      langs: [
        {
          code: "en",
          name: "English",
        },
        {
          code: "es",
          name: "Spanish",
        },
      ],
    };
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
    },
  },
};
</script>
