var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"tw-overflow-hidden"},[_c('v-app-bar',{staticClass:"tw-border-b tw-border-black",attrs:{"app":"","color":"custom-gray","height":"80","flat":""}},[_c('head-layout',{staticClass:"tw-hidden md:tw-block"}),_c('xs-head-layout',{staticClass:"tw-block md:tw-hidden"})],1),_c('xs-sidebar-menu'),_c('sidebar-menu'),_c('v-main',{staticClass:"custom-gray"},[_c('router-view',{staticClass:"tw-h-full tw-m-3 px-2 py-2 md:px-5 md:py-5"})],1),_c('v-navigation-drawer',{attrs:{"absolute":"","right":"","overlay-opacity":"0.9","width":"400","temporary":""},model:{value:(_vm.isDrowerOpen),callback:function ($$v) {_vm.isDrowerOpen=$$v},expression:"isDrowerOpen"}},[_c('div',{staticClass:"tw-bg-gray-300 tw-h-screen"},[_c('div',{staticClass:"tw-px-5 tw-py-4"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.toggleNotificationDrawer(false)}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1),_c('p',{staticClass:"tw-font-bold tw-text-lg"},[_vm._v("Alerts")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.toggleNotificationDrawer(false)}}},[_c('v-icon',[_vm._v(" mdi-menu ")])],1)],1)]),_c('v-divider',{staticClass:"tw-mt-2 tw-mb-3"}),(_vm.notifications.length <= 0)?_c('div',{staticClass:"tw-text-center"},[_vm._v(" No Data Exist ")]):_c('div',_vm._l((_vm.notifications),function(notif,index){return _c('div',{key:index,staticClass:"tw-px-4 tw-mb-3"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-justify-between tw-p-3 tw-rounded-lg",class:[
              notif.type === 'negative'
                ? 'tw-bg-error tw-bg-opacity-10'
                : notif.type === 'positive'
                ? 'tw-bg-success tw-bg-opacity-10'
                : 'tw-bg-primary tw-bg-opacity-10',
            ]},[_c('div',{staticClass:"tw-w-1/6"},[(notif.type === 'positive')?_c('img',{attrs:{"src":require("./assets/icons/positiveIcon.svg"),"alt":""}}):(notif.type === 'negative')?_c('img',{attrs:{"src":require("./assets/icons/negetiveIcon.svg"),"alt":""}}):(notif.type === 'general')?_c('img',{attrs:{"src":require("./assets/icons/generalIcon.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"tw-w-5/6"},[_c('p',{staticClass:"tw-font-extrabold tw-text-lg tw-text-black"},[_vm._v(" "+_vm._s(notif.title)+" ")]),_c('p',{staticClass:"tw-text-black tw-font-bold tw-text-sm"},[_vm._v(" "+_vm._s(notif.description)+" ")]),_c('span',{staticClass:"tw-text-sm tw-text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("fullDate")(notif.createdAt))+" ")])])])])}),0)],1)]),_c('notes-drawer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }