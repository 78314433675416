<template>
  <div class="tw-w-full">
    <div>
      <component :is="returnActiveHeadbar" />
    </div>
  </div>
</template>

<script>
import MainHeadbar from "./HeadComponents/MainHeadbar.vue";
import CreditAnalysisHeadbar from "./HeadComponents/CreditAnalysisHeadbar.vue";
import CreateProfileHeadbar from "./HeadComponents/CreateProfileHeadbar.vue";
import EditProfileHeadbar from "./HeadComponents/EditProfileHeadbar.vue";
import SettingsHeadbar from "./HeadComponents/SettingsHeadbar.vue";
import AttackCenterHeadbar from "./HeadComponents/AttackCenterHeadbar.vue";

export default {
  components: { MainHeadbar },
  computed: {
    returnActiveHeadbar() {
      switch (this.$route.path || this.$route.name) {
        case "/dashboard":
          return MainHeadbar;
        case "/home":
          return MainHeadbar;
        case "/credit-analysis":
          return CreditAnalysisHeadbar;
        case "/create-profile":
          return CreateProfileHeadbar;
        case "profile-edit":
          return EditProfileHeadbar;
        case "/settings":
          return SettingsHeadbar;
        case "/repair-center":
          return AttackCenterHeadbar;
        default:
          return EditProfileHeadbar;
      }
    },
  },
};
</script>

<style></style>
