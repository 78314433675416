<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card v-if="isLoading">
        <v-card-title class="text-h6">
          Just a minute, Credified is verifying 😊
        </v-card-title>
        <div class="tw-p-5">
          <v-progress-linear
            color="#0b47ae"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$router.push('/home')">
            Go to Dashboard
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="!isLoading && this.profiles?.results.length > 0">
        <v-card-title class="text-h5">
          You are not allowed to create multiple profiles!
        </v-card-title>
        <v-card-text>
          If you want to make the new profile, Delete the previous one and come
          back again!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$router.push('/home')">
            Go to Dashboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      profiles: "profile/profiles",
    }),
    currentTip() {
      return this.tips[this.currentTipIndex];
    },
  },
  async mounted() {
    if (window.VUE_APP_APP_TYPE === "b2c") {
      this.isLoading = true;
      this.dialog = true;
      await this.loadProfiles();
      this.isLoading = false;
      if (this.profiles?.results.length > 0) {
        this.$router.push(`edit-profile/${this.profiles?.results[0].id}`);
      } else if (this.profiles?.results.length < 1) {
        this.dialog = false;
      }
    }
  },
  methods: {
    ...mapActions({
      loadProfiles: "profile/loadProfiles",
    }),
  },
};
</script>
