import axios from "../../axios";

export default {
  namespaced: true,
  state() {
    return {
      userInfo: {},
      isLoading: true,
    };
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    SET_USER_INFO(state, payload) {
      state.userInfo = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async loadUserInfo(context) {
      context.commit("IS_LOADING", true);
      try {
        const response = await axios.get("/api/v1/myInformation");
        context.commit("SET_USER_INFO", response.data);
        context.commit("IS_LOADING", false);
        return response.data;
        // const response = await fetch("/api/v1/myInformation", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_USER_INFO", responseData);
        // return responseData;
      } catch (error) {
        context.commit("IS_LOADING", false);
        return console.warn(error);
      }
    },
  },
};
