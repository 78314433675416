<template>
  <div>
    <div v-if="isLoading" class="tw-w-full tw-h-full">
      <Loader />
    </div>
    <div v-if="!isLoading">
      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4">
        <div class="tw-w-full md:tw-w-1/2 tw-flex tw-flex-col tw-space-y-4">
          <div class="tw-flex tw-flex-col tw-space-y-4">
            <v-card
              id="gc-greetings"
              class="tw-w-full tw-px-4 tw-py-8 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col tw-space-y-1">
                <span class="tw-text-xl"> {{ todayDate() }} </span>
                <span class="tw-font-bold tw-text-3xl">
                  Welcome, {{ userInfo.fullName }}
                </span>
                <img
                  alt="welcome-svg"
                  src="../assets/icons/welcome-svg.svg"
                  class="tw-absolute tw-w-auto tw-h-[70px] md:tw-h-[100px] tw-right-5 tw-bottom-2"
                />
              </div>
            </v-card>
            <div
              class="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-space-y-4 md:tw-space-y-0 md:tw-space-x-4"
            >
              <v-card
                class="md:tw-w-1/2 tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
              >
                <p class="tw-text-base tw-font-semibold">
                  Lets Begin: 3 Step Process 🚀
                </p>
                <p class="tw-text-sm tw-mt-1">
                  <b>1-</b> First
                  <v-btn
                    small
                    color="primary"
                    class="tw-my-2 tw-w-max"
                    @click="$router.push('/settings')"
                  >
                    Purchase
                  </v-btn>
                  a generation credits &nbsp;
                </p>
                <p class="tw-text-sm tw-mt-1">
                  <b>2-</b> Second &nbsp;
                  <v-btn
                    small
                    color="primary"
                    class="tw-my-2 tw-w-max"
                    @click="$router.push('/create-profile')"
                  >
                    Create Profile
                  </v-btn>
                  and connect your credit report
                </p>

                <p class="tw-text-sm tw-mt-1">
                  <b>3-</b> Generate personalized letters in
                  <v-btn
                    small
                    color="primary"
                    class="tw-my-2 tw-w-max"
                    @click="$router.push('/repair-center')"
                  >
                    Repair Center
                  </v-btn>
                </p>
              </v-card>
              <!-- checklist for web -->
              <v-card
                class="tw-hidden md:tw-block md:tw-w-1/2 tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
              >
                <p class="tw-text-base tw-font-semibold">
                  <v-icon class="tw-pr-2">mdi-format-list-checks</v-icon
                  >Checklist
                </p>
                <div class="tw-mt-4">
                  <v-checkbox
                    class="tw-mt-0"
                    v-for="item in checklistItems"
                    :disabled="item.disabled"
                    :key="item.id"
                    v-model="item.checked"
                    :label="item.label"
                  ></v-checkbox>
                </div>
              </v-card>
            </div>
            <div
              class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 md:tw-space-x-4"
            >
              <v-card
                id="gc-generationsLeft"
                style="margin-left: 0px"
                class="tw-w-full tw-flex md:tw-w-3/5 tw-px-4 tw-py-4 tw-rounded-lg"
                @click="$router.push('/settings')"
              >
                <div class="tw-flex tw-items-center">
                  <v-progress-circular
                    :rotate="270"
                    :size="65"
                    :width="5"
                    :value="userInfo.generationsLeft.value"
                    color="primary"
                  >
                    <span
                      class="tw-text-black tw-font-extrabold tw-text-xl tw-m-2"
                    >
                      {{ userInfo.generationsLeft.value }}
                    </span>
                  </v-progress-circular>
                  <div class="tw-pl-3 tw-flex tw-flex-col">
                    <span class="tw-text-lg tw-font-extrabold w-100">
                      {{ $t("homepage.CreditsRemaining") }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            style="align-items: baseline"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span
                          >Credits are the chances, that how many times you can
                          generate letters</span
                        >
                      </v-tooltip>
                    </span>
                    <span class="tw-text-sm">
                      {{ $t("homepage.Account") }} #{{
                        userInfo.generationsLeft.account
                      }}
                    </span>
                    <v-btn outlined color="primary" class="tw-my-2 tw-w-max">
                      Buy now
                    </v-btn>
                    <span class="tw-text-xs tw-text-[#959595]"
                      >1 credit is utilized to generate personalized credit
                      repair letters, for all your negative accounts including
                      credit bureaus and creditors</span
                    >
                  </div>
                </div>
              </v-card>
              <v-card
                id="gc-tips"
                class="tw-w-full md:tw-w-2/5 tw-px-4 tw-py-4 tw-rounded-lg"
              >
                <p class="tw-text-base tw-font-semibold">Tips 🔔</p>
                <p class="tw-text-xs tw-mt-1">
                  We would recommend at least 4 attacks per user for
                  <b>maximum success</b>
                </p>
                <p class="tw-text-xs tw-mt-1">
                  To watch a tutorial visit <b>“Credified Guide”</b>
                </p>
              </v-card>
              <!-- checklist for mobile -->
              <v-card
                class="md:tw-hidden md:tw-w-1/2 tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
              >
                <p class="tw-text-base tw-font-semibold">
                  <v-icon class="tw-pr-2">mdi-format-list-checks</v-icon
                  >Checklist
                </p>
                <div class="tw-mt-4">
                  <v-checkbox
                    class="tw-mt-0"
                    v-for="item in checklistItems"
                    :disabled="item.disabled"
                    :key="item.id"
                    v-model="item.checked"
                    :label="item.label"
                  ></v-checkbox>
                </div>
              </v-card>
            </div>
          </div>
          <div
            class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 md:tw-space-x-6"
          >
            <v-card
              id="gc-dateJoined"
              class="tw-w-full md:tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col">
                <span class="tw-text-gray-500 tw-text-xs pb-2 tw-font-bold">
                  Date Joined
                </span>
                <p class="tw-text-dark tw-font-extrabold tw-text-xl">
                  {{ userInfo.dateJoined | MwithDate }}
                </p>
              </div>
            </v-card>
            <v-card
              id="gc-lastActive"
              class="tw-w-full md:tw-w-full tw-px-4 tw-py-4 tw-rounded-lg"
            >
              <div class="tw-flex tw-flex-col">
                <span class="tw-text-gray-500 tw-text-xs pb-2 tw-font-bold">
                  {{ $t("homepage.DateLastActive") }}
                </span>
                <p class="tw-text-dark tw-font-extrabold tw-text-xl">
                  {{ userInfo.lastActiveDate | MwithDate }}
                </p>
              </div>
            </v-card>
          </div>
        </div>
        <div
          class="tw-w-full md:tw-w-1/2 tw-space-y-4 md:tw-md-0 tw-flex md:tw-flex tw-flex-col-reverse md:tw-flex-row md:tw-space-x-4"
        >
          <div
            v-if="!profiles || !profiles?.results"
            class="tw-bg-white tw-w-full tw-my-3 tw-space-y-4 md:tw-my-0 md:tw-w-1/2 tw-rounded-lg tw-overflow-hidden"
          >
            <v-sheet color="tw-grey lighten-4'" class="pa-3">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="image"
              />
            </v-sheet>
          </div>
          <div
            v-else-if="profiles && profiles?.results"
            class="tw-bg-white tw-w-full tw-my-3 md:tw-my-0 md:tw-w-1/2 tw-rounded-lg tw-overflow-hidden"
          >
            <custom-calendar :profiles="profiles.results" />
          </div>
          <div
            class="tw-w-full md:tw-w-1/2 tw-p-5 tw-bg-primary tw-shadow-primary tw-shadow-lg tw-rounded-lg tw-flex tw-flex-col tw-h-max"
          >
            <div class="tw-flex tw-flex-row tw-justify-center tw-w-full">
              <img
                src="../assets/icons/circle-check.svg"
                style="width: 100px"
                alt=""
              />
            </div>
            <div class="tw-py-3 tw-pt-7 tw-w-full">
              <p
                class="tw-mb-0 tw-text-2xl tw-text-white tw-text-center tw-font-extrabold mb-8"
              >
                Credified Guide
              </p>
              <p class="tw-text-gray-100 tw-text-center">
                Click on Get Started to learn about your dashboard
              </p>
            </div>
            <div>
              <v-btn
                class="tw-w-full my-3 tw-text-primary"
                large
                depressed
                @click="startWalkthrough"
              >
                Get Started
              </v-btn>
              <v-btn
                class="tw-w-full mb-3 tw-text-primary"
                color="white"
                large
                outlined
                depressed
                @click="dialogueHandler"
              >
                <img
                  src="../assets/icons/play-button.svg"
                  class="tw-w-8 tw-mr-2"
                  alt="play-button"
                />
                Watch Tutorial
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <v-card class="my-5 tw-p-8">
        <div
          v-if="!isProfileLoading && profiles && profiles.results[0]"
          class="tw-flex tw-flex-col tw-justify-between tw-items-baseline tw-mb-4"
        >
          <div
            class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between"
          >
            <p class="tw-font-bold tw-text-xl">Profile</p>
            <div class="tw-flex tw-items-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="gc-editProfile"
                    icon
                    color="#0c48af"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      () =>
                        $router.push(`/edit-profile/${profiles.results[0].id}`)
                    "
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="gc-deleteProfile"
                    icon
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="remove(profiles.results[0].id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
          <div
            id="gc-profileInfo"
            class="tw-w-full tw-flex tw-flex-col tw-mt-4"
          >
            <div
              class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-2 tw-border-solid tw-border-b-[1px]"
            >
              <p class="tw-text-base">Name</p>
              <p class="tw-font-semibold">
                {{ profiles.results[0].name || "" }}
              </p>
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-2 tw-border-solid tw-border-b-[1px]"
            >
              <p class="tw-text-base">Date</p>
              <p class="tw-font-semibold">
                {{ moment(profiles.results[0].date) || "" }}
              </p>
            </div>
            <div
              class="tw-w-full tw-flex tw-items-center tw-justify-between tw-py-2 tw-border-solid tw-border-b-[1px]"
            >
              <p class="tw-text-base">Status</p>
              <p
                class="tw-font-semibold"
                :class="[
                  profiles.results[0].status == 'Onboarding'
                    ? 'tw-text-[#0c48af]'
                    : profiles.results[0].status == 'Waiting'
                    ? 'tw-text-[#ff9700]'
                    : profiles.results[0].status == 'Needs Review'
                    ? 'tw-text-[#ff0000]'
                    : 'tw-text-[#26b400]',
                ]"
              >
                {{ profiles.results[0].status || "" }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="isProfileLoading">
          <Loader />
        </div>
        <div v-if="!isProfileLoading && profiles.results.length < 1">
          <div
            class="tw-flex h-50 tw-flex-col tw-items-center tw-justify-center"
          >
            <p>No Profile Available</p>
            <v-btn
              color="primary"
              outlined
              depressed
              class="tw-font-bold tw-shadow-lg tw-mt-2"
              @click="$router.push('create-profile')"
            >
              Create Profile
            </v-btn>
          </div>
        </div>
      </v-card>
      <generations-history-logs />
      <profile-update-logs-list />
      <watch-tutorial-dialogue ref="tutDialogue" />
    </div>
    <OnBoardingScreens ref="onBoardingScreens" />
    <!-- delete dialog -->
    <v-dialog v-model="isDeleteModalOpen" max-width="550">
      <v-card class="tw-p-3">
        <p class="tw-font-extrabold tw-text-lg tw-pb-5">
          Are you sure you want to delete
        </p>
        <div class="tw-flex tw-flex-row tw-justify-between tw-mt-3 tw-pt-5">
          <div class="tw-pr-3 tw-w-1/2">
            <v-btn
              class="tw-w-full"
              color="primary"
              outlined
              :disabled="isDeleting"
              @click="isDeleteModalOpen = false"
            >
              No
            </v-btn>
          </div>
          <div class="tw-pl-3 tw-w-1/2">
            <v-btn
              class="tw-w-full"
              color="primary"
              :loading="isDeleting"
              @click="removeClient(profiles.results[0].id)"
            >
              Yes
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-btn
      small
      color="primary"
      class="tw-p-2 tw-w-max tw-shadow-xl tw-shadow-white/50"
      style="
        position: fixed;
        bottom: 3rem;
        right: 3rem;
        height: 70px;
        width: 70px;
        border-radius: 100%;
      "
      @click="FabHandler"
    >
      <p class="tw-text-2xl">?</p>
    </v-btn>
    <get-phone-number-dialogue ref="getPhoneNumberDialogue" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomCalendar from "@/components/global/CustomCalendar.vue";
import ProfileUpdateLogsList from "@/components/pages/dashboard/ProfileUpdateLogsList.vue";
import Loader from "@/components/global/Loader.vue";
import GuideChimp from "guidechimp";
import WatchTutorialDialogue from "@/components/global/WatchTutorialDialogue.vue";
import GetPhoneNumberDialogue from "@/components/global/GetPhoneNumberDialogue.vue";
import GenerationsHistoryLogs from "@/components/pages/dashboard/GenerationsHistoryLogs.vue";
import moment from "moment";
import OnBoardingScreens from "@/components/global/OnBoardingScreens.vue";

export default {
  components: {
    CustomCalendar,
    ProfileUpdateLogsList,
    GetPhoneNumberDialogue,
    Loader,
    WatchTutorialDialogue,
    GenerationsHistoryLogs,
    OnBoardingScreens,
  },
  data() {
    return {
      generationsLeft: 30,
      isDeleteModalOpen: false,
      isDeleting: false,
      tips: [
        "credits are the tickets to use in the repair center and to generate documents. You can always buy it more.",
        "credit is a set of letters for all your accounts across all bureaus and creditors.",
        "Make sure to visit Credified every 30 days after mailing each set of letters to generate a new batch of letters to mail if accounts don’t remove continue until success.",
        "Credified is a simple 3-step process. CREATE PROFILE | ADD REPORT | MAIL THE LETTERS",
      ],
      currentTipIndex: 0,
      checklistItems: [
        { id: 1, label: "Create Profile", checked: false, disabled: false },
        {
          id: 2,
          label: "Connect Credit Report",
          checked: false,
          disabled: false,
        },
        {
          id: 3,
          label: "Generate Credified Letters",
          checked: false,
          disabled: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "dashboard/userInfo",
      isLoading: "dashboard/isLoading",
      profiles: "profile/profiles",
      generationsLog: "profile/generationsLog",
      isProfileLoading: "profile/isLoading",
    }),
    currentTip() {
      return this.tips[this.currentTipIndex];
    },
  },
  async mounted() {
    await this.loadUserInfo();
    await this.loadProfiles();
    if (this.userInfo?.showOnboarding) {
      this.$refs.onBoardingScreens.dialog = true;
    }
    // -----------------
    if (this.profiles?.results.length > 0) {
      this.checklistItems = this.checklistItems.map((item) =>
        item.label == "Create Profile"
          ? { ...item, checked: true, disabled: true }
          : { ...item }
      );
    }
    // console.log("this.userInfo", this.userInfo?.onboarding.report);
    if (this.userInfo?.onboarding.report) {
      this.checklistItems = this.checklistItems.map((item) =>
        item.id == 2 ? { ...item, checked: true, disabled: true } : { ...item }
      );
    }
    // check if generations have been generated
    if (this.generationsLog?.length > 0) {
      this.checklistItems = this.checklistItems.map((item) =>
        item.id == 3 ? { ...item, checked: true, disabled: true } : { ...item }
      );
    }

    // check if phone number is available
    if (this.userInfo?.phoneNumber && !this.userInfo?.phoneNumber) {
      this.$refs.getPhoneNumberDialogue.dialog = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions({
      loadProfiles: "profile/loadProfiles",
      removeProfileById: "profile/removeProfileById",
      loadUserInfo: "dashboard/loadUserInfo",
    }),
    startWalkthrough() {
      var guideChimp = GuideChimp([
        {
          element: "#gc-greetings",
          title: "Greetings",
          description: "First of all, Welcome😉!",
        },
        {
          element: "#gc-generationsLeft",
          title: "Generations Left",
          description:
            "These are the generations that are left in your account, You can always buy them upon clicking on it!",
        },
        {
          element: "#gc-tips",
          title: "Tips",
          description:
            "Here you will be having some really nice tips, so you can utilize maximum potential of Credified",
        },
        {
          element: "#gc-dateJoined",
          title: "Date Join",
          description: "This is the date showing your Date of Joining",
        },
        {
          element: "#gc-lastActive",
          title: "Last Active",
          description: "This is the date showing your Last Active",
        },
        {
          element: "#gc-profileInfo",
          title: "Profile Info",
          description: "This is the section showing Profile you have created.",
        },
        {
          element: "#gc-editProfile",
          title: "Edit Profile",
          description: "You can edit your profile anytime.",
        },
        {
          element: "#gc-deleteProfile",
          title: "Delete Profile",
          description: "You can delete your profile anytime, and make new one",
        },
        {
          element: "#gc-generationLogs",
          title: "Generations History",
          description: "Here, you will be shown all of your generation logs!",
        },
      ]);
      guideChimp.start();
    },
    dialogueHandler() {
      this.$refs.tutDialogue.dialog = !this.$refs.tutDialogue.dialog;
    },
    moment(date) {
      return moment(date).format("MM/DD/YYYY");
    },
    todayDate() {
      const today = moment();
      const formattedDate = today.format("dddd, MMMM Do");
      return formattedDate;
    },
    remove(item) {
      this.isDeleteModalOpen = true;
      this.item = item;
    },
    async removeClient(id) {
      this.isDeleting = true;
      await this.removeProfileById(id);
      this.isDeleting = false;
      this.isDeleteModalOpen = false;
      await this.loadProfiles();
    },
    FabHandler() {
      this.$refs.onBoardingScreens.dialog = true;
    },
  },
};
</script>

<style></style>
