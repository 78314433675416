<!-- eslint-disable vue/html-indent -->
<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <div class="tw-w-full">
      <v-alert
        v-if="errorFetching"
        color="red"
        text
        class="tw-flex tw-items-center tw-self-center"
        type="error"
        ><div class="tw-flex tw-items-center">
          <span>An unknown error occurred!</span>
          <span
            class="tw-font-bold underline tw-cursor-pointer"
            style="color: #f44336; text-decoration: underline"
            @click="
              () => {
                this.$router.go();
              }
            "
          >
            Try Again
          </span>
        </div>
      </v-alert>
    </div>
    <div
      class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-space-x-4"
    >
      <div class="tw-w-full md:tw-w-1/2">
        <v-card class="tw-p-3">
          <div
            class="tw-flex tw-items-center tw-justify-between tw-align-center"
          >
            <p class="tw-text-md md:tw-text-xl tw-font-bold">
              {{ $t("profile.PersonalInformation") }}
            </p>
            <text-with-badge />
          </div>
          <v-divider class="tw-my-5" />
          <div id="gc-personalInfo">
            <v-form>
              <div class="tw-flex tw-flex-row tw-flex-wrap">
                <form-item
                  id="gc-credifiedProfileName"
                  v-model="profile.profileName"
                  :label="CredifiedProfileNameLabel"
                  type="textbox"
                  class="tw-w-full"
                />
                <form-item
                  v-model="profile.firstName"
                  :label="FirstNameLabel"
                  type="textbox"
                  class="tw-w-full md:tw-w-1/2 md:tw-pr-3"
                />
                <form-item
                  v-model="profile.lastName"
                  :label="LastNameLabel"
                  type="textbox"
                  class="tw-w-full md:tw-w-1/2 md:tw-pl-3"
                />
                <!-- phone number -->
                <div class="tw-w-full md:tw-w-1/2 md:tw-pr-3">
                  <p class="tw-text-sm tw-text-gray">
                    {{ $t("profile.Phone") }}
                  </p>
                  <div>
                    <v-text-field
                      v-model="profile.phone"
                      v-mask="'+1 (###) ### ####'"
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <!-- phone number -->
                <div class="tw-w-full md:tw-w-1/2 md:tw-pl-3">
                  <p class="tw-text-sm tw-text-gray">
                    {{ $t("profile.Email") }}
                  </p>
                  <div>
                    <v-text-field
                      v-model="profile.mail"
                      outlined
                      :rules="emailRules"
                      class="tw-w-full md:tw-pl-3"
                    ></v-text-field>
                  </div>
                </div>
                <div class="tw-w-full md:tw-w-1/2 md:tw-pr-3">
                  <p class="tw-text-sm tw-text-gray">Social Security Number</p>
                  <div>
                    <v-text-field
                      v-model="maskedSsn"
                      v-mask="'###-##-####'"
                      :append-icon="visibilityIconSSN"
                      type="textbox"
                      outlined
                      @click:append="toggleVisibilitySSN"
                    />
                  </div>
                </div>
                <!-- Date picker -->
                <div class="tw-w-full md:tw-w-1/2 md:tw-pl-3">
                  <p class="tw-text-sm tw-text-gray">Date of Birth</p>
                  <div>
                    <v-text-field
                      v-model="maskedDob"
                      outlined
                      type="date"
                      :max="maxDate"
                      :rules="dobRules"
                      placeholder="MM/DD/YYYY"
                      :append-icon="visibilityIconDOB"
                      pattern="^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)[0-9]{2}$"
                      @click:append="toggleVisibilityDOB"
                    ></v-text-field>
                  </div>
                </div>
                <!-- Date picker -->
                <div class="tw-w-full md:tw-w-1/2 md:tw-pr-3">
                  <p class="tw-text-sm tw-text-gray">Street Address</p>
                  <div>
                    <v-text-field
                      v-model="maskedStreet"
                      outlined
                      :append-icon="visibilityIconStreet"
                      type="textbox"
                      class="capitalize"
                      @click:append="toggleVisibilityStreet"
                    ></v-text-field>
                  </div>
                </div>
                <form-item
                  v-model="profile.city"
                  :label="CityLabel"
                  type="textbox"
                  class="tw-w-full md:tw-w-1/2 md:tw-pl-3"
                />
                <form-item
                  v-model="profile.state"
                  :items="states"
                  :label="StateLabel"
                  class="tw-w-full md:tw-w-1/2 md:tw-pr-3"
                  type="select"
                  item-text="name"
                  item-value="id"
                  place-holder="Select"
                />
                <form-item
                  v-model="profile.postalCode"
                  :label="ZipLabel"
                  maxlength="7"
                  type="textbox"
                  class="tw-w-full md:tw-w-1/2 md:tw-pl-3"
                />
              </div>
              <div id="gc-pod">
                <p class="tw-text-xl tw-font-bold">Proof of Documents</p>
                <v-divider class="tw-my-3" />
                <div
                  class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-space-y-5 md:tw-space-x-5 md:tw-space-y-0"
                >
                  <div
                    class="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col tw-justify-between"
                  >
                    <div class="tw-flex tw-justify-between tw-mb-1">
                      <p>Social Security Number</p>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            style="align-items: baseline"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>Social Security Number</span>
                      </v-tooltip>
                    </div>
                    <input
                      ref="ssnUploader"
                      type="file"
                      class="tw-hidden"
                      accept="image/jpeg,image/png"
                      @change="showUploadSsn"
                    />
                    <div
                      class="tw-rounded-xl tw-p-3 tw-py-5 tw-border-2 tw-cursor-pointer"
                      :class="documentsClass.ssnDocs"
                      @click="$refs.ssnUploader.click()"
                    >
                      <img
                        v-if="ssnDocs.error"
                        src="../../assets/icons/ssnIconError.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <img
                        v-if="ssnDocs.isUploaded && !ssnDocs.error"
                        src="../../assets/icons/ssnIconActive.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <img
                        v-if="!ssnDocs.isUploaded && !ssnDocs.error"
                        src="../../assets/icons/ssnIcon.svg"
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <div
                        v-if="!ssnDocs.isUploaded && !ssnDocs.error"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span class="tw-mx-auto tw-text-center tw-text-sm">
                          Drag .png or .jpg file here
                        </span>
                      </div>
                      <div
                        v-if="ssnDocs.error && !ssnDocs.isUploaded"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span
                          class="tw-mx-auto tw-text-center tw-text-sm tw-text-error"
                        >
                          Error Uploading! File too large
                        </span>
                      </div>
                      <div
                        v-if="ssnDocs.isUploaded && !ssnDocs.error"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span
                          class="tw-mx-auto tw-text-center tw-text-sm tw-text-success"
                        >
                          File successfully uploaded!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col tw-justify-between"
                  >
                    <div class="tw-flex tw-justify-between tw-mb-1">
                      <p>
                        Proof of Address (First Page of Utility/Bank Statement)
                      </p>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            style="align-items: baseline"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>First Page of Utility/Bank Statement</span>
                      </v-tooltip>
                    </div>
                    <input
                      ref="poaUploader"
                      type="file"
                      class="tw-hidden"
                      accept="image/jpeg,image/png"
                      @change="showUploadPoa"
                    />
                    <div
                      class="tw-rounded-xl tw-p-3 tw-py-5 tw-border-2 tw-cursor-pointer"
                      :class="documentsClass.poaDocs"
                      @click="$refs.poaUploader.click()"
                    >
                      <img
                        v-if="poaDocs.error"
                        src="../../assets/icons/proofofaddressIconError.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <img
                        v-if="poaDocs.isUploaded && !poaDocs.error"
                        src="../../assets/icons/proofofaddressIconActive.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <img
                        v-if="!poaDocs.isUploaded && !poaDocs.error"
                        src="../../assets/icons/proofofaddressIcon.svg"
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <div
                        v-if="!poaDocs.isUploaded && !poaDocs.error"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span class="tw-mx-auto tw-text-center tw-text-sm">
                          Drag .png or .jpg file here
                        </span>
                      </div>
                      <div
                        v-if="poaDocs.error"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span
                          class="tw-mx-auto tw-text-center tw-text-sm tw-text-error"
                        >
                          Error Uploading! File too large
                        </span>
                      </div>
                      <div
                        v-if="poaDocs.isUploaded && !poaDocs.error"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span
                          class="tw-mx-auto tw-text-center tw-text-sm tw-text-success"
                        >
                          File successfully uploaded!
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col tw-justify-between"
                  >
                    <div class="tw-flex tw-justify-between tw-mb-1">
                      <p>ID or Driver’s License</p>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            style="align-items: baseline"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert-circle-outline
                          </v-icon>
                        </template>
                        <span>ID or Driver's License</span>
                      </v-tooltip>
                    </div>
                    <input
                      ref="idOrDriverLicense"
                      type="file"
                      class="tw-hidden"
                      accept="image/jpeg,image/png"
                      @change="showUploadIdOrDriverLicense"
                    />
                    <div
                      class="tw-rounded-xl tw-p-3 tw-py-5 tw-border-2 tw-cursor-pointer"
                      :class="documentsClass.idOrLicenseDocs"
                      @click="$refs.idOrDriverLicense.click()"
                    >
                      <img
                        v-if="
                          idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error
                        "
                        src="../../assets/icons/idLicenseIconActive.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <img
                        v-if="
                          idOrLicenseDocs.error && !idOrLicenseDocs.isUploaded
                        "
                        src="../../assets/icons/idLicenseIconError.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <img
                        v-if="
                          !idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error
                        "
                        src="../../assets/icons/idLicenseIcon.svg"
                        alt=""
                        class="tw-mx-auto tw-h-12 tw-my-5"
                      />
                      <div
                        v-if="
                          !idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error
                        "
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span class="tw-mx-auto tw-text-center tw-text-sm">
                          Drag .png or .jpg file here
                        </span>
                      </div>
                      <div
                        v-if="idOrLicenseDocs.error"
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span
                          class="tw-mx-auto tw-text-center tw-text-sm tw-text-error"
                        >
                          Error Uploading! File too large
                        </span>
                      </div>
                      <div
                        v-if="
                          idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error
                        "
                        class="tw-w-full tw-flex tw-justify-center"
                      >
                        <span
                          class="tw-mx-auto tw-text-center tw-text-sm tw-text-success"
                        >
                          File successfully uploaded!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-btn
                color="success"
                large
                class="tw-w-full tw-mt-5"
                :loading="isLoading"
                :disabled="isRequesting || isFileUploading"
                @click="save"
              >
                <v-icon class="tw-px-3"> mdi-check-circle-outline </v-icon>
                Create Profile
              </v-btn>
            </v-form>
          </div>
        </v-card>
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-mt-5 md:tw-mt-0">
        <v-expansion-panels
          v-model="activeCredit"
          class="tw-transition-all tw-delay-75 tw-ease-in"
          :class="[
            !profileId ? 'tw-blur-sm tw-pointer-events-none' : 'tw-blur-none',
          ]"
        >
          <v-expansion-panel
            class="tw-mb-3 tw-transition-all tw-delay-75 tw-ease-in"
            :class="[
              activeCreditAccount === 0 || activeCreditAccount === 2
                ? 'tw-blur-sm tw-tw-pointer-events-none'
                : 'tw-blur-none',
              activeCreditAccount === 1
                ? 'tw-border-2 tw-border-success tw-border-opacity-50'
                : 'tw-border-2 tw-border-transparent',
            ]"
          >
            <v-expansion-panel-header expand-icon="mdi-plus">
              <div>
                <img
                  src="../../assets/png/MyFreeScoreNow.svg"
                  class="tw-max-w-[271px] tw-max-h-[50px]"
                  alt="IdentityIQ-Registered"
                />
                <p class="tw-my-4 tw-font-bold tw-text-md tw-text-primary">
                  (Recommended)
                </p>
                <div class="tw-flex tw-flex-row tw-mt-2">
                  <span
                    class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-1.5 tw-mr-1"
                    :class="[
                      activeCreditAccount === 1
                        ? 'tw-bg-success tw-border tw-border-success tw-text-success'
                        : 'tw-bg-gray-200 tw-border tw-border-gray-400 tw-text-gray-400',
                    ]"
                  />
                  <div
                    class="tw-text-md"
                    :class="[
                      activeCreditAccount === 1
                        ? 'tw-text-success'
                        : ' tw-text-gray-500',
                    ]"
                  >
                    {{
                      activeCreditAccount === 1 ? "connected" : "not connected"
                    }}
                  </div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                v-if="activeCreditAccount !== 1"
                class="tw-flex tw-flex-row tw-flex-wrap"
              >
                <form-item
                  v-model="myFreeScoreNow.username"
                  :label="UserNameLabel"
                  class="tw-w-1/2 tw-pr-3"
                />
                <form-item
                  v-model="myFreeScoreNow.password"
                  label="Password"
                  class="tw-w-1/2 tw-pl-3"
                />
                <v-btn
                  color="primary"
                  large
                  class="tw-w-full"
                  @click="myFreeScoreNowLogin"
                >
                  Sign Into myFreeScoreNow
                </v-btn>
                <span class="tw-text-center tw-mx-auto tw-py-3">
                  Don’t have an account yet?
                  <b class="tw-text-primary tw-cursor-pointer">Sign Up</b>
                </span>
              </v-form>
              <div v-else>
                <div
                  class="tw-w-2/5 tw-my-6 tw-px-3 tw-py-3 tw-bg-subtitle tw-bg-opacity-10 tw-text-center tw-rounded-lg tw-mx-auto"
                >
                  <p class="tw-text-black tw-font-extrabold tw-text-xl tw-mb-2">
                    {{ activeCreditAccountInfo.mail }}
                  </p>
                  <span class="tw-text-gray-500 tw-text-sm tw-text-bold">
                    Signed in on {{ activeCreditAccountInfo.signedInOn }}
                  </span>
                </div>
                <div class="tw-w-2/5 tw-mx-auto tw-mb-4">
                  <v-btn
                    color="primary"
                    class="tw-w-full"
                    large
                    rounded
                    @click="logoutmyFreeScoreNow"
                  >
                    Sign Out
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            class="tw-mb-3 tw-transition-all tw-delay-75 tw-ease-in"
            :class="[
              activeCreditAccount === 1 || activeCreditAccount === 2
                ? 'tw-blur-sm tw-tw-pointer-events-none'
                : 'tw-blur-none',
              activeCreditAccount === 0
                ? 'tw-border-2 tw-border-success tw-border-opacity-50'
                : 'tw-border-2 tw-border-transparent',
            ]"
          >
            <v-expansion-panel-header expand-icon="mdi-plus">
              <div>
                <img
                  src="../../assets/png/IdentityIQ.png"
                  alt="IdentityIQ-Registered"
                />
                <div class="tw-flex tw-flex-row tw-mt-2">
                  <span
                    class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-1.5 tw-mr-1"
                    :class="[
                      activeCreditAccount === 0
                        ? 'tw-bg-success tw-border tw-border-success tw-text-success'
                        : 'tw-bg-gray-200 tw-border tw-border-gray-400 tw-text-gray-400',
                    ]"
                  />
                  <div
                    class="tw-text-md"
                    :class="[
                      activeCreditAccount === 0
                        ? 'tw-text-success'
                        : ' tw-text-gray-500',
                    ]"
                  >
                    {{
                      activeCreditAccount === 0 ? "connected" : "not connected"
                    }}
                  </div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                v-if="activeCreditAccount !== 0"
                class="tw-flex tw-flex-row tw-flex-wrap"
              >
                <form-item
                  v-model="identityIq.username"
                  :label="UserNameLabel"
                  class="tw-w-1/2 tw-pr-3"
                />
                <form-item
                  v-model="identityIq.password"
                  label="Password"
                  class="tw-w-1/2 tw-pl-3"
                />
                <form-item
                  v-model="identityIq.sqa"
                  label="Secret Question Answer"
                  class="tw-w-full"
                />
                <v-btn
                  color="primary"
                  large
                  class="tw-w-full"
                  @click="identityIQLogin"
                >
                  Sign Into IdentitiyIQ
                </v-btn>
                <span class="tw-text-center tw-mx-auto tw-py-3">
                  Don’t have an account yet?
                  <b class="tw-text-primary tw-cursor-pointer">Sign Up</b>
                </span>
              </v-form>
              <div v-else>
                <div
                  class="tw-w-2/5 tw-my-6 tw-px-3 tw-py-3 tw-bg-subtitle tw-bg-opacity-10 tw-text-center tw-rounded-lg tw-mx-auto"
                >
                  <p class="tw-text-black tw-font-extrabold tw-text-xl tw-mb-2">
                    {{ activeCreditAccountInfo.mail }}
                  </p>
                  <span class="tw-text-gray-500 tw-text-sm tw-text-bold">
                    Signed in on {{ activeCreditAccountInfo.signedInOn }}
                  </span>
                </div>
                <div class="tw-w-2/5 tw-mx-auto tw-mb-4">
                  <v-btn
                    color="primary"
                    class="tw-w-full"
                    large
                    rounded
                    @click="logoutIdentityIq"
                  >
                    Sign Out
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            class="tw-mb-3 tw-transition-all tw-delay-75 tw-ease-in"
            :class="[
              activeCreditAccount === 0 || activeCreditAccount === 1
                ? 'tw-blur-sm tw-tw-pointer-events-none'
                : 'tw-blur-none',
              activeCreditAccount === 2
                ? 'tw-border-2 tw-border-success tw-border-opacity-50'
                : 'tw-border-2 tw-border-transparent',
            ]"
          >
            <v-expansion-panel-header expand-icon="mdi-plus">
              <div>
                <img
                  src="../../assets/png/smartCredit.png"
                  alt="IdentityIQ-Registered"
                />
                <div class="tw-flex tw-flex-row tw-mt-2">
                  <span
                    class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-1.5 tw-mr-1"
                    :class="[
                      activeCreditAccount === 2
                        ? 'tw-bg-success tw-border tw-border-success tw-text-success'
                        : 'tw-bg-gray-200 tw-border tw-border-gray-400 tw-text-gray-400',
                    ]"
                  />
                  <div
                    class="tw-text-md"
                    :class="[
                      activeCreditAccount === 2
                        ? 'tw-text-success'
                        : ' tw-text-gray-500',
                    ]"
                  >
                    {{
                      activeCreditAccount === 2 ? "connected" : "not connected"
                    }}
                  </div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                v-if="activeCreditAccount !== 2"
                class="tw-flex tw-flex-row tw-flex-wrap"
              >
                <form-item
                  v-model="smartCredit.username"
                  :label="UserNameLabel"
                  class="tw-w-1/2 tw-pr-3"
                />
                <form-item
                  v-model="smartCredit.password"
                  label="Password"
                  class="tw-w-1/2 tw-pl-3"
                />
                <form-item
                  v-model="smartCredit.sqa"
                  label="Secret Question Answer"
                  class="tw-w-full"
                />
                <v-btn
                  color="primary"
                  large
                  class="tw-w-full"
                  @click="smartCreditLogin"
                >
                  Sign Into smartCredit
                </v-btn>
                <span class="tw-text-center tw-mx-auto tw-py-3">
                  Don’t have an account yet?
                  <b class="tw-text-primary tw-cursor-pointer">Sign Up</b>
                </span>
              </v-form>
              <div v-else>
                <div
                  class="tw-w-2/5 tw-my-6 tw-px-3 tw-py-3 tw-bg-subtitle tw-bg-opacity-10 tw-text-center tw-rounded-lg tw-mx-auto"
                >
                  <p class="tw-text-black tw-font-extrabold tw-text-xl tw-mb-2">
                    {{ activeCreditAccountInfo.mail }}
                  </p>
                  <span class="tw-text-gray-500 tw-text-sm tw-text-bold">
                    Signed in on {{ activeCreditAccountInfo.signedInOn }}
                  </span>
                </div>
                <div class="tw-w-2/5 tw-mx-auto tw-mb-4">
                  <v-btn
                    color="primary"
                    class="tw-w-full"
                    large
                    rounded
                    @click="logoutSmartCredit"
                  >
                    Sign Out
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <DialogueB2C />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextWithBadge from "@/components/global/TextWithBadge.vue";
import FormItem from "@/components/global/FormItem.vue";
import DialogueB2C from "@/components/pages/profile/DialogueB2C.vue";
import moment from "moment";

export default {
  components: { FormItem, TextWithBadge, DialogueB2C },
  data() {
    return {
      activeCredit: null,
      profile: {
        firtName: "",
        lastName: "",
        phone: "",
        mail: "",
        city: "",
        state: "",
        postalCode: "",
      },
      isLoading: false,
      isFileUploading: false,
      profileId: null,
      ssnDocs: {
        data: null,
        isUploaded: false,
        error: false,
      },
      poaDocs: {
        data: null,
        isUploaded: false,
        error: false,
      },
      idOrLicenseDocs: {
        data: null,
        isUploaded: false,
        error: false,
      },
      identityIq: {
        username: null,
        password: null,
        sqa: null,
      },
      myFreeScoreNow: {
        username: null,
        password: null,
        sqa: null,
      },
      smartCredit: {
        username: null,
        password: null,
        sqa: null,
      },
      activeCreditAccount: -1,
      activeCreditAccountInfo: {},
      documentsClass: {
        default: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
        success:
          "tw-border-solid tw-bg-success tw-bg-opacity-10 tw-border-green-400",
        error: "tw-border-solid tw-bg-error tw-bg-opacity-10 tw-border-error",
        ssnDocs: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
        poaDocs: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
        idOrLicenseDocs: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
      },
      ssn: "",
      isVisibleSsn: true,
      dob: "",
      streetAddress: "",
      isVisibleDob: true,
      isVisibleStreet: true,
      maxDate: "",
    };
  },
  computed: {
    ...mapGetters({
      states: "profile/states",
      isRequesting: "profile/isLoading",
      errorFetching: "profile/errorFetching",
    }),
    emailRules() {
      return [
        (value) => !!value || "Email is required",
        (value) => /^\S+@\S+\.\S+$/.test(value) || "Email is not valid",
      ];
    },
    dobRules() {
      return [
        (v) => {
          let now = new Date();
          let dob = new Date(v);
          let age = now.getFullYear() - dob.getFullYear();
          if (age < 18) {
            return "You must be at least 18 years old";
          }
          return true;
        },
      ];
    },
    visibilityIconSSN() {
      return this.isVisibleSsn ? "mdi-eye" : "mdi-eye-off";
    },
    maskedSsn: {
      get() {
        if (!this.isVisibleSsn) {
          return `***-**-${this.ssn.substr(this.ssn.length - 4)}`;
        }
        return this.ssn;
      },
      set(value) {
        this.ssn = value;
      },
    },
    visibilityIconDOB() {
      return this.isVisibleDob ? "mdi-eye" : "mdi-eye-off";
    },
    maskedDob: {
      get() {
        if (!this.isVisibleDob) {
          return `**/**/${this.dob.substr(this.dob.length - 4)}`;
        }
        return this.dob;
      },
      set(value) {
        this.dob = value;
      },
    },
    visibilityIconStreet() {
      return this.isVisibleStreet ? "mdi-eye" : "mdi-eye-off";
    },
    maskedStreet: {
      get() {
        if (!this.isVisibleStreet) {
          return `***${this.streetAddress.substr(
            this.streetAddress.length - 4
          )}`;
        }
        return this.streetAddress;
      },
      set(value) {
        this.streetAddress = value;
      },
    },
    CredifiedProfileNameLabel() {
      return `Credified ${this.$t("profile.Profile")} ${this.$t(
        "profile.Name"
      )}`;
    },
    FirstNameLabel() {
      return "First" + this.$t("profile.Name");
    },
    LastNameLabel() {
      return "Last" + this.$t("profile.Name");
    },
    EmailAddressLabel() {
      return this.$t("profile.Email");
    },
    StateLabel() {
      return this.$t("profile.State");
    },
    CityLabel() {
      return this.$t("profile.City");
    },
    ZipLabel() {
      return this.$t("profile.Zip");
    },
    UserNameLabel() {
      return this.$t("homepage.Username");
    },
  },
  mounted() {
    this.getStates();
    let today = new Date();
    this.maxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    )
      .toISOString()
      .substr(0, 10);
  },
  methods: {
    ...mapActions({
      createProfile: "profile/createProfile",
      // uploadSsnDocuments: "profile/uploadSsnDocuments",
      // uploadPoaDocuments: "profile/uploadPoaDocuments",
      // uploadIdOrLicenseDocuments: "profile/uploadIdOrLicenseDocuments",
      getStates: "profile/getStates",
      uploadDocs: "cdn/uploadDocs",
      uplaodPiiImage: "cdn/uplaodPiiImage",
      uplaodSSNImage: "cdn/uplaodSSNImage",
      uplaodIDImage: "cdn/uplaodIDImage",
      identityLogin: "creditProdiver/identityIqLogin",
      identityLogout: "creditProdiver/identityIqLogout",
      initialLogin: "creditProdiver/myFreeScoreNowLogin",
      initalLogout: "creditProdiver/myFreeScoreNowLogout",
      smartLogin: "creditProdiver/smartCreditLogin",
      smartLogout: "creditProdiver/smartCreditLogout",
    }),
    parseDate(dateString) {
      if (dateString === "") {
        return dateString;
      }
      let date = new Date(dateString);
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    async save() {
      this.isLoading = true;
      this.isFileUploading = true;
      const res = await this.createProfile({
        ...this.profile,
        dob: moment(this.maskedDob).format("YYYY-MM-DD"),
        ssn: this.ssn,
        streetAddress: this.streetAddress,
        ssnDocs: this.ssnDocs.data,
        poaDocs: this.poaDocs.data,
        idDocs: this.idOrLicenseDocs.data,
      });
      if (res) {
        await this.uploadSsn(res.id);
        await this.uploadPoa(res.id);
        await this.uploadIdOrDriverLicense(res.id);
        this.$router.push(`/edit-profile/${res.id}`);
        this.profileId = res.id;
        this.isFileUploading = false;
      }
      this.isLoading = false;
    },
    showUploadSsn() {
      // if (this.profileId) {
      let files = this.$refs.ssnUploader.files;
      let formData = new FormData();
      formData.append("ssnDocs", files[0]);
      this.ssnDocs.data = formData;
      this.ssnDocs.isUploaded = true;
      this.ssnDocs.error = false;
      this.documentsClass.ssnDocs = this.documentsClass.success;
    },
    async uploadSsn(id) {
      try {
        let data = { profileId: id, data: this.ssnDocs.data };
        const result = await this.uplaodSSNImage(data).then((res) => res);
        if (result.ok) {
          this.ssnDocs.isUploaded = true;
          this.ssnDocs.error = false;
          this.documentsClass.ssnDocs = this.documentsClass.success;
        } else {
          this.ssnDocs.isUploaded = false;
          this.ssnDocs.error = true;
          this.documentsClass.ssnDocs = this.documentsClass.error;
        }
      } catch (error) {
        console.error("ERROR", error);
        this.ssnDocs.isUploaded = false;
        this.ssnDocs.error = true;
        this.documentsClass.ssnDocs = this.documentsClass.error;
      }
    },
    showUploadPoa() {
      let files = this.$refs.poaUploader.files;
      let formData = new FormData();
      formData.append("POA", files[0]);
      this.poaDocs.data = formData;

      this.poaDocs.isUploaded = true;
      this.poaDocs.error = false;
      this.documentsClass.poaDocs = this.documentsClass.success;
    },
    async uploadPoa(id) {
      // let files = this.$refs.poaUploader.files;
      // let formData = new FormData();
      // formData.append("POA", files[0]);
      // this.poaDocs.data = formData;
      try {
        let data = { profileId: id, data: this.poaDocs.data };
        const result = await this.uplaodPiiImage(data).then((res) => res);
        if (result.ok) {
          this.poaDocs.isUploaded = true;
          this.poaDocs.error = false;
          this.documentsClass.poaDocs = this.documentsClass.success;
        } else {
          this.poaDocs.isUploaded = false;
          this.poaDocs.error = true;
          this.documentsClass.poaDocs = this.documentsClass.error;
        }
      } catch (error) {
        console.error("ERROR", error);
        this.poaDocs.isUploaded = false;
        this.poaDocs.error = true;
        this.documentsClass.poaDocs = this.documentsClass.error;
      }
    },
    showUploadIdOrDriverLicense() {
      let files = this.$refs.idOrDriverLicense.files;
      let formData = new FormData();
      formData.append("idOrLicenseDocs", files[0]);
      this.idOrLicenseDocs.data = formData;

      this.idOrLicenseDocs.isUploaded = true;
      this.idOrLicenseDocs.error = false;
      this.documentsClass.idOrLicenseDocs = this.documentsClass.success;
    },
    async uploadIdOrDriverLicense(id) {
      // if (this.profileId) {
      // let files = this.$refs.idOrDriverLicense.files;
      // let formData = new FormData();
      // formData.append("idOrLicenseDocs", files[0]);
      // this.idOrLicenseDocs.data = formData;
      try {
        let data = { profileId: id, data: this.idOrLicenseDocs.data };
        const result = await this.uplaodIDImage(data).then((res) => res);
        if (result.ok) {
          this.idOrLicenseDocs.isUploaded = true;
          this.idOrLicenseDocs.error = false;
          this.documentsClass.idOrLicenseDocs = this.documentsClass.success;
        } else {
          this.idOrLicenseDocs.isUploaded = false;
          this.idOrLicenseDocs.error = true;
          this.documentsClass.idOrLicenseDocs = this.documentsClass.error;
        }
      } catch (error) {
        console.error("ERROR", error);
        this.idOrLicenseDocs.isUploaded = false;
        this.idOrLicenseDocs.error = true;
        this.documentsClass.idOrLicenseDocs = this.documentsClass.error;
      }
    },
    identityIQLogin() {
      this.identityLogin(this.identityIq).then((res) => {
        if (res.result) {
          this.activeCreditAccount = 0;
          this.activeCreditAccountInfo = res;
        }
      });
    },
    logoutIdentityIq() {
      this.identityLogout().then((res) => {
        if (res.result) {
          this.activeCreditAccount = null;
          this.activeCreditAccountInfo = { res };
        }
      });
    },
    myFreeScoreNowLogin() {
      this.initialLogin(this.identityIq).then((res) => {
        if (res.result) {
          this.activeCreditAccount = 1;
          this.activeCreditAccountInfo = res;
        }
      });
    },
    logoutmyFreeScoreNow() {
      this.initalLogout().then((res) => {
        if (res.result) {
          this.activeCreditAccount = null;
          this.activeCreditAccountInfo = { res };
        }
      });
    },
    smartCreditLogin() {
      this.smartLogin(this.identityIq).then((res) => {
        if (res.result) {
          this.activeCreditAccount = 2;
          this.activeCreditAccountInfo = res;
        }
      });
    },
    logoutSmartCredit() {
      this.smartLogout().then((res) => {
        if (res.result) {
          this.activeCreditAccount = null;
          this.activeCreditAccountInfo = { res };
        }
      });
    },
    toggleVisibilitySSN() {
      this.isVisibleSsn = !this.isVisibleSsn;
    },
    toggleVisibilityDOB() {
      this.isVisibleDob = !this.isVisibleDob;
    },
    toggleVisibilityStreet() {
      this.isVisibleStreet = !this.isVisibleStreet;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    background-color: #f6fbff !important;
    border-color: #dfe7ec !important;
  }
  .v-expansion-panel-header--active {
    border-bottom: 1px solid #dfe7ec !important;
  }
  .v-expansion-panel-content__wrap {
    padding-top: 1rem !important;
  }
  .capitalize input {
    text-transform: capitalize !important;
  }
}
</style>
