<template>
  <div
    class="tw-px-2 tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full"
  >
    <v-btn
      v-model="isOpen"
      color="white"
      depressed
      large
      @click="toggleXsSidebar"
    >
      <v-icon> mdi-menu </v-icon>
    </v-btn>
    <img src="../../../assets/icons/credified.svg" alt="credified" />
    <notification-toggler />
    <notes-toggler />
  </div>
</template>

<script>
import NotesToggler from "@/components/global/NotesToggler.vue";
import NotificationToggler from "@/components/global/NotificationToggler.vue";
import { mapMutations } from "vuex";
export default {
  components: { NotificationToggler, NotesToggler },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    isOpen(value) {
      this.toggleSidebar(value);
    },
  },
  methods: {
    ...mapMutations({
      toggleSidebar: "drawer/toggleSidebar",
    }),
    toggleXsSidebar(value) {
      this.toggleSidebar(value);
    },
  },
};
</script>

<style></style>
