import axios from "@/axios.js";

export default {
  namespaced: true,
  actions: {
    async uploadDocs(context, payload) {
      try {
        const response = await axios.post(
          "/api/v1/cdn/upload",
          JSON.stringify(payload)
        );
        return response.data;
        // const response = await fetch('/api/v1/cdn/upload',
        // {
        //     method: 'POST',
        //     body:payload
        // })
        // const responseData = await response.json()
        // return responseData
      } catch (error) {
        return console.warn(error);
      }
    },
    async uplaodSSNImage(context, payload) {
      try {
        const response = await axios.post(
          `/api/v1/profile/upload/${payload.profileId}/ssn`,
          payload.data,
          {
            headers: {'Content-Type': 'multipart/form-data' }
          }
        );
        return response.data;
        // const response = await fetch('/api/v1/cdn/upload',
        // {
        //     method: 'POST',
        //     body:payload
        // })
        // const responseData = await response.json()
        // return responseData
      } catch (error) {
        return console.warn(error);
      }
    },
    async uplaodPiiImage(context, payload) {
      try {
        const response = await axios.post(
          `/api/v1/profile/upload/${payload.profileId}/poa`,
          payload.data,
          {
            headers: {'Content-Type': 'multipart/form-data' }
          }
        );
        return response.data;
        // const response = await fetch('/api/v1/cdn/upload',
        // {
        //     method: 'POST',
        //     body:payload
        // })
        // const responseData = await response.json()
        // return responseData
      } catch (error) {
        return console.warn(error);
      }
    },
    async uplaodIDImage(context, payload) {
      try {
        const response = await axios.post(
          `/api/v1/profile/upload/${payload.profileId}/id`,
          payload.data,
          {
            headers: {'Content-Type': 'multipart/form-data' }
          }
        );
        return response.data;
        // const response = await fetch('/api/v1/cdn/upload',
        // {
        //     method: 'POST',
        //     body:payload
        // })
        // const responseData = await response.json()
        // return responseData
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
