import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#0C48AF",
        "primary-light": "#1D65DF",
        warning: "#FF9700",
        error: "#F00",
        "error-light": "#FF006A",
        success: "#26B400",
        subtitle: "#747C89",
        title: "#1A1F25",
        "custom-gray": "#F7F9FC",
        "form-bg": "#F6FBFF",
        "light-blue": "#EDF3F8",
        gray:'#EDEFF3'
      },
    },
  },
});
