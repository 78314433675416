<template>
  <div>
    <v-btn
      v-model="isOpen"
      depressed
      large
      color="white"
      @click="toggleNotesDrawerState"
    >
      <v-icon> mdi-note-outline </v-icon> <span class="tw-hidden md:tw-block">Notes</span>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
  },
  watch: {
    isOpen(value) {
      this.toggleNotesDrawer(value);
    },
  },
  methods: {
    ...mapMutations({
      toggleNotesDrawer: "drawer/toggleNotesDrawer",
    }),
    toggleNotesDrawerState(value) {
      this.toggleNotesDrawer(value);
    },
  },
};
</script>

<style></style>
