<template>
  <div class="tw-w-full">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <div>
        <p class="tw-font-extrabold tw-text-3xl">
          {{ profileInfo.profileName }}
        </p>
      </div>
      <div class="tw-flex tw-space-x-4 tw-flex-row tw-align-baseline">
        <div id="language-select">
          <v-select
            v-model="$i18n.locale"
            v-on:change="setLanguage"
            :items="langs"
            item-value="code"
            item-text="name"
            solo
          />
        </div>
        <!-- <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              large
              depressed
              color="white"
              v-bind="attrs"
              v-on="on"
            >
              <span class="tw-text-black"> English </span>
              <v-icon color="black">
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  large
                  depressed
                  class="tw-w-full"
                  color="transparent"
                >
                  Spanish
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  large
                  depressed
                  class="tw-w-full"
                  color="transparent"
                >
                  Russians
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  large
                  depressed
                  class="tw-w-full"
                  color="transparent"
                >
                  Arabic
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <notification-toggler />
        <notes-toggler />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationToggler from "@/components/global/NotificationToggler.vue";
import NotesToggler from "@/components/global/NotesToggler.vue";
export default {
  components: { NotificationToggler, NotesToggler },
  data() {
    return {
      langs: [
        {
          code: "en",
          name: "English",
        },
        {
          code: "es",
          name: "Spanish",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      profileInfo: "profile/profileInfo",
    }),
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
    },
  },
};
</script>

<style></style>
