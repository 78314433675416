<template>
  <div class="tw-w-full">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <div>
        <p class="tw-font-extrabold tw-text-3xl">
          {{ $t("homepage.Dashboard") }}
        </p>
      </div>
      <div class="tw-flex tw-space-x-4 tw-flex-row tw-align-baseline">
        <v-btn
          v-if="window.IS_SUPPORT"
          large
          color="primary"
          outlined
          class="tw-font-bold tw-shadow-lg"
          @click="AdminAddGeneration"
        >
          Add Generation
        </v-btn>
        <v-btn
          v-if="window.IS_SUPPORT"
          large
          color="primary"
          outlined
          class="tw-font-bold tw-shadow-lg"
          @click="AdminImpersonate"
        >
          Impersonate
        </v-btn>
        <v-btn
          v-if="isB2B"
          large
          color="primary"
          depressed
          class="tw-font-bold tw-shadow-lg"
          @click="$router.push('credit-analysis')"
        >
          <v-icon class="tw-pr-3"> mdi-magnify </v-icon>
          Credit Analysis
        </v-btn>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn large color="white" depressed dark v-bind="attrs" v-on="on">
              <img
                src="../../../assets/icons/AddNew.svg"
                alt=""
                class="tw-pr-3"
              />
              <span class="tw-text-black"> Add New </span>
              <v-icon color="black"> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  large
                  depressed
                  class="tw-w-full"
                  color="transparent"
                  @click="$router.push('/create-profile')"
                >
                  Client
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isB2B">
              <v-list-item-title>
                <v-btn large depressed class="tw-w-full" color="transparent">
                  Affiliate
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-menu :close-on-content-click="false" offset-x open-on-hover>
                <template #activator="{ on, attrs }">
                  <v-btn
                    large
                    v-bind="attrs"
                    depressed
                    class="child-menu"
                    v-on="on"
                  >
                    Outsourcer
                    <v-icon color="black" class="tw-mt-1">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        large
                        depressed
                        color="transparent"
                        class="tw-w-full"
                      >
                        Prospect Buyer
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        large
                        depressed
                        color="transparent"
                        class="tw-w-full"
                      >
                        Reseller
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-list>
        </v-menu>

        <div id="language-select">
          <v-select
            v-model="$i18n.locale"
            v-on:change="setLanguage"
            :items="langs"
            item-value="code"
            item-text="name"
            solo
          />
        </div>
        <notification-toggler />
        <NotesToggler />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import NotesToggler from "@/components/global/NotesToggler.vue";
import NotificationToggler from "@/components/global/NotificationToggler.vue";
export default {
  components: { NotificationToggler, NotesToggler },
  data() {
    return {
      langs: [
        {
          code: "en",
          name: "English",
        },
        {
          code: "es",
          name: "Spanish",
        },
      ],
      window,
    };
  },
  computed: {
    isB2B() {
      return window.VUE_APP_APP_TYPE === "b2b";
    },
    ...mapGetters({
      userInfo: "dashboard/userInfo",
      profiles: "profile/profiles",
    }),
  },
  methods: {
    ...mapActions({
      AdminAddGeneration: "setting/AdminAddGeneration",
    }),
    setLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
    },
    AdminImpersonate() {
      const targetUserId = window.prompt(
        "Enter the user id you want to impersonate"
      );
      if (targetUserId) {
        window.TARGET_USER_ID = targetUserId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .child-menu {
    background-color: transparent !important;
  }
}
</style>
