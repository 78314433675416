<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <v-btn large class="" color="primary" depressed @click="generate">
      <img src="@/assets/icons/download.svg" alt="" class="tw-pr-2" />
      Download Letter(s)
    </v-btn>
    <FileDownloadingDialog ref="downloadingDialog" />
  </div>
  <!-- Alert -->
</template>

<script>
import { mapActions } from "vuex";
import FileDownloadingDialog from "../../components/pages/attackCenter/fileDownloadingDialog.vue";

export default {
  components: { FileDownloadingDialog },
  data() {
    return {
      id: null,
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    this.id = id;
    await this.getCSVFileFunc({ sessionId: this.id });
  },
  methods: {
    ...mapActions({
      fileGenerationFunc: "attackCenter/fileGenerationFunc",
      getCSVFileFunc: "attackCenter/getCSVFileFunc",
    }),
    async generate() {
      await this.fileGenerationFunc({
        sessionId: this.id,
        profile: {
          profileName: this.id,
        },
      });
    },
  },
};
</script>
