<template lang="">
  <div>
    <img
      src="../assets/icons/404-pages.svg"
      class="tw-mx-auto tw-h-96 tw-my-5"
    />
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
<style lang=""></style>
