<template lang="">
  <div class="text-center">
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <div class="tw-flex tw-justify-center" style="margin-bottom: 30px">
          <img
            v-if="dialogueVariant == 'success' || null"
            src="../../../assets/icons/success-icon.svg"
            style="height: 80px; margin-top: -55px; position: fixed"
            alt=""
          />
          <img
            v-if="dialogueVariant == 'error'"
            src="../../../assets/icons/error-icon.svg"
            style="height: 80px; margin-top: -55px; position: fixed"
            alt=""
          />
        </div>
        <v-card-title class="text-h5 tw-text-center tw-block lighten-2">
          {{
            dialogueVariant == "success" || null
              ? "Credit Report Connected"
              : errorMessage
          }}
        </v-card-title>

        <div class="tw-p-4 tw-text-center tw-block lighten-2">
          {{
            dialogueVariant == "success"
              ? "Credit Report has been connected! Move to repair center for getting reports"
              : errorMessage
              ? errorMessage.includes("Login Failed")
                ? "Please login and make sure your 3b report is available."
                : errorMessage.includes("Billing Error")
                ? `please visit ${companyName} and make sure your billing is correct.`
                : "Unable to connect, Please try Again!"
              : "Unable to connect, Please try Again!"
          }}
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dialogueVariant == 'success' || null"
            block
            color="primary"
            @click="$router.push(`/repair-center/${$route.params.id}`)"
          >
            Move to repair center
          </v-btn>
          <v-btn
            v-if="dialogueVariant == 'error'"
            block
            color="primary"
            @click="dialog = false"
          >
            Try Again
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogueVariant: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: "Unable to Connect Credit Report",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    companyName() {
      return window.location.origin;
    },
  },
};
</script>
<style lang=""></style>
