<template>
  <div>
    <p v-text="label" class="tw-text-sm tw-text-gray"></p>
    <div v-if="type === 'select'">
      <v-select
        v-bind="$attrs"
        v-model="innerValue"
        :items="items"
        :placeholder="placeHolder"
        outlined
      />
    </div>
    <div v-else-if="type === 'textbox'">
      <v-text-field
        v-bind="$attrs"
        v-model="innerValue"
        :placeholder="placeHolder"
        outlined
      />
    </div>
    <div v-else-if="type === 'combobox'">
      <v-combobox
        v-bind="$attrs"
        v-model="innerValue"
        :items="items"
        :placeholder="placeHolder"
        outlined
      />
    </div>
    <v-menu
      v-else-if="type === 'datepicker'"
      v-model="isDatePickerOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="auto"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="placeHolder"
          outlined
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @input="isDatePickerOpen = false"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Array, Object, Number],
      default: "",
    },
    type: {
      type: String,
      default: "textbox",
      required: false,
      validator: (v) =>
        [
          "textbox",
          "textarea",
          "select",
          "checkbox",
          "switch",
          "radio",
          "file",
          "price",
          "multiselect",
          "date",
          "datetime",
          "time",
          "slider",
          "range",
          "combobox",
          "autocomplete",
          "chips",
          "phoneNumber",
          "textboxIcon",
        ].includes(v),
    },
    items: {
      type: [String, Object, Array, Number],
      default: () => [],
      require: true,
    },
  },
  data() {
    return {
      isDatePickerOpen: false,
      date: null,
      computedDateFormatted: null,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    date(val) {
      this.computedDateFormatted = val;
      this.innerValue = val;
    },
    value: {
      handler() {
        if (this.type === "datepicker") {
          this.date = this.value;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  p {
    padding: 0 !important;
  }
}
</style>
