<template>
  <v-card class="my-8 md:my-5">
    <div
      class="tw-flex tw-flex-row tw-justify-between tw-items-baseline tw-mb-4 tw-p-4 md:tw-p-8"
    >
      <div class="tw-flex tw-flex-col">
        <p class="tw-font-bold tw-text-xl">
          {{ $t("homepage.ProfileUpdateLogs") }}
        </p>
      </div>
    </div>
    <div class="tw-p-0 md:tw-p-8">
      <custom-table
        :columns="columns"
        :data-tables="profileLogs"
        :loading="isLoading"
      />
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomTable from "@/components/global/CustomTable.vue";
export default {
  components: { CustomTable },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      profileLogs: "profile/profileLogs",
    }),
    columns() {
      return [
        {
          text: this.$t("homepage.Name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("homepage.Username"),
          align: "start",
          value: "username",
        },
        {
          text: this.$t("homepage.AccountID"),
          align: "start",
          value: "id",
        },
        {
          text: this.$t("homepage.Date"),
          align: "start",
          value: "date",
        },
        {
          text: this.$t("homepage.Status"),
          align: "start",
          value: "status",
        },
        {
          text: "",
          align: "end",
          value: "more",
        },
      ];
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadProfileLogs();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      loadProfileLogs: "profile/loadProfileLogs",
    }),
  },
};
</script>

<style></style>
