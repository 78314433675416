<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog v-model="dialog" scrollable persistent max-width="700px">
        <v-card>
          <v-divider></v-divider>
          <v-card-text style="padding: 10px 20px">
            <v-carousel
              v-model="currentSlide"
              id="onboarding-carousel"
              height="inherit"
              hide-delimiter-background
              hide-delimiters
              :continuous="false"
              :prev-icon="currentSlide == 0 ? '' : 'mdi-chevron-left'"
              :next-icon="currentSlide == 2 ? '' : 'mdi-chevron-right'"
              @change="currentSlide = $event"
            >
              <v-carousel-item v-for="(slide, i) in slides" :key="i">
                <v-sheet id="onboarding-carousel-item">
                  <div class="tw-px-2 tw-pt-2 md:tw-px-4 md:tw-pt-4">
                    <div class="tw-flex tw-justify-center">
                      <img
                        src="../../assets/icons/credified.svg"
                        alt="credified logo"
                        class=""
                      />
                    </div>
                    <div
                      class="tw-flex tw-flex-col-reverse md:tw-flex-row tw-w-full tw-space-x-2"
                    >
                      <div
                        class="tw-w-full md:tw-w-1/2 tw-py-2 md:tw-py-4 tw-flex tw-flex-col tw-justify-center"
                      >
                        <div class="tw-text-2xl tw-font-bold">
                          {{ i + 1 }} - {{ slide.title }}
                        </div>
                        <div
                          class="tw-text-sm tw-font-normal tw-pt-3 text-justify"
                        >
                          {{ slide.desc }}
                        </div>
                      </div>
                      <img
                        v-if="i == 0"
                        src="../../assets/icons/modal-svg-animation.svg.gif"
                        alt="credified onboading screens animation"
                        class="tw-w-full md:tw-w-1/2"
                      />
                      <img
                        v-else-if="i == 1"
                        src="../../assets/icons/link-credit-reports.png"
                        alt="link-credit-reports"
                        class="tw-w-full md:tw-w-1/2"
                      />
                      <img
                        v-else-if="i == 2"
                        src="../../assets/icons/generate-letter-svg.svg"
                        alt="generate-letter-svg"
                        class="tw-w-full md:tw-w-1/2"
                      />
                    </div>
                  </div>
                </v-sheet>
                <v-card-actions
                  v-if="i == 2"
                  class="tw-px-2 md:tw-px-4 tw-contents"
                >
                  <v-btn
                    class="tw-w-max tw-float-right tw-mx-4 tw-mb-4"
                    color="success"
                    @click="finishHandler"
                  >
                    Finish
                  </v-btn>
                </v-card-actions>
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "OnBoardingScreen",
  data() {
    return {
      dialog: false,
      currentSlide: 0,
      carouselVal: 0,
      slides: [
        {
          title: "What is Credified?",
          desc: "A new and simplified way to easily automate your credit enhancement journey. Credified helps enrich your credit score by building, updating or removing bankruptcy!",
          image:
            "https://uploads-ssl.webflow.com/612ea4814a8e42e6c0a99f5e/61a882ba2e274e7cd4ad1604_Art.svg",
        },
        {
          title: "How to Link your credit report?",
          desc: "The first step in using Credified is to link your credit report. This allows the software to access and analyze the information on your credit report, which is necessary for identifying any errors or inaccuracies that may be affecting your credit score.",
          image:
            "https://uploads-ssl.webflow.com/612ea4814a8e42e6c0a99f5e/61a882ba2e274e7cd4ad1604_Art.svg",
        },
        {
          title: "How to Generate your letters?",
          desc: "Once your credit report is linked, the software can generate ethical and compliant based attack letters to challenge any errors or inaccuracies on your report. These letters are typically sent to the credit bureaus or creditors who provided the information in question, and they request that the errors be corrected.",
          image:
            "https://uploads-ssl.webflow.com/612ea4814a8e42e6c0a99f5e/61a882ba2e274e7cd4ad1604_Art.svg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "dashboard/userInfo",
    }),
  },
  methods: {
    finishHandler() {
      this.currentSlide = 0;
      this.dialog = false;
    },
  },
};
</script>
