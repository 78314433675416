<template>
  <v-navigation-drawer fixed app color="gray" width="90">
    <div class="image-container tw-border-b-2 tw-px-5 tw-py-3.5 tw-mx-auto">
      <img src="../../assets/icons/credified.svg" alt="credified" />
    </div>
    <ul class="mt-1" id="gc-sideMenu">
      <li
        v-for="item in menuItems"
        :id="item.id"
        :key="item.id"
        @click="$router.push(item.route).catch((err) => {})"
      >
        <div
          v-if="typeof item === 'object'"
          class="mb-3 tw-w-full tw-border-r-2 tw-border-transparent tw-cursor-pointer hover:tw-border-primary"
        >
          <div
            class="tw-bg-white tw-rounded-md tw-w-9/12 first-of-type:tw-mt-3 tw-h-12 hover:tw-border-r"
            :class="[
              item.isActive
                ? 'tw-bg-gradient-to-l tw-from-primary-light tw-to-primary tw-bg-primary/90 tw-shadow-lg tw-shadow-primary/50'
                : 'tw-bg-white',
            ]"
          >
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <img
                    v-if="!item.isActive"
                    :src="require(`../../assets/icons/${item.name}.svg`)"
                    class="tw-mx-auto tw-py-3"
                    height="100"
                    :alt="item.name"
                  />
                  <img
                    v-else
                    :src="require(`../../assets/icons/${item.name}Active.svg`)"
                    class="tw-mx-auto tw-py-3"
                    height="100"
                    :alt="item.name"
                  />
                </div>
              </template>
              <span>{{ item.name.replace(/([a-z])([A-Z])/g, "$1 $2") }}</span>
            </v-tooltip>
          </div>
        </div>
      </li>
    </ul>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      hoverdItem: null,
      menuItems: [
        window.VUE_APP_APP_TYPE === "b2b"
          ? {
              id: "gc-dashboard",
              name: "Home",
              route: "/dashboard",
              isActive: false,
            }
          : {
              id: "gc-dashboard",
              name: "Home",
              route: "/home",
              isActive: false,
            },
        // window.VUE_APP_APP_TYPE === "b2b" && {
        //   id: "gc-analytics",
        //   name: "Analytics",
        //   route: "/credit-analysis",
        //   isActive: false,
        // },
        {
          id: "gc-createProfile",
          name: "Profile",
          route: "/create-profile",
          isActive: false,
        },
        {
          id: "gc-attackCenter",
          name: "RepairCenter",
          route: "/repair-center",
          isActive: false,
        },
        {
          id: "gc-settings",
          name: "Settings",
          route: "/settings",
          isActive: false,
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.menuItems.find((r) => {
          if (typeof r === "object") {
            if (this.$route.path.startsWith(r?.route)) {
              r.isActive = true;
            } else if (
              r.route === "/create-profile" &&
              this.$route.path.startsWith("/edit-profile")
            ) {
              r.isActive = true;
            } else {
              r.isActive = false;
            }
          }
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  ul {
    padding-left: 0 !important;
    li {
      margin-left: 1rem;
    }
  }
}
</style>
