<template>
  <div>
    <div v-if="userInfo.generationsLeft?.value < 1" class="tw-w-full tw-h-full">
      <ErrorDialogue :isUserLoading="isUserLoading" />
    </div>
    <div v-else-if="userInfo.generationsLeft?.value >= 1">
      <div
        v-if="isProfileFetching || isAttackCenterLoading"
        class="tw-w-full tw-h-full"
      >
        <Loader />
      </div>
      <div v-else-if="!isProfileFetching || !isAttackCenterLoading">
        <!-- Warning -->
        <v-alert
          v-if="$route.params.id && creditReports.length <= 0"
          color="red"
          text
          class="tw-flex tw-items-center tw-self-center"
          type="error"
          ><div class="tw-flex tw-items-center">
            <span>{{
              $t("attackcenter.ProfileDoesNotHaveAnyCreditReports")
            }}</span
            >&nbsp;
            <router-link
              :to="'/edit-profile/' + $route.params.id"
              class="tw-font-bold underline tw-cursor-pointer"
              style="color: #f44336; text-decoration: underline"
            >
              {{ $t("attackcenter.AddCreditReport") }}
            </router-link>
          </div>
        </v-alert>
        <!-- Warning -->
        <div class="tw-mb-3">
          <div
            class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-space-x-4"
          >
            <div id="gc-selectClient" class="tw-w-full md:tw-w-1/2">
              <p class="tw-text-sm tw-text-gray">
                {{
                  isB2B === "b2b"
                    ? $t("attackcenter.SelectClient")
                    : $t("attackcenter.SelectProfile")
                }}
              </p>
              <v-select
                v-model="$route.params.id"
                outlined
                :placeholder="SelectPlaceholder"
                type="select"
                class="tw-w-full"
                :items="profilesData?.results"
                item-text="name"
                item-value="id"
                v-on:change="selectHandler"
                :loading="isLoading"
              >
                <template slot="item" slot-scope="data">
                  <!-- Divider and Header-->
                  <template v-if="data.item?.header">
                    <!-- <v-list-tile v-html="data.item.header" /> -->
                    section
                  </template>
                  <!-- Normal item -->
                  <template v-else>
                    <div>
                      <div>{{ data.item.name }}</div>
                      <!-- <div-sub-title v-html="data.item.id" /> -->
                    </div>
                  </template>
                </template>
              </v-select>
            </div>
            <form-item
              id="gc-selectCredit"
              v-model="creditReportId"
              type="select"
              class="tw-w-full md:tw-w-1/2"
              :label="SelectReportLabel"
              :items="creditReports"
              item-text="name"
              item-value="id"
              :no-data-text="NoDataText"
              :loading="isLoading"
              :place-holder="SelectPlaceholder"
            />
          </div>
          <div
            class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-space-y-5 md:tw-space-y-0 md:tw-space-x-12"
          >
            <div class="tw-w-full md:tw-w-2/3">
              <v-btn-toggle
                v-model="toggleExclusive"
                class="tw-w-full"
                active-class="primary"
                rounded
              >
                <v-btn class="tw-w-1/2 tw-text-lg">
                  <div class="tw-pr-1">
                    <img
                      v-if="toggleExclusive !== 0"
                      src="../assets/icons/AutomaticGenerations.svg"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/icons/AutomaticGenerationsActive.svg"
                      alt=""
                    />
                  </div>
                  {{ $t("attackcenter.Automatic") }}
                </v-btn>
                <v-btn class="tw-w-1/2 tw-text-lg">
                  <div class="tw-pr-1">
                    <img
                      v-if="toggleExclusive !== 1"
                      src="../assets/icons/ManualGenerations.svg"
                      alt=""
                    />
                    <img
                      v-else
                      src="../assets/icons/ManualGenerationsActive.svg"
                      alt=""
                    />
                  </div>
                  {{ $t("attackcenter.Manual") }}
                </v-btn>
              </v-btn-toggle>
            </div>
            <div
              class="tw-w-full md:tw-w-1/3 tw-flex tw-justify-end"
              :class="[
                $route.params.id && creditReports.length <= 0
                  ? 'tw-blur-sm tw-pointer-events-none'
                  : 'tw-blur-none',
              ]"
            >
              <v-btn
                class="tw-w-full md:tw-w-2/3"
                large
                color="primary"
                :disabled="
                  toggleExclusive === 0
                    ? !$route.params.id || !creditReportId
                    : selectedDestinations.some((x) => x) &&
                      selectedAccounts.some((x) => x)
                    ? false
                    : true
                "
                :loading="isGenerationsLoading"
                @click="generateNew"
              >
                Generate
              </v-btn>
            </div>
          </div>
        </div>
        <Manual
          ref="manualSection"
          @selected-destinations="handleDestinations"
          @selected-account="handleAccounts"
          class="tw-transition-all tw-delay-75 tw-ease-in"
          :class="[
            ($route.params.id && creditReports.length <= 0) ||
            toggleExclusive == 0
              ? 'tw-blur-sm tw-pointer-events-none'
              : 'tw-blur-none',
          ]"
          :selectedReport="creditReportId"
        />
        <select-options-modal ref="autoGenerate" :reportId="creditReportId" />
      </div>
    </div>
    <isFileDownloading />
    <Snackbar
      variant="primary"
      timeout="10000"
      :text="snackbarText"
      ref="snackbar"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormItem from "@/components/global/FormItem.vue";
import Manual from "@/components/pages/attackCenter/manual.vue";
import SelectOptionsModal from "@/components/pages/attackCenter/selectOptionsModal.vue";
import Loader from "@/components/global/Loader.vue";
import ErrorDialogue from "@/components/global/ErrorDialogue.vue";
import isFileDownloading from "@/components/pages/attackCenter/fileDownloadingDialog.vue";
import Snackbar from "@/components/global/Snackbar.vue";
import moment from "moment";

export default {
  components: {
    FormItem,
    Manual,
    SelectOptionsModal,
    Loader,
    ErrorDialogue,
    isFileDownloading,
    Snackbar,
  },
  data() {
    return {
      toggleExclusive: 0,
      isIdAvailaible: null,
      snackbarText: "",
      columns: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          value: "mail",
        },
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        {
          text: "Analysis Type",
          align: "start",
          value: "type",
        },
        {
          text: "",
          align: "end",
          value: "more",
        },
      ],
      isLoading: false,
      profileId: null,
      creditReportId: null,
      reportsId: null,
      creditId: null,
      destionations: null,
      isGenerationsLoading: false,
      profilesData: { results: [] },
      recentProfiles: [],
      selectedDestinations: [],
      selectedAccounts: [],
      disableGeneration: true,
    };
  },

  computed: {
    ...mapGetters({
      userInfo: "dashboard/userInfo",
      isUserLoading: "dashboard/isLoading",
      creditReports: "attackCenter/creditReports",
      isAttackCenterLoading: "attackCenter/isLoading",
      isFileDownloading: "attackCenter/isFileDownloading",
      generationsLog: "profile/generationsLog",
      // reports: "credit/reports",
      isProfileFetching: "profile/isLoading",
      profiles: "profile/profiles",
      profileInfo: "profile/profileInfo",
    }),
    SelectReportLabel() {
      return window.VUE_APP_APP_TYPE === "b2b"
        ? this.$t("attackcenter.SelectClientReport")
        : this.$t("attackcenter.SelectProfileReport");
    },
    SelectPlaceholder() {
      return this.$t("attackcenter.Select");
    },
    NoDataText() {
      return this.$t("homepage.NoDataAvailable");
    },
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
    disableGeneration() {
      if (this.toggleExclusive === 1) {
        if (
          this.selectedDestinations.some((x) => x) &&
          this.selectedAccounts.some((x) => x)
        ) {
          this.disableGeneration = false;
        } else {
          this.disableGeneration = true;
        }
      }
    },
  },
  async mounted() {
    await this.loadUserInfo();
    await this.loadGenerationsLog();
    this.generationsLogFunc();
  },
  methods: {
    ...mapActions({
      loadSingleCreditReport: "attackCenter/loadSingleCreditReport",
      loadAutomatedGenerations: "attackCenter/loadAutomatedGenerations",
      loadGenerationsLog: "profile/loadGenerationsLog",
      loadProfiles: "profile/loadProfiles",
      loadUserInfo: "dashboard/loadUserInfo",
      manualGenerations: "attackCenter/manualGenerations",
    }),
    async fetchData() {
      this.isLoading = true;
      this.creditReportId = null;
      if (this.$route.params.id) {
        let allprofiles = await this.loadProfiles();
        let allCreditReports = await this.loadSingleCreditReport({
          id: this.$route.params.id,
        });

        // logic for recent clients selected

        let retrieveRecents = JSON.parse(
          localStorage.getItem("recentProfiles")
        );
        let selectedProfile = allprofiles.results.find(
          (item) => item.id == this.$route.params.id
        );
        if (retrieveRecents) {
          this.profilesData.results = [
            { header: "Recents" },
            ...retrieveRecents,
            { header: "Clients" },
            ...allprofiles.results,
          ];
        } else {
          this.profilesData.results = [
            { header: "Recents" },
            selectedProfile,
            { header: "Clients" },
            ...allprofiles.results,
          ];
          localStorage.setItem(
            "recentProfiles",
            JSON.stringify([selectedProfile])
          );
        }
        if (allCreditReports.length > 0) {
          this.creditReportId = allCreditReports[0].id;
        }
        this.isIdAvailaible = this.$route.params.id;
      } else {
        let allprofiles = await this.loadProfiles();

        // logic for recent clients selected

        let retrieveRecents = JSON.parse(
          localStorage.getItem("recentProfiles")
        );

        if (retrieveRecents) {
          this.profilesData.results = [
            { header: "Recents" },
            ...retrieveRecents,
            { header: "Clients" },
            ...allprofiles.results,
          ];
        } else {
          this.profilesData.results = allprofiles.results;
        }
      }
      this.isLoading = false;
    },
    async generateNew() {
      this.isGenerationsLoading = true;
      this.$refs.autoGenerate.generationError = false;
      if (this.toggleExclusive === 0) {
        await this.loadAutomatedGenerations({
          profileId: this.$route.params.id,
          creditReportId: this.creditReportId,
          reportId: this.reportsId,
        });
        this.$refs.autoGenerate.isOpen = true;
      } else if (this.toggleExclusive === 1) {
        let res = await this.manualGenerations({
          reportId: this.creditReportId,
          profileId: this.$route.params.id,
          destinations: this.selectedDestinations,
          selectedAccounts: this.selectedAccounts,
        });

        this.$refs.autoGenerate.sessionId = res.sessionId;
        this.$refs.autoGenerate.$refs.dialog.isActive = true;
      }
      this.isGenerationsLoading = false;
    },
    async selectHandler(e) {
      console.log("e", e);
      console.log("this.profilesData", this.profilesData);
      let selected = this.profilesData?.results.find((item) =>
        item ? item.id == e : item
      );
      console.log("selected", selected);
      let recentProfiles = JSON.parse(localStorage.getItem("recentProfiles"));
      if (recentProfiles) {
        if (recentProfiles.length > 2) {
          recentProfiles.pop();
          localStorage.setItem(
            "recentProfiles",
            JSON.stringify([selected, ...recentProfiles])
          );
        } else {
          localStorage.setItem(
            "recentProfiles",
            JSON.stringify([selected, ...recentProfiles])
          );
        }
      } else {
        localStorage.setItem("recentProfiles", JSON.stringify([selected]));
      }
      this.$router.push({ path: `/repair-center/${e}` });
    },
    handleDestinations(vals) {
      this.selectedDestinations = vals;
    },
    handleAccounts(vals) {
      this.selectedAccounts = vals;
    },
    isB2B() {
      window.VUE_APP_APP_TYPE = process.env.VUE_APP_APP_TYPE;
      if (location.origin.includes("https://business.credified.com")) {
        window.VUE_APP_APP_TYPE = "b2c";
      }
      return window.VUE_APP_APP_TYPE;
    },
    generationsLogFunc() {
      let lastGeneratedDate = this.findRecentGenerated(this.generationsLog);
      const date = moment(lastGeneratedDate).format("MM/DD/YYYY");
      const today = moment();
      const diffInDays = today.diff(date, "days");
      if (diffInDays > 1) {
        this.snackbarText = `Its been ${diffInDays} days since your last Letter Generation!`;
        this.$refs.snackbar.snackbar = true;
      } else {
        this.snackbarText = `Remember to generate letters every 30 days, and check your Credit Score`;
        this.$refs.snackbar.snackbar = true;
      }
    },
    findRecentGenerated(array) {
      if (array && array.length > 0) {
        let maxDate = null;
        let maxDateObj = null;

        for (let obj of array) {
          let date = new Date(obj.date);
          if (!maxDate || date > maxDate) {
            maxDate = date;
            maxDateObj = obj;
          }
        }
        return maxDateObj.date;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
  }
  .v-btn--active {
    .v-btn__content {
      color: white !important;
    }
  }
  .v-btn--has-bg {
    background: #ffffff !important;
  }

  .v-subheader {
    color: black;
    font-weight: 700;
  }
}
</style>
