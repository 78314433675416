<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      top
      right
      :style="{ top: '80px' }"
      elevation="24"
      :color="variant == 'error' ? 'red accent-2' : 'blue accent-2'"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon style="transform: rotate(45deg)">mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
      default: null,
    },
    variant: {
      type: String,
      required: true,
      default: "",
    },
    timeout: {
      type: String,
      required: true,
      default: "6000",
    },
  },
  data: () => ({
    snackbar: false,
    topPosition: "100px",
  }),
  //   watch: {
  //     $route: {
  //       handler: "watchRoute",
  //       immediate: true,
  //     },
  //   },
  methods: {},
};
</script>
