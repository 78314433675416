<template lang="">
  <div class="text-center">
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="text-h5 tw-text-center tw-block lighten-2">
          Credified Tutorial
        </v-card-title>

        <div class="tw-px-12 tw-py-4 tw-text-center tw-block lighten-2">
          <div
            style="
              position: relative;
              padding-bottom: 52.708333333333336%;
              height: 0;
            "
          >
            <iframe
              src="https://www.loom.com/embed/ee2382122c854b9cbbdefa6a1c949a70"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            />
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style lang=""></style>
