<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="columns"
      :items-per-page="1000000"
      :items="dataTables"
      hide-default-footer
      item-key="id"
      height="480px"
      disable-sort
      striped
      :no-data-text="DataText"
      v-bind="$attrs"
      :show-select="isSelectable"
      class="custom_table_class overflow-y-auto"
    >
      <template #[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox color="primary" v-bind="props" v-on="on" />
      </template>
      <template #[`item.data-table-select`]="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>
      <template #[`item.name`]="{ item }">
        <span>{{ item.name }}</span>
      </template>
      <template #[`item.date`]="{ item }">
        <span>{{ moment(item.date || fullDate) }}</span>
      </template>
      <template #[`item.status`]="{ item }">
        <span
          :class="[
            item.status == 'Onboarding'
              ? 'tw-text-[#0c48af]'
              : item.status == 'Waiting'
              ? 'tw-text-[#ff9700]'
              : item.status == 'Needs Review'
              ? 'tw-text-[#ff0000]'
              : 'tw-text-[#26b400]',
          ]"
          >{{ item.status }}</span
        >
      </template>
      <template #[`item.more`]="{ item }">
        <div class="tw-flex tw-flex-row tw-items-center tw-float-right">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <img
                  :src="require(`../../assets/icons/RepairCenterBlue.svg`)"
                  class="tw-mx-auto tw-py-3 tw-text-[#0c48af] tw-pr-3"
                  height="100"
                  alt="repair center"
                  @click="attackCenter(item)"
                />
              </div>
            </template>
            <span>Repair Center</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <i
                  class="fa-solid fa-download tw-text-xl tw-text-[#0c48af] tw-pr-3 tw-cursor-pointer"
                ></i>
              </div>
            </template>
            <span>Download</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span
                  v-if="hasEdit"
                  class="tw-hidden md:tw-block icon-Edit tw-text-xl tw-pr-3 tw-cursor-pointer"
                  @click="edit(item)"
                />
              </div>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span
                  v-if="hasDelete"
                  class="tw-hidden md:tw-block icon-Delete tw-text-xl tw-cursor-pointer"
                  @click="remove(item)"
                />
              </div>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                class="tw-block md:tw-hidden"
              >
                <v-icon color="black"> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item>
                <v-list-item-title @click="remove(item)">
                  <v-btn large depressed class="tw-w-full" color="transparent">
                    Delete
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn large depressed class="tw-w-full" color="transparent">
                    Edit
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="isDeleteModalOpen" max-width="550">
      <v-card class="tw-p-3">
        <p class="tw-font-extrabold tw-text-lg tw-pb-5">
          Are you sure you want to delete
          {{ selected.length > 1 ? "these records?" : "this record?" }}
        </p>
        <div class="tw-flex tw-flex-row tw-justify-between tw-mt-3 tw-pt-5">
          <div class="tw-pr-3 tw-w-1/2">
            <v-btn
              class="tw-w-full"
              color="primary"
              outlined
              @click="isDeleteModalOpen = false"
            >
              No
            </v-btn>
          </div>
          <div class="tw-pl-3 tw-w-1/2">
            <v-btn
              class="tw-w-full"
              color="primary"
              @click="
                selected.length > 1
                  ? $emit('remove-multiple', selected)
                  : $emit('remove-record', item),
                  (isDeleteModalOpen = false)
              "
            >
              Yes
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    dataTables: {
      type: Array,
      default: () => [],
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    hasDelete: {
      type: Boolean,
      default: false,
    },
    hasEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDeleteModalOpen: false,
      item: {},
      selected: [],
    };
  },
  computed: {
    DataText() {
      return this.$t("homepage.NoDataAvailable");
    },
  },
  methods: {
    remove(item) {
      this.isDeleteModalOpen = true;
      this.item = item;
    },
    edit(item) {
      this.$emit("edit", item);
    },
    attackCenter(item) {
      this.$emit("attack-center", item);
    },
    moment(date) {
      return moment(date).format("MM/DD/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom_table_class {
    border: 1px solid #9ca5b362;
    border-radius: 8px !important;
    tbody {
      tr:nth-of-type(even) {
        background-color: rgba(156, 165, 179, 0.07);
      }
      td {
        font-weight: bold;
      }
      tr:nth-of-type(odd) {
        background-color: rgba(156, 165, 179, 0.03);
      }
      tr.v-data-table__selected:nth-child(odd) {
        background: rgba(12, 72, 175, 0.12) !important;
      }
      tr.v-data-table__selected:nth-child(even) {
        background: rgba(12, 72, 175, 0.07) !important;
      }
    }
  }
}
</style>
