import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      analysisHistory: [],
      analysis: [],
      reports: [],
    };
  },
  getters: {
    analysisHistory(state) {
      return state.analysisHistory;
    },
    analysis(state) {
      return state.analysis;
    },
    reports(state) {
      return state.reports;
    },
  },
  mutations: {
    SET_ANALYSIS_HISTORY(state, payload) {
      state.analysisHistory = payload;
    },
    SET_ANALYSIS(state, payload) {
      state.analysis = payload;
    },
    SET_REPORTS(state, payload) {
      state.reports = payload;
    },
  },
  actions: {
    // async loadProfileLogs(context) {
    //   try {
    //     const response = await fetch("/api/v1/analysisHistory", {
    //       method: "GET",
    //     });
    //     const responseData = await response.json();
    //     context.commit("SET_ANALYSIS_HISTORY", responseData);
    //     return responseData;
    //   } catch (error) {
    //     return console.warn(error);
    //   }
    // },
    async loadAnalysis(context) {
      try {
        const response = await axios.get("/api/v1/analysis");
        context.commit("SET_ANALYSIS", response.data);
        return response.data;
        // const response = await fetch("/api/v1/analysis", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_ANALYSIS", responseData);
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async filterAnalysisHistory(context, payload) {
      try {
        const response = await axios.get(
          `/api/v1/analysisHistory?analysis=${payload.analysisId}&Client=${payload.clientId}&report=${payload.reportId}`
        );
        context.commit("SET_ANALYSIS_HISTORY", response.data);
        return response.data;
        // const response = await fetch(`/api/v1/analysisHistory?analysis=${payload.analysisId}&Client=${payload.clientId}&report=${payload.reportId}`, {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_ANALYSIS_HISTORY", responseData);
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
