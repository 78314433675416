<template>
  <div class="tw-flex tw-flex-col tw-space-y-8">
    <div
      class="tw-w-full tw-flex tw-items-center tw-flex-col md:tw-flex-row tw-justify-between tw-pb-8 tw-border-b"
    >
      <div>
        <p class="tw-font-bold tw-text-xl">Generations</p>
        <span class="tw-text-sm tw-text-gray-500"> </span>
      </div>
      <v-card class="tw-w-full md:tw-w-2/3 tw-p-3">
        <div
          class="tw-flex tw-flex-col md:tw-flex-row tw-space-y-3 md:tw-space-y-0 tw-items-center tw-justify-between"
        >
          <div class="tw-flex tw-flex-row tw-items-center">
            <v-progress-circular
              :rotate="270"
              :size="65"
              :width="5"
              :value="generations.generationsLeft.value"
              color="primary"
            >
              <span class="tw-text-black tw-font-extrabold tw-text-xl">
                {{ generations.generationsLeft.value }}
              </span>
            </v-progress-circular>
            <div class="tw-px-3 tw-flex tw-flex-col">
              <span class="tw-text-lg tw-font-extrabold">
                Generations Left
              </span>
            </div>
          </div>
          <v-divider class="mx-2" vertical light />
          <div class="tw-flex tw-flex-row tw-items-center">
            <v-progress-circular
              :rotate="270"
              :size="65"
              :width="5"
              :value="generations.generationsUsed.value"
              color="error"
            >
              <span class="tw-text-black tw-font-extrabold tw-text-xl">
                {{ generations.generationsUsed.value }}
              </span>
            </v-progress-circular>
            <div class="tw-px-3 tw-flex tw-flex-col">
              <span class="tw-text-lg tw-font-extrabold">
                Generations Used
              </span>
            </div>
          </div>
          <v-divider class="mx-2" vertical light />
          <div id="gc-purchaseGens" class="tw-flex tw-flex-col">
            <div class="tw-w-full" id="settings-num-gen">
              <p class="tw-text-sm tw-text-gray">Number of Generations</p>
              <v-select
                v-model="numberOfGenerations"
                outlined
                placeholder="Select a number"
                type="select"
                class="tw-w-full"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                item-text="Amount"
                item-value="id"
              />
            </div>
            <div class="tw-w-full">
              <v-btn
                class="tw-w-full"
                color="success"
                large
                @click="purchaseGenerations()"
              >
                <v-icon> mdi-cart </v-icon>
                Purchase Generations ${{
                  numberOfGenerations * generationPrice
                }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div id="gc-companyInfo" class="tw-mt-0">
      <div
        v-if="isB2B"
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-start tw-pb-3 tw-border-b tw-pb-8"
      >
        <div class="tw-w-full md:tw-w-1/3">
          <p class="tw-font-bold tw-text-xl">Company Logo</p>
          <span class="tw-text-sm tw-text-gray-500"> </span>
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <input
            ref="uploader"
            type="file"
            class="tw-hidden"
            accept="image/jpeg,image/png"
            @change="createFormData"
          />
          <div
            class="tw-rounded-xl tw-p-3 tw-py-5 tw-w-64 tw-border-2 tw-border-dashed tw-border-gray-300 tw-bg-light-blue tw-cursor-pointer"
            @click="$refs.uploader.click()"
          >
            <img
              src="../assets/icons/companyLogoIcon.svg"
              alt=""
              class="tw-mx-auto tw-h-12 tw-my-5"
            />
            <div class="tw-w-full tw-flex tw-justify-center">
              <span class="tw-mx-auto tw-text-center tw-text-sm">
                Drag .png or .jpg file here
              </span>
            </div>
          </div>
          <div v-if="file" class="md:tw-ml-5">
            <v-img
              class="tw-rounded-lg"
              :src="file"
              alt="file"
              height="160"
              width="160"
              max-height="160"
              max-width="160"
            />
          </div>
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-start tw-border-b tw-pt-6"
      >
        <div class="tw-w-full md:tw-w-1/3">
          <p class="tw-font-bold tw-text-xl">User Info</p>
          <span class="tw-text-sm tw-text-gray-500"> </span>
        </div>
        <div
          class="tw-w-full md:tw-w-2/3 tw-flex md:tw-space-x-5 tw-flex-col md:tw-flex-row tw-justify-between"
        >
          <form-item
            v-model="user.fullName"
            label="Full Name"
            class="tw-w-full md:tw-w-1/2"
          />
          <form-item
            v-model="user.mail"
            label="E-Mail Address"
            class="tw-w-full md:tw-w-1/2"
          />
        </div>
      </div>
      <div
        v-if="isB2B"
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-start tw-border-b tw-pt-8"
      >
        <div class="tw-w-full md:tw-w-1/3">
          <p class="tw-font-bold tw-text-xl">Company Name</p>
          <span class="tw-text-sm tw-text-gray-500"> </span>
        </div>
        <div
          class="tw-w-full md:tw-w-2/3 tw-flex md:tw-space-x-5 tw-flex-col md:tw-flex-row tw-justify-between"
        >
          <form-item
            v-model="company.name"
            label="Company Name"
            class="tw-w-full"
          />
        </div>
      </div>
      <div
        v-if="isB2B"
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-start tw-border-b tw-pt-8"
      >
        <div class="tw-w-full md:tw-w-1/3">
          <p class="tw-font-bold tw-text-xl">Company Website</p>
          <span class="tw-text-sm tw-text-gray-500"> </span>
        </div>
        <div
          class="tw-w-full md:tw-w-2/3 tw-flex md:tw-space-x-5 tw-flex-col md:tw-flex-row tw-justify-between"
        >
          <form-item
            v-model="company.websiteUrl"
            label="Company Website"
            class="tw-w-full"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-start tw-border-b tw-pt-8"
      >
        <div class="tw-w-full md:tw-w-1/3">
          <p class="tw-font-bold tw-text-xl">Referal Link</p>
          <span class="tw-text-sm tw-text-gray-500"> </span>
        </div>
        <div
          class="tw-w-full md:tw-w-2/3 tw-flex md:tw-space-x-5 tw-flex-col md:tw-flex-row tw-justify-between"
        >
          <form-item
            v-model="company.websiteUrl"
            label="Referal Link"
            placeHolder="https://www.credified.com/referral?id=12345&utm_source=referrer&utm_medium=share&utm_campaign=referral_program"
            class="tw-w-full"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="tw-flex tw-flex-row tw-justify-center">
        <v-btn
          class="tw-w-full"
          large
          color="success"
          :loading="formLoading"
          @click.prevent="save"
        >
          <v-icon class="tw-px-3"> mdi-check-circle-outline </v-icon>
          {{ $t("settings.Save") }}
        </v-btn>
      </div>
    </div>
    <v-divider class="tw-mt-3" />
    <!-- <employees-list />
    <affiliates-list /> -->
    <div class="hidden" style="display: none">
      <form
        id="payment_confirmation"
        action="https://secureacceptance.cybersource.com/pay"
        method="post"
      >
        <input
          v-for="(value, name) in paymentData"
          v-bind:key="name"
          :id="name"
          :name="name"
          :value="value"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormItem from "@/components/global/FormItem.vue";
// import EmployeesList from "@/components/pages/settings/EmployeesList.vue";
// import AffiliatesList from "@/components/pages/settings/AffiliatesList.vue";
export default {
  components: { FormItem },
  data() {
    return {
      numberOfGenerations: 1,
      generationPrice: window.VUE_APP_APP_TYPE === "b2b" ? 29 : 49,
      columns: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Type",
          align: "start",
          value: "type",
        },
        {
          text: "E-mail",
          align: "start",
          value: "mail",
        },
        {
          text: "Last Active",
          align: "start",
          value: "l_active",
        },
        {
          text: "",
          align: "end",
          value: "more",
        },
      ],
      data: [
        {
          name: "test",
          type: "test",
          mail: "test",
          l_active: "test",
          more: "test",
        },
      ],
      user: {},
      company: {},
      file: null,
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      generations: "setting/generations",
      paymentData: "setting/paymentData",
    }),
    isB2B() {
      return window.VUE_APP_APP_TYPE === "b2b";
    },
  },
  mounted() {
    this.loadGenerations();
  },
  methods: {
    ...mapActions({
      loadGenerations: "setting/loadGenerations",
      createPayment: "setting/createPayment",
      uploadDocs: "cdn/uploadDocs",
      saveSettings: "setting/saveSettings",
    }),
    async purchaseGenerations() {
      await this.createPayment(this.numberOfGenerations);
      window.document.getElementById("payment_confirmation").submit();
    },
    createFormData() {
      let files = this.$refs.uploader.files;
      let formData = new FormData();
      formData.append("document", files[0]);
      this.uploadDocs(formData).then((res) => {
        this.file = res.result;
      });
    },
    save() {
      const payload = {
        fullName: this.user.fullName,
        mail: this.user.mail,
        companyName: this.company.name,
        companyUrl: this.company.websiteUrl,
        companyLogoUrl: this.file,
      };
      this.formLoading = true;
      this.saveSettings(payload);
      this.formLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    background-color: #edf3f8 !important;
    border-color: #dfe7ec !important;
  }
}
</style>
