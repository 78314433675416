import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      affiliates: [],
    };
  },
  getters: {
    affiliates(state) {
      return state.affiliates;
    },
  },
  mutations: {
    SET_AFFILIATES(state, payload) {
      state.affiliates = payload;
    },
  },
  actions: {
    async loadAffiliates(context) {
      try {
        const response = await axios.get("/api/v1/affiliates");
        context.commit("SET_AFFILIATES", response.data);
        return response.data;
        // const response = await fetch("/api/v1/affiliates", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
