import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      clients: [],
    };
  },
  getters: {
    clients(state) {
      return state.clients;
    },
  },
  mutations: {
    SET_CLIENTS(state, payload) {
      state.clients = payload;
    },
  },
  actions: {
    async loadClients(context) {
      try {
        const response = await axios.get("/api/v1/clients");
        context.commit("SET_CLIENTS", response.data);
        return response.data;
        // const response = await fetch("/api/v1/clients", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_CLIENTS", responseData);
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
    async removeClientById(context, payload) {
      try {
        const response = await axios.delete(`/api/v1/clients/${payload}`);
        return response.data;
        // const response = fetch(`/api/v1/clients/${payload}`, {
        //   method: "DELETE",
        // });
        // const responseData = await response.json();
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
