<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <div v-if="isLoading" class="tw-w-full tw-h-full">
      <Loader />
    </div>
    <!-- Alert -->
    <div v-else-if="profile.error">
      <v-alert
        color="red"
        text
        class="tw-flex tw-items-center tw-self-center"
        type="error"
        ><div class="tw-flex tw-items-center">
          <span v-if="profile.error">An unknown error occurred!</span>
          <span v-else-if="typeof profile.error === 'string'">{{
            profile.error
          }}</span>
          <span
            class="tw-font-bold underline tw-cursor-pointer"
            style="color: #f44336; text-decoration: underline"
            @click="
              () => {
                this.$router.go();
              }
            "
          >
            Try Again
          </span>
        </div>
      </v-alert>
    </div>
    <div
      v-if="!isLoading"
      class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-space-x-4"
    >
      <div class="tw-w-full md:tw-w-1/2">
        <!-- encryption msg -->
        <v-alert
          color="success"
          text
          class="tw-flex tw-items-center tw-self-center"
          type="success"
          ><div class="tw-flex tw-items-center">
            <span>Your information is securely encrypted by Credified</span
            >&nbsp;
          </div>
        </v-alert>
        <!-- encryption msg -->
        <v-card class="tw-p-3">
          <div
            class="tw-flex tw-items-center tw-justify-between tw-align-center"
          >
            <p class="tw-text-md md:tw-text-xl tw-font-bold">
              {{ $t("profile.PersonalInformation") }}
            </p>
            <text-with-badge :status="profile.status" />
          </div>
          <v-divider class="tw-my-5" />
          <v-form>
            <div class="tw-flex tw-flex-row tw-flex-wrap">
              <form-item
                v-model="profile.profileName"
                :label="CredifiedProfileNameLabel"
                type="textbox"
                maxlength="25"
                class="tw-w-full capitalize"
              />
              <form-item
                v-model="profile.firstName"
                :label="FirstNameLabel"
                type="textbox"
                class="tw-w-full md:tw-w-1/2 md:tw-pr-3 capitalize"
              />
              <form-item
                v-model="profile.lastName"
                :label="LastNameLabel"
                type="textbox"
                class="tw-w-full md:tw-w-1/2 md:tw-pl-3 capitalize"
              />
              <!-- phone number -->
              <div class="tw-w-full md:tw-w-1/2 md:tw-pr-3">
                <p class="tw-text-sm tw-text-gray">{{ $t("profile.Phone") }}</p>
                <div>
                  <v-text-field
                    v-model="profile.phone"
                    v-mask="'+1 (###) ### ####'"
                    :append-icon="
                      AppType === 'b2b'
                        ? profile?.phone?.length > 1
                          ? 'mdi-phone'
                          : ''
                        : ''
                    "
                    outlined
                    @click:append="() => callHandler(profile.phone)"
                  ></v-text-field>
                </div>
              </div>
              <!-- phone number -->
              <div class="tw-w-full md:tw-w-1/2 md:tw-pl-3">
                <p class="tw-text-sm tw-text-gray">
                  {{ $t("profile.Email") }}
                </p>
                <div>
                  <v-text-field
                    v-model="profile.mail"
                    outlined
                    :rules="emailRules"
                    class="tw-w-full md:tw-pl-3"
                  ></v-text-field>
                </div>
              </div>
              <div class="tw-w-full md:tw-w-1/2 md:tw-pr-3">
                <p class="tw-text-sm tw-text-gray">Social Security Number</p>
                <div>
                  <v-text-field
                    v-model="maskedSsn"
                    v-mask="'###-##-####'"
                    :append-icon="visibilityIconSSN"
                    type="textbox"
                    outlined
                    @click:append="toggleVisibilitySSN"
                  />
                </div>
              </div>
              <!-- Date picker -->
              <div class="tw-w-full md:tw-w-1/2 md:tw-pl-3">
                <p class="tw-text-sm tw-text-gray">Date of Birth</p>
                <div class="custom-date-picker">
                  <v-text-field
                    ref="dobInput"
                    v-model="maskedDob"
                    outlined
                    type="date"
                    :max="maxDate"
                    :rules="dobRules"
                    placeholder="MM/DD/YYYY"
                    :append-icon="visibilityIconDOB"
                    pattern="^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)[0-9]{2}$"
                    @click:append="toggleVisibilityDOB"
                  ></v-text-field>
                </div>
              </div>
              <!-- Date picker -->
              <div class="tw-w-full md:tw-w-1/2 md:tw-pr-3">
                <p class="tw-text-sm tw-text-gray">Street Address</p>
                <div>
                  <v-text-field
                    v-model="maskedStreet"
                    outlined
                    :append-icon="visibilityIconStreet"
                    type="textbox"
                    class="capitalize"
                    @click:append="toggleVisibilityStreet"
                  ></v-text-field>
                </div>
              </div>
              <form-item
                v-model="profile.city"
                :label="CityLabel"
                type="textbox"
                class="tw-w-full md:tw-w-1/2 md:tw-pl-3 capitalize"
              />
              <form-item
                v-model="profile.state.name"
                :items="states"
                :label="StateLabel"
                class="tw-w-full md:tw-w-1/2 md:tw-pr-3"
                type="select"
                item-text="name"
                item-value="id"
                place-holder="Select"
              />
              <form-item
                v-model="profile.postalCode"
                :label="ZipLabel"
                type="textbox"
                maxlength="7"
                class="tw-w-full md:tw-w-1/2 md:tw-pl-3"
              />
              <div class="tw-w-full md:tw-pr-3" v-if="onboardingKey">
                <p class="tw-text-sm tw-text-gray">Client Onboarding Link</p>
                <div>
                  <v-text-field
                    v-model="onboardingKey"
                    v-on:focus="$event.target.select()"
                    outlined
                    append-icon="mdi-note-multiple-outline"
                    type="textbox"
                    readonly
                    @click:append="copyOnboardingKeyHandler"
                    ref="onboardingKey"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <p class="tw-text-xl tw-font-bold">Proof of Documents</p>
            <v-divider class="tw-my-3" />
            <div
              class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-space-y-5 md:tw-space-x-5 md:tw-space-y-0"
            >
              <div
                class="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col tw-justify-between"
              >
                <div class="tw-flex tw-justify-between tw-mb-1">
                  <p>Social Security Number</p>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="align-items: baseline"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>Social Security Number</span>
                  </v-tooltip>
                </div>
                <input
                  ref="ssnUploader"
                  type="file"
                  class="tw-hidden"
                  accept="image/jpeg,image/png"
                  @change="uploadSsn"
                />
                <div
                  class="tw-rounded-xl tw-p-3 tw-py-5 tw-border-2 tw-cursor-pointer"
                  :class="documentsClass.ssnDocs"
                  @click="$refs.ssnUploader.click()"
                >
                  <img
                    v-if="ssnDocs.error"
                    src="../../assets/icons/ssnIconError.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <img
                    v-if="ssnDocs.isUploaded && !ssnDocs.error"
                    src="../../assets/icons/ssnIconActive.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <img
                    v-if="!ssnDocs.isUploaded && !ssnDocs.error"
                    src="../../assets/icons/ssnIcon.svg"
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <div
                    v-if="!ssnDocs.isUploaded && !ssnDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span class="tw-mx-auto tw-text-center tw-text-sm">
                      Drag .png or .jpg file here
                    </span>
                  </div>
                  <div
                    v-if="ssnDocs.error && !ssnDocs.isUploaded"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span
                      class="tw-mx-auto tw-text-center tw-text-sm tw-text-error"
                    >
                      Error Uploading! File too large
                    </span>
                  </div>
                  <div
                    v-if="ssnDocs.isUploaded && !ssnDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span
                      class="tw-mx-auto tw-text-center tw-text-sm tw-text-success"
                    >
                      File successfully uploaded!
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col tw-justify-between"
              >
                <div class="tw-flex tw-justify-between tw-mb-1">
                  <p>Proof of Address (First Page of Utility/Bank Statement)</p>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="align-items: baseline"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>First Page of Utility/Bank Statement</span>
                  </v-tooltip>
                </div>
                <input
                  ref="poaUploader"
                  type="file"
                  class="tw-hidden"
                  accept="image/jpeg,image/png"
                  @change="uploadPoa"
                />
                <div
                  class="tw-rounded-xl tw-p-3 tw-py-5 tw-border-2 tw-cursor-pointer"
                  :class="documentsClass.poaDocs"
                  @click="$refs.poaUploader.click()"
                >
                  <img
                    v-if="poaDocs.error"
                    src="../../assets/icons/proofofaddressIconError.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <img
                    v-if="poaDocs.isUploaded && !poaDocs.error"
                    src="../../assets/icons/proofofaddressIconActive.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <img
                    v-if="!poaDocs.isUploaded && !poaDocs.error"
                    src="../../assets/icons/proofofaddressIcon.svg"
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <div
                    v-if="!poaDocs.isUploaded && !poaDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span class="tw-mx-auto tw-text-center tw-text-sm">
                      Drag .png or .jpg file here
                    </span>
                  </div>
                  <div
                    v-if="poaDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span
                      class="tw-mx-auto tw-text-center tw-text-sm tw-text-error"
                    >
                      Error Uploading! File too large
                    </span>
                  </div>
                  <div
                    v-if="poaDocs.isUploaded && !poaDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span
                      class="tw-mx-auto tw-text-center tw-text-sm tw-text-success"
                    >
                      File successfully uploaded!
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="tw-w-full md:tw-w-1/3 tw-flex tw-flex-col tw-justify-between"
              >
                <div class="tw-flex tw-justify-between tw-mb-1">
                  <p>ID or Driver’s License</p>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="align-items: baseline"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </template>
                    <span>ID or Driver’s License</span>
                  </v-tooltip>
                </div>
                <input
                  ref="idOrDriverLicense"
                  type="file"
                  class="tw-hidden"
                  accept="image/jpeg,image/png"
                  @change="uploadIdOrDriverLicense"
                />
                <div
                  class="tw-rounded-xl tw-p-3 tw-py-5 tw-border-2 tw-cursor-pointer"
                  :class="documentsClass.idOrLicenseDocs"
                  @click="$refs.idOrDriverLicense.click()"
                >
                  <img
                    v-if="idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error"
                    src="../../assets/icons/idLicenseIconActive.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <img
                    v-if="idOrLicenseDocs.error && !idOrLicenseDocs.isUploaded"
                    src="../../assets/icons/idLicenseIconError.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <img
                    v-if="!idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error"
                    src="../../assets/icons/idLicenseIcon.svg"
                    alt=""
                    class="tw-mx-auto tw-h-12 tw-my-5"
                  />
                  <div
                    v-if="!idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span class="tw-mx-auto tw-text-center tw-text-sm">
                      Drag .png or .jpg file here
                    </span>
                  </div>
                  <div
                    v-if="idOrLicenseDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span
                      class="tw-mx-auto tw-text-center tw-text-sm tw-text-error"
                    >
                      Error Uploading! File too large
                    </span>
                  </div>
                  <div
                    v-if="idOrLicenseDocs.isUploaded && !idOrLicenseDocs.error"
                    class="tw-w-full tw-flex tw-justify-center"
                  >
                    <span
                      class="tw-mx-auto tw-text-center tw-text-sm tw-text-success"
                    >
                      File successfully uploaded!
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <v-btn
              color="success"
              large
              class="tw-w-full tw-mt-5"
              :loading="isIdLoading"
              :disabled="isLoading || dobError"
              @click="edit"
            >
              <v-icon class="tw-px-3"> mdi-check-circle-outline </v-icon>
              Save {{ $t("profile.Profile") }}
            </v-btn>
          </v-form>
        </v-card>
      </div>
      <div class="tw-w-full md:tw-w-1/2 tw-mt-5 md:tw-mt-0">
        <div
          v-if="!profile.credit && !profile.credit.hasCreditReport"
          class="tw-py-4"
        >
          <v-alert
            color="red"
            text
            class="tw-flex tw-items-center tw-self-center"
            type="error"
            ><div class="tw-flex tw-items-center">
              <span>
                Profile does not have any credit reports yet, Add Below
              </span
              >&nbsp;
            </div>
          </v-alert>
        </div>
        <v-expansion-panels
          v-model="activeCreditAccount"
          class="tw-transition-all tw-delay-75 tw-ease-in"
        >
          <v-expansion-panel
            class="tw-mb-3 tw-transition-all tw-delay-75 tw-ease-in"
            :class="[
              profile?.credit?.creditType === 'MyFreeScoreNow' || switchProvider
                ? 'tw-blur-none'
                : 'tw-blur-sm tw-pointer-events-none',
            ]"
          >
            <v-expansion-panel-header expand-icon="mdi-plus" class="tw-gap-2">
              <div class="tw-flex tw-items-center tw-justify-between">
                <div>
                  <img
                    src="../../assets/png/MyFreeScoreNow.svg"
                    alt="IdentityIQ-Registered"
                    class="tw-max-w-[271px] tw-max-h-[50px]"
                  />
                  <p class="tw-my-4 tw-font-bold tw-text-md tw-text-primary">
                    (Recommended)
                  </p>
                  <div class="tw-flex tw-flex-row tw-mt-2">
                    <span
                      class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-1.5 tw-mr-1"
                      :class="[
                        profile.credit?.creditType === 'MyFreeScoreNow'
                          ? 'tw-bg-success tw-border tw-border-success tw-text-success'
                          : 'tw-bg-gray-200 tw-border tw-border-gray-400 tw-text-gray-400',
                      ]"
                    />
                    <div
                      class="tw-text-md"
                      :class="[
                        profile.credit?.creditType === 'MyFreeScoreNow'
                          ? 'tw-text-success'
                          : ' tw-text-gray-500',
                      ]"
                    >
                      {{
                        profile.credit?.creditType === "MyFreeScoreNow"
                          ? "connected"
                          : "not connected"
                      }}
                    </div>
                  </div>
                </div>
                <v-btn
                  v-if="profile.credit?.creditType === 'MyFreeScoreNow'"
                  outlined
                  color="primary"
                  dark
                  @click="refreshMyFreeScoreNow"
                >
                  <i
                    class="v-icon mdi mdi-refresh"
                    :class="
                      MyFreeScoreNowLoading ? 'tw-animate-spin tw-disabled' : ''
                    "
                  ></i>
                  Refresh
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form class="tw-flex tw-flex-row tw-flex-wrap">
                <form-item
                  v-model="myFreeScoreNow.username"
                  :label="UserNameLabel"
                  type="textbox"
                  class="tw-w-1/2 tw-pr-3"
                />
                <form-item
                  v-model="myFreeScoreNow.password"
                  label="Password"
                  type="textbox"
                  class="tw-w-1/2 tw-pl-3"
                />
                <v-btn
                  color="primary"
                  large
                  class="tw-w-full"
                  :disabled="isLoading"
                  :loading="MyFreeScoreNowLoading"
                  @click="myFreeScoreNowLogin"
                >
                  {{
                    profile.credit?.hasCreditReport
                      ? "Update credit report"
                      : "Connect"
                  }}
                </v-btn>
                <span class="tw-text-center tw-mx-auto tw-py-3">
                  Don’t have an account yet?
                  <a
                    href="https://portal.myfreescorenow.com/enroll?AID=Credified&PID=62517"
                    target="_blank"
                    ><b class="tw-text-primary tw-cursor-pointer">Sign Up</b></a
                  >
                </span>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            expand
            class="tw-mb-3 tw-transition-all tw-delay-75 tw-ease-in"
            :class="[
              profile?.credit?.creditType === 'identityIQLogin' ||
              switchProvider
                ? 'tw-blur-none'
                : 'tw-blur-sm tw-pointer-events-none',
            ]"
          >
            <v-expansion-panel-header expand-icon="mdi-plus" class="tw-gap-2">
              <div class="tw-flex tw-items-center tw-justify-between">
                <div>
                  <img
                    src="../../assets/png/IdentityIQ.png"
                    alt="IdentityIQ-Registered"
                  />
                  <div class="tw-flex tw-flex-row tw-mt-2">
                    <span
                      class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-1.5 tw-mr-1"
                      :class="[
                        profile.credit?.creditType === 'IdentityIQ'
                          ? 'tw-bg-success tw-border tw-border-success tw-text-success'
                          : 'tw-bg-gray-200 tw-border tw-border-gray-400 tw-text-gray-400',
                      ]"
                    />
                    <div
                      class="tw-text-md"
                      :class="[
                        profile.credit?.creditType === 'IdentityIQ'
                          ? 'tw-text-success'
                          : ' tw-text-gray-500',
                      ]"
                    >
                      {{
                        profile.credit?.creditType === "IdentityIQ"
                          ? "connected"
                          : "not connected"
                      }}
                    </div>
                  </div>
                </div>
                <btn
                  @click="refreshIdentityIQ"
                  v-if="profile.credit?.creditType === 'IdentityIQ'"
                >
                  <div
                    class="tw-p-3 tw-bg-[#edf3f8] tw-rounded-full tw-border tw-border-blue-200"
                  >
                    <i class="v-icon mdi mdi-refresh"></i>
                  </div>
                </btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form class="tw-flex tw-flex-row tw-flex-wrap">
                <form-item
                  v-model="identityIq.username"
                  :label="UserNameLabel"
                  type="textbox"
                  class="tw-w-1/2 tw-pr-3"
                />
                <form-item
                  v-model="identityIq.password"
                  label="Password"
                  type="textbox"
                  class="tw-w-1/2 tw-pl-3"
                />
                <form-item
                  v-model="identityIq.sqa"
                  label="Secret Question Answer"
                  type="textbox"
                  class="tw-w-full"
                />
                <v-btn
                  color="primary"
                  large
                  class="tw-w-full"
                  :loading="IdentityIQLoading"
                  :disabled="isLoading"
                  @click="identityIQLogin"
                >
                  {{
                    profile.credit?.hasCreditReport
                      ? `${$t("profile.Update")} credit report`
                      : "Connect"
                  }}
                </v-btn>
                <span class="tw-text-center tw-mx-auto tw-py-3">
                  Don’t have an account yet?
                  <a
                    href="https://www.identityiq.com/sc-securemax.aspx?offercode=43128077"
                    target="_blank"
                    ><b class="tw-text-primary tw-cursor-pointer">Sign Up</b></a
                  >
                </span>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            class="tw-mb-3 tw-transition-all tw-delay-75 tw-ease-in"
            :class="[
              profile?.credit?.creditType === 'smartCreditLogin' ||
              switchProvider
                ? 'tw-blur-none'
                : 'tw-blur-sm tw-pointer-events-none',
            ]"
          >
            <v-expansion-panel-header expand-icon="mdi-plus" class="tw-gap-2">
              <div class="tw-flex tw-items-center tw-justify-between">
                <div>
                  <img
                    src="../../assets/png/smartCredit.png"
                    alt="IdentityIQ-Registered"
                  />
                  <div class="tw-flex tw-flex-row tw-mt-2">
                    <span
                      class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-1.5 tw-mr-1"
                      :class="[
                        profile.credit?.creditType === 'SmartCredit'
                          ? 'tw-bg-success tw-border tw-border-success tw-text-success'
                          : 'tw-bg-gray-200 tw-border tw-border-gray-400 tw-text-gray-400',
                      ]"
                    />
                    <div
                      class="tw-text-md"
                      :class="[
                        profile.credit?.creditType === 'SmartCredit'
                          ? 'tw-text-success'
                          : ' tw-text-gray-500',
                      ]"
                    >
                      {{
                        profile.credit?.creditType === "SmartCredit"
                          ? "connected"
                          : "not connected"
                      }}
                    </div>
                  </div>
                </div>
                <btn
                  @click="refreshSmartCredit"
                  v-if="profile.credit?.creditType === 'SmartCredit'"
                >
                  <div
                    class="tw-p-3 tw-bg-[#edf3f8] tw-rounded-full tw-border tw-border-blue-200"
                  >
                    <i class="v-icon mdi mdi-refresh"></i>
                  </div>
                </btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form class="tw-flex tw-flex-row tw-flex-wrap">
                <form-item
                  v-model="smartCredit.username"
                  type="textbox"
                  :label="UserNameLabel"
                  class="tw-w-1/2 tw-pr-3"
                />
                <form-item
                  v-model="smartCredit.password"
                  type="textbox"
                  label="Password"
                  class="tw-w-1/2 tw-pl-3"
                />
                <form-item
                  v-model="smartCredit.sqa"
                  label="Secret Question Answer"
                  type="textbox"
                  class="tw-w-full"
                />
                <v-btn
                  color="primary"
                  large
                  class="tw-w-full"
                  :loading="SmartCreditLoading"
                  :disabled="isLoading"
                  @click="smartCreditLogin"
                >
                  {{
                    profile.credit?.hasCreditReport
                      ? "Update credit report"
                      : "Connect"
                  }}
                </v-btn>
                <span class="tw-text-center tw-mx-auto tw-py-3">
                  Don’t have an account yet?
                  <a href="https://smartcredit.com/" target="_blank"
                    ><b class="tw-text-primary tw-cursor-pointer">Sign Up</b></a
                  >
                </span>
              </v-form>
              <!-- <div v-else>
                <div
                  class="tw-w-2/5 tw-my-6 tw-px-3 tw-py-3 tw-bg-subtitle tw-bg-opacity-10 tw-text-center tw-rounded-lg tw-mx-auto"
                >
                  <p class="tw-text-black tw-font-extrabold tw-text-xl tw-mb-2">
                    {{ profile.credit.username }}
                  </p>
                  <span class="tw-text-gray-500 tw-text-sm tw-text-bold">
                    Signed in on {{ profile.credit.signedInOn }}
                  </span>
                </div>
                <div class="tw-w-2/5 tw-mx-auto tw-mb-4">
                  <v-btn
                    color="primary"
                    class="tw-w-full"
                    large
                    rounded
                    :disabled="isLoading"
                    @click="logoutSmartCredit"
                  >
                    Sign Out
                  </v-btn>
                </div>
              </div> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          v-if="profile.credit && profile.credit.hasCreditReport"
          class="tw-p-4"
        >
          <p style="color: red; font-size: 14px" v-if="viewReportErr">
            Error showing report, Please try later!
          </p>
          <v-btn
            outlined
            :disabled="isLoading"
            large
            color="primary"
            class="tw-w-full tw-mb-2"
            @click="handleViewReport"
          >
            View Report
          </v-btn>
          <router-link :to="'/repair-center/' + $route.params.id">
            <v-btn
              color="primary"
              :disabled="isLoading"
              large
              class="tw-w-full tw-mb-2"
            >
              Move to Repair center
            </v-btn>
          </router-link>
          <v-btn
            color="primary"
            outlined
            :disabled="isLoading"
            @click="switchProvider = !switchProvider"
            large
            class="tw-w-full"
          >
            CHANGE CREDIT PROVIDER
          </v-btn>
          <p class="caption">
            Note*: Changing the credit provider will disconnect the current
            credit provider
          </p>
        </div>
        <ProfileDialogue
          :dialogueVariant="dialogueVariant"
          :errorMessage="dialogueError"
          ref="successDialog"
        />
      </div>
    </div>
  </div>
  <!-- Alert -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextWithBadge from "@/components/global/TextWithBadge.vue";
import FormItem from "@/components/global/FormItem.vue";
import Loader from "@/components/global/Loader.vue";
import ProfileDialogue from "@/components/pages/profile/ProfileDialogue.vue";
import moment from "moment";

export default {
  components: { FormItem, TextWithBadge, Loader, ProfileDialogue },
  data() {
    return {
      activeCredit: null,
      profile: {
        state: {
          name: "",
        },
      },
      profileCheck: {},
      isIdLoading: false,
      profileId: null,
      ssnDocs: {
        data: null,
        isUploaded: false,
        error: false,
      },
      poaDocs: {
        data: null,
        isUploaded: false,
        error: false,
      },
      idOrLicenseDocs: {
        data: null,
        isUploaded: false,
        error: false,
      },
      identityIq: {
        username: "",
        password: "",
        sqa: "",
      },
      myFreeScoreNow: {
        username: "",
        password: "",
        sqa: "",
      },
      smartCredit: {
        username: "",
        password: "",
        sqa: "",
      },
      activeCreditAccount: -1,
      creditProviderId: {
        myFreeScoreNow: "MyFreeScoreNow",
        IdentityIQ: "IdentityIQ",
        SmartCredit: "SmartCredit",
      },
      activeCreditAccountInfo: {},
      documentsClass: {
        default: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
        success:
          "tw-border-solid tw-bg-success tw-bg-opacity-10 tw-border-green-400",
        error: "tw-border-solid tw-bg-error tw-bg-opacity-10 tw-border-error",
        ssnDocs: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
        poaDocs: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
        idOrLicenseDocs: "tw-border-dashed tw-bg-light-blue tw-border-gray-400",
      },
      IdentityIQLoading: false,
      MyFreeScoreNowLoading: false,
      SmartCreditLoading: false,
      dialogueVariant: null,
      dialogueError: null,
      ssn: "",
      isVisibleSsn: true,
      dob: "",
      streetAddress: "",
      isVisibleDob: true,
      isVisibleStreet: true,
      onboardingKey: "",
      dobError: false,
      viewReportErr: false,
      switchProvider: false,
    };
  },
  computed: {
    ...mapGetters({
      states: "profile/states",
      isLoading: "profile/isLoading",
      errorFetching: "profile/errorFetching",
    }),
    emailRules() {
      return [
        (value) => !!value || "Email is required",
        (value) => /^\S+@\S+\.\S+$/.test(value) || "Email is not valid",
      ];
    },
    maxDate() {
      const minAge = 18;
      const today = new Date();
      const minDate = new Date(
        today.getFullYear() - minAge,
        today.getMonth(),
        today.getDate()
      );
      return minDate.toISOString().slice(0, 10);
    },
    dobRules() {
      return [
        (v) => {
          let now = new Date();
          let dob = new Date(v);
          let age = now.getFullYear() - dob.getFullYear();
          if (age < 18) {
            this.dobError = true;
            return "You must be at least 18 years old";
          }
          this.dobError = false;
          return true;
        },
      ];
    },
    CredifiedProfileNameLabel() {
      return `Credified ${this.$t("profile.Profile")} ${this.$t(
        "profile.Name"
      )}`;
    },
    FirstNameLabel() {
      return "First " + this.$t("profile.Name");
    },
    LastNameLabel() {
      return "Last " + this.$t("profile.Name");
    },
    EmailAddressLabel() {
      return this.$t("profile.Email");
    },
    StateLabel() {
      return this.$t("profile.State");
    },
    CityLabel() {
      return this.$t("profile.City");
    },
    ZipLabel() {
      return this.$t("profile.Zip");
    },
    UserNameLabel() {
      return this.$t("homepage.Username");
    },
    visibilityIconSSN() {
      return this.isVisibleSsn ? "mdi-eye" : "mdi-eye-off";
    },
    maskedSsn: {
      get() {
        if (!this.isVisibleSsn) {
          return `***-**-${this.ssn.substr(this.ssn.length - 4)}`;
        }
        return this.ssn;
      },
      set(value) {
        this.ssn = value;
      },
    },
    visibilityIconDOB() {
      return this.isVisibleDob ? "mdi-eye" : "mdi-eye-off";
    },
    maskedDob: {
      get() {
        if (!this.isVisibleDob) {
          return `**/**/${this.dob.substr(this.dob.length - 4)}`;
        }
        return this.dob;
      },
      set(value) {
        this.dob = value;
      },
    },
    visibilityIconStreet() {
      return this.isVisibleStreet ? "mdi-eye" : "mdi-eye-off";
    },
    maskedStreet: {
      get() {
        if (!this.isVisibleStreet) {
          return `***${this.streetAddress.substr(
            this.streetAddress.length - 4
          )}`;
        }
        return this.streetAddress;
      },
      set(value) {
        this.streetAddress = value;
      },
    },
    AppType() {
      return window.VUE_APP_APP_TYPE;
    },
  },

  async mounted() {
    const { id } = this.$route.params;
    await this.getProfile(id).then((res) => {
      this.profile = res[0];
      this.profileCheck = res[0];
      console.log("profileCheck", this.profileCheck);
      this.ssn = this.profile.ssn;
      this.maskedSsn = this.profile.ssn;
      this.maskedDob = this.profile.dob;
      this.dob = this.profile.dob;
      this.streetAddress = this.profile.streetAddress;
      this.onboardingKey = this.profile.onboardingKey;
      this.setActivePanel(this.profile.credit);
      // setting the username and password
      console.log("this.profile.credit", this.profile.creditType);
      switch (this.profile.credit.creditType) {
        case "MyFreeScoreNow":
          this.myFreeScoreNow.username = this.profile.credit.username;
          this.myFreeScoreNow.password = this.profile.credit.password;
          break;
        case "IdentityIQ":
          this.identityIq.username = this.profile.credit.username;
          this.identityIq.password = this.profile.credit.password;
          break;
        case "SmartCredit":
          this.smartCredit.username = this.profile.credit.username;
          this.smartCredit.password = this.profile.credit.password;
          break;
        default:
          break;
      }

      this.documentsClass.ssnDocs =
        this.profile.ssnDocument && this.documentsClass.success;
      this.documentsClass.idOrLicenseDocs =
        this.profile.idOrDriverDocument && this.documentsClass.success;
      this.documentsClass.poaDocs =
        this.profile.addressDocument && this.documentsClass.success;
    });
    this.getStates();
  },
  methods: {
    ...mapActions({
      getProfile: "profile/getProfile",
      getStates: "profile/getStates",
      editProfile: "profile/editProfile",
      viewReports: "profile/viewReports",
      loadSingleCreditReport: "attackCenter/loadSingleCreditReport",
      uploadDocs: "cdn/uploadDocs",
      uplaodPiiImage: "cdn/uplaodPiiImage",
      uplaodSSNImage: "cdn/uplaodSSNImage",
      uplaodIDImage: "cdn/uplaodIDImage",
      identityLogin: "creditProdiver/identityIqLogin",
      identityLogout: "creditProdiver/identityIqLogout",
      initialLogin: "creditProdiver/myFreeScoreNowLogin",
      initalLogout: "creditProdiver/myFreeScoreNowLogout",
      smartLogin: "creditProdiver/smartCreditLogin",
      smartLogout: "creditProdiver/smartCreditLogout",
    }),
    refreshMyFreeScoreNow() {
      this.myFreeScoreNowLogin();
    },
    refreshIdentityIQ() {
      this.identityIQLogin();
    },
    refreshSmartCredit() {
      this.smartCreditLogin();
    },
    callHandler(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    edit() {
      this.isIdLoading = true;
      const { id } = this.$route.params;
      this.editProfile({
        id,
        ...this.profile,
        dob: moment(this.maskedDob).format("YYYY-MM-DD"),
        ssn: this.ssn,
        streetAddress: this.streetAddress,
        state: this.profile?.state?.name,
        ssnDocs: this.ssnDocs.data,
        poaDocs: this.poaDocs.data,
        idDocs: this.idOrLicenseDocs.data,
      }).then((res) => {
        this.profileId = res.id;
      });
      this.isIdLoading = false;
    },
    async uploadSsn() {
      // if (this.profileId) {
      let files = this.$refs.ssnUploader.files;
      let formData = new FormData();
      formData.append("ssnDocs", files[0]);
      this.ssnDocs.data = formData;
      try {
        let data = { profileId: this.$route.params.id, data: formData };
        const result = await this.uplaodSSNImage(data).then((res) => res);
        if (result.ok) {
          this.ssnDocs.isUploaded = true;
          this.ssnDocs.error = false;
          this.documentsClass.ssnDocs = this.documentsClass.success;
        } else {
          this.ssnDocs.isUploaded = false;
          this.ssnDocs.error = true;
          this.documentsClass.ssnDocs = this.documentsClass.error;
        }
      } catch (error) {
        console.error("ERROR", error);
        this.ssnDocs.isUploaded = false;
        this.ssnDocs.error = true;
        this.documentsClass.ssnDocs = this.documentsClass.error;
      }
    },
    async uploadPoa() {
      // if (this.profileId) {
      let files = this.$refs.poaUploader.files;
      let formData = new FormData();
      formData.append("POA", files[0]);
      this.poaDocs.data = formData;
      try {
        let data = { profileId: this.$route.params.id, data: formData };
        const result = await this.uplaodPiiImage(data).then((res) => res);
        if (result.ok) {
          this.poaDocs.isUploaded = true;
          this.poaDocs.error = false;
          this.documentsClass.poaDocs = this.documentsClass.success;
        } else {
          this.poaDocs.isUploaded = false;
          this.poaDocs.error = true;
          this.documentsClass.poaDocs = this.documentsClass.error;
        }
      } catch (error) {
        console.error("ERROR", error);
        this.poaDocs.isUploaded = false;
        this.poaDocs.error = true;
        this.documentsClass.poaDocs = this.documentsClass.error;
      }
    },
    async uploadIdOrDriverLicense() {
      let files = this.$refs.idOrDriverLicense.files;
      let formData = new FormData();
      formData.append("idOrLicenseDocs", files[0]);
      this.idOrLicenseDocs.data = formData;
      try {
        let data = { profileId: this.$route.params.id, data: formData };
        const result = await this.uplaodIDImage(data).then((res) => res);
        if (result.ok) {
          this.idOrLicenseDocs.isUploaded = true;
          this.idOrLicenseDocs.error = false;
          this.documentsClass.idOrLicenseDocs = this.documentsClass.success;
        } else {
          this.idOrLicenseDocs.isUploaded = false;
          this.idOrLicenseDocs.error = true;
          this.documentsClass.idOrLicenseDocs = this.documentsClass.error;
        }
      } catch (error) {
        console.error("ERROR", error);
        this.idOrLicenseDocs.isUploaded = false;
        this.idOrLicenseDocs.error = true;
        this.documentsClass.idOrLicenseDocs = this.documentsClass.error;
      }
    },
    async identityIQLogin() {
      this.IdentityIQLoading = true;
      try {
        const res = await this.identityLogin({
          identityIq: this.identityIq,
          id: this.$route.params.id,
        });
        if (res.ok) {
          this.dialogueVariant = "success";
          this.$refs.successDialog.dialog = true;
          this.activeCreditAccount = "IdentityIQ";
          this.activeCreditAccountInfo = res;
        } else {
          this.dialogueVariant = "error";
          this.dialogueError = res?.errors;
          this.$refs.successDialog.dialog = true;
        }
      } catch (error) {
        console.log("in catch", error);
        this.dialogueVariant = "error";
        this.dialogueError = error?.errors;
        this.$refs.successDialog.dialog = true;
      }
      this.IdentityIQLoading = false;
    },
    logoutIdentityIq() {
      this.identityLogout().then((res) => {
        if (res.result) {
          this.activeCreditAccount = null;
          this.activeCreditAccountInfo = { res };
        }
      });
    },
    async myFreeScoreNowLogin() {
      this.MyFreeScoreNowLoading = true;
      try {
        const res = await this.initialLogin({
          myFreeScoreNow: this.myFreeScoreNow,
          id: this.$route.params.id,
        });
        if (res.ok) {
          this.dialogueVariant = "success";
          this.$refs.successDialog.dialog = true;
          this.activeCreditAccount = "MyFreeScoreNow";
          this.activeCreditAccountInfo = res;
        } else {
          this.dialogueVariant = "error";
          this.dialogueError = res?.errors;
          this.$refs.successDialog.dialog = true;
        }
      } catch (error) {
        this.dialogueVariant = "error";
        this.dialogueError = error?.errors;
        this.$refs.successDialog.dialog = true;
      }
      this.MyFreeScoreNowLoading = false;
    },
    logoutmyFreeScoreNow() {
      this.initalLogout().then((res) => {
        if (res.result) {
          this.activeCreditAccount = null;
          this.activeCreditAccountInfo = { res };
        }
      });
    },
    async smartCreditLogin() {
      this.SmartCreditLoading = true;
      try {
        const res = await this.smartLogin({
          smartCredit: this.smartCredit,
          id: this.$route.params.id,
        });
        if (res.ok) {
          this.dialogueVariant = "success";
          this.$refs.successDialog.dialog = true;
          this.activeCreditAccount = "SmartCredit";
          this.activeCreditAccountInfo = res;
        } else {
          this.dialogueVariant = "error";
          this.dialogueError = res.errors;
          this.$refs.successDialog.dialog = true;
        }
      } catch (error) {
        this.dialogueVariant = "error";
        this.dialogueError = error?.errors;
        this.$refs.successDialog.dialog = true;
      }
      this.SmartCreditLoading = false;
    },
    logoutSmartCredit() {
      this.smartLogout().then((res) => {
        if (res.result) {
          this.activeCreditAccount = null;
          this.activeCreditAccountInfo = { res };
        }
      });
    },
    async handleViewReport() {
      this.viewReportErr = false;
      const creditReport = await this.loadSingleCreditReport({
        id: this.$route.params.id,
      });
      if (creditReport[0].id) {
        const report = await this.viewReports({
          profileId: this.$route.params.id,
          reportId: creditReport[0].id,
        });

        if (report) {
          this.viewReportErr = false;
          let newTab = window.open();
          let htmlContent = `<html><head><title>Report Page</title></head><body>${report}</body></html>`;
          newTab.document.write(htmlContent);
          newTab.document.close();
        } else {
          this.viewReportErr = true;
        }
      }
    },
    setActivePanel(creditData) {
      if (creditData.creditType === "IdentityIQ") {
        this.activeCreditAccount = this.creditProviderId.IdentityIQ;
        this.identityIq.username = creditData.username;
        this.identityIq.password = creditData.password;
      } else if (creditData.creditType === "myFreeScoreNow") {
        this.activeCreditAccount = this.creditProviderId.myFreeScoreNow;
        this.myFreeScoreNow.username = creditData.username;
        this.myFreeScoreNow.password = creditData.password;
      } else if (creditData.creditType === "SmartCredit") {
        this.activeCreditAccount = this.creditProviderId.SmartCredit;
        this.smartCredit.username = creditData.username;
        this.smartCredit.password = creditData.password;
      }
    },
    toggleVisibilitySSN() {
      this.isVisibleSsn = !this.isVisibleSsn;
    },
    toggleVisibilityDOB() {
      this.isVisibleDob = !this.isVisibleDob;
    },
    toggleVisibilityStreet() {
      this.isVisibleStreet = !this.isVisibleStreet;
    },
    copyOnboardingKeyHandler() {
      this.$refs.onboardingKey.focus();
      document.execCommand("copy");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    background-color: #f6fbff !important;
    border-color: #dfe7ec !important;
  }
  .v-expansion-panel-header--active {
    border-bottom: 1px solid #dfe7ec !important;
  }
  .v-expansion-panel-content__wrap {
    padding-top: 1rem !important;
  }
  .capitalize input {
    text-transform: capitalize !important;
  }

  .custom-date-picker {
    display: inline-block;
    position: relative;
  }

  .vdp-datepicker {
    background-color: #f6fbff !important;
    width: 100%;
    border: 1px solid #989c9e !important;
    font-family: "Inter", sans-serif;
    border-radius: 6px;
    padding: 15px 10px;
    font-size: 16px;
  }

  .custom-date-picker {
    width: 100%;
  }

  #datepicker {
    width: 100%;
  }

  #datepicker > input {
    background-color: #f6fbff !important;
    width: 100%;
    border-color: #989c9e !important;
    font-family: "Inter", sans-serif;
    border-radius: 6px;
    padding: 27px 10px;
    font-size: 16px;
  }
}
</style>
