<template>
  <div class="tw-text-sm tw-font-bold">
    <v-menu v-model="menu" :close-on-content-click="true" offset-y>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <div v-if="status === 'Onboarding'" class="tw-flex flex-row">
            <span
              class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-2.5 tw-mr-1"
              style="background-color: #0c48af"
            />
            <div class="tw-text-md" style="color: #0c48af">
              {{ status }}
            </div>
          </div>
          <div v-else-if="status === 'Waiting'" class="tw-flex flex-row">
            <span
              class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-2.5 tw-mr-1"
              style="background-color: #ff9700"
            />
            <div class="tw-text-md" style="color: #ff9700">
              {{ status }}
            </div>
          </div>
          <div v-else-if="status === 'Needs Review'" class="tw-flex flex-row">
            <span
              class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-2.5 tw-mr-1"
              style="background-color: #ff0000"
            />
            <div class="tw-text-md" style="color: #ff0000">
              {{ status }}
            </div>
          </div>
          <div v-else-if="status === 'Finished'" class="tw-flex flex-row">
            <span
              class="tw-min-w-0.5 tw-w-0.5 tw-rounded-full tw-px-2.5 tw-mr-1"
              style="background-color: #26b400"
            />
            <div class="tw-text-md tw-text-gray-500" style="color: #26b400">
              {{ status }}
            </div>
          </div>
        </div>
      </template>

      <!-- <v-card class="tw-p-3">
        <v-btn-toggle
          v-model="activeStatus"
          dense
          class="tw-w-full"
          active-class="primary"
          rounded
        >
          <v-btn class="tw-w-1/3 tw-text-lg">
            <div class="tw-pr-1" />
            Not Active
          </v-btn>
          <v-btn class="tw-w-1/3 tw-text-lg">
            <div class="tw-pr-1" />
            On Hold
          </v-btn>
          <v-btn class="tw-w-1/3 tw-text-lg">
            <div class="tw-pr-1" />
            Active
          </v-btn>
        </v-btn-toggle>
      </v-card> -->
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    activeStatus: 1,
    menu: true,
  }),
  computed: {
    currentState() {
      switch (this.activeStatus) {
        case 0:
          return "Not Active";
        case 1:
          return "On Hold";
        case 2:
          return "Active";
        default:
          break;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-btn--active {
    .v-btn__content {
      color: white !important;
    }
  }
  .v-btn--has-bg {
    background: #ffffff !important;
  }
}
</style>
