export default {
  namespaced: true,
  state() {
    return {
      isOpen: false,
      isSidebarOpen: false,
      isNotesOpen: false,
    };
  },
  getters: {
    isOpen(state) {
      return state.isOpen;
    },
    isSidebarOpen(state) {
      return state.isSidebarOpen;
    },
    isNotesOpen(state) {
      return state.isNotesOpen;
    },
  },
  mutations: {
    toggleDrawer(state, value) {
      return (state.isOpen = value);
    },
    toggleSidebar(state, value) {
      return (state.isSidebarOpen = value);
    },
    toggleNotesDrawer(state, value) {
      return (state.isNotesOpen = value);
    },
  },
  actions: {},
};
