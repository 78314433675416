<template lang="">
  <div class="text-center">
    <v-dialog v-model="dialog" width="400">
      <v-card>
        <v-card-title class="text-h5 tw-text-center tw-block lighten-2">
          <img
            src="../../assets/icons/verify-phone.svg"
            class="tw-mx-auto tw-h-44 tw-my-2"
          />
          <h1
            class="tw-text-primary font-weight-bold"
            style="font-size: 1.75rem"
          >
            Enter Your Number
          </h1>
          <p class="tw-text-grey font-weight-light" style="font-size: 1rem">
            Please enter your country code & your phone number
          </p>
        </v-card-title>
        <div class="tw-px-12 tw-pt-4 tw-text-center tw-block lighten-2">
          <div class="tw-w-full">
            <p class="tw-text-sm tw-text-gray text-left">Phone Number</p>
            <div>
              <v-text-field
                v-model="phoneNumber"
                v-mask="'+1(###)-###-####'"
                outlined
              ></v-text-field>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            :disabled="phoneNumber.length < 16"
            @click="sendPhoneNumberHandler"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {
      dialog: false,
      phoneNumber: "",
    };
  },
  methods: {
    ...mapActions({
      sendPhoneNumber: "setting/sendPhoneNumber",
    }),
    async sendPhoneNumberHandler() {
      console.log("run");
      await this.sendPhoneNumber(this.phoneNumber);
      this.dialog = false;
      // try {
      //   console.log("res", res);
      // } catch (error) {
      //   console.log("error", error);
      // }
    },
  },
};
</script>
<style lang=""></style>
