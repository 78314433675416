<template>
  <v-app class="tw-overflow-hidden">
    <v-app-bar
      app
      color="custom-gray"
      height="80"
      class="tw-border-b tw-border-black"
      flat
    >
      <head-layout class="tw-hidden md:tw-block" />
      <xs-head-layout class="tw-block md:tw-hidden" />
    </v-app-bar>
    <xs-sidebar-menu />
    <sidebar-menu />
    <v-main class="custom-gray">
      <router-view class="tw-h-full tw-m-3 px-2 py-2 md:px-5 md:py-5" />
    </v-main>
    <v-navigation-drawer
      v-model="isDrowerOpen"
      absolute
      right
      overlay-opacity="0.9"
      width="400"
      temporary
    >
      <div class="tw-bg-gray-300 tw-h-screen">
        <div class="tw-px-5 tw-py-4">
          <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <v-btn depressed @click="toggleNotificationDrawer(false)">
              <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
            <p class="tw-font-bold tw-text-lg">Alerts</p>
            <v-btn depressed @click="toggleNotificationDrawer(false)">
              <v-icon> mdi-menu </v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider class="tw-mt-2 tw-mb-3" />
        <div v-if="notifications.length <= 0" class="tw-text-center">
          No Data Exist
        </div>
        <div v-else>
          <div
            v-for="(notif, index) in notifications"
            :key="index"
            class="tw-px-4 tw-mb-3"
          >
            <div
              class="tw-flex tw-flex-row tw-justify-between tw-p-3 tw-rounded-lg"
              :class="[
                notif.type === 'negative'
                  ? 'tw-bg-error tw-bg-opacity-10'
                  : notif.type === 'positive'
                  ? 'tw-bg-success tw-bg-opacity-10'
                  : 'tw-bg-primary tw-bg-opacity-10',
              ]"
            >
              <div class="tw-w-1/6">
                <img
                  v-if="notif.type === 'positive'"
                  src="./assets/icons/positiveIcon.svg"
                  alt=""
                />
                <img
                  v-else-if="notif.type === 'negative'"
                  src="./assets/icons/negetiveIcon.svg"
                  alt=""
                />
                <img
                  v-else-if="notif.type === 'general'"
                  src="./assets/icons/generalIcon.svg"
                  alt=""
                />
              </div>
              <div class="tw-w-5/6">
                <p class="tw-font-extrabold tw-text-lg tw-text-black">
                  {{ notif.title }}
                </p>
                <p class="tw-text-black tw-font-bold tw-text-sm">
                  {{ notif.description }}
                </p>
                <span class="tw-text-sm tw-text-gray-500">
                  {{ notif.createdAt | fullDate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <notes-drawer />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import SidebarMenu from "./components/global/SidebarMenu.vue";
import XsSidebarMenu from "./components/global/xs/SidebarMenu.vue";
import HeadLayout from "./components/layout/HeadLayout.vue";
import XsHeadLayout from "./components/layout/xs/HeadLayout.vue";
import 'guidechimp/dist/guidechimp.min.css';
import NotesDrawer from "./components/global/NotesDrawer.vue";

export default {
  components: { SidebarMenu, HeadLayout, XsHeadLayout, XsSidebarMenu, NotesDrawer },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isOpen: "drawer/isOpen",
      notifications: "notification/notifications",
    }),
    isDrowerOpen: {
      get() {
        return this.isOpen;
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    ...mapMutations({
      toggleDrawer: "drawer/toggleDrawer",
    }),
    ...mapActions({
      loadNotifications: "notification/loadNotifications",
    }),
    toggleNotificationDrawer(value) {
      this.toggleDrawer(value);
    },
  },
};
</script>

<style lang="scss"></style>
