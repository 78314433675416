<template lang="">
  <v-navigation-drawer
    v-model="isDrowerOpen"
    absolute
    right
    overlay-opacity="0.9"
    width="400"
    temporary
  >
    <div class="tw-bg-gray-300 tw-h-screen">
      <div class="tw-px-5 tw-py-4">
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <v-btn depressed @click="toggleNotificationDrawer(false)">
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
          <p class="tw-font-bold tw-text-lg">Notes</p>
          <v-btn depressed @click="toggleNotificationDrawer(false)">
            <v-icon> mdi-menu </v-icon>
          </v-btn>
        </div>
      </div>
      <v-divider class="tw-mt-2 tw-mb-3" />
      <div>
        <div class="tw-w-full md:tw-pr-3">
          <div class="tw-mx-8 tw-flex tw-items-center tw-space-x-4">
            <v-text-field v-model="notes" label="Enter Note"></v-text-field>
            <v-btn depressed color="primary" small @click="addNoteHandler">
              Add Note
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="notesArr && notesArr.length < 1" class="tw-text-center">
        No Notes Added Yet
      </div>
      <div v-else>
        <div
          v-for="(note, index) in notesArr"
          :key="index"
          class="tw-px-4 tw-mb-3"
        >
          <div
            class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-3 tw-rounded-lg tw-bg-[#e4e4e4]"
          >
            <div class="tw-w-1/6">
              <img
                src="../../assets/icons/generalIcon.svg"
                alt=""
                style="width: 70%; height: 100%"
              />
            </div>
            <div class="tw-w-5/6">
              <p class="tw-text-black tw-font-regular tw-text-sm">
                {{ note }}
              </p>
            </div>
            <v-icon @click="deleteNoteHandler(index)">mdi-close</v-icon>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      notesArr: [],
      notes: "",
    };
  },
  computed: {
    ...mapGetters({
      isNotesOpen: "drawer/isNotesOpen",
      notifications: "notification/notifications",
    }),
    isDrowerOpen: {
      get() {
        return this.isNotesOpen;
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    this.loadNotifications();
    this.notesArr = JSON.parse(localStorage.getItem("notes")) || [];
  },
  methods: {
    ...mapMutations({
      toggleNotesDrawer: "drawer/toggleNotesDrawer",
    }),
    ...mapActions({
      loadNotifications: "notification/loadNotifications",
    }),
    toggleNotificationDrawer(value) {
      this.toggleNotesDrawer(value);
    },
    addNoteHandler() {
      if (this.notes.trim() !== "") {
        this.notesArr.push(this.notes);
        localStorage.setItem("notes", JSON.stringify(this.notesArr));
        this.notes = "";
      }
    },
    deleteNoteHandler(index) {
      this.notesArr.splice(index, 1);
      localStorage.setItem("notes", JSON.stringify(this.notesArr));
    },
  },
};
</script>
