import axios from "@/axios.js";

export default {
  namespaced: true,
  state() {
    return {
      notifications: [],
    };
  },
  getters: {
    notifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    async loadNotifications(context) {
      try {
        const response = await axios.get("/api/v1/notifications");
        context.commit("SET_NOTIFICATIONS", response.data);
        return response.data;
        // const response = await fetch("/api/v1/notifications", {
        //   method: "GET",
        // });
        // const responseData = await response.json();
        // context.commit("SET_NOTIFICATIONS", responseData);
        // return responseData;
      } catch (error) {
        return console.warn(error);
      }
    },
  },
};
