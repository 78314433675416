<template>
  <div>
    <img
      src="../assets/icons/credit-analysis-coming-soon.svg"
      class="tw-mx-auto tw-h-96 tw-my-5"
    />
    <!-- <div class="tw-mb-3">
      <div
        class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-space-x-4"
      >
        <form-item
          type="select"
          class="tw-w-full md:tw-w-1/3"
          label="Analysis Type"
          :items="analysis"
          item-text="name"
          item-value="id"
          :loading="loading"
          place-holder="Type Goes Here"
          @input="analysisId = $event"
        />
        <form-item
          type="select"
          class="tw-w-full md:tw-w-1/3"
          label="Clients"
          :items="clients.results"
          item-text="name"
          item-value="id"
          :loading="loading"
          place-holder="Jane Doe"
          @input="clientId = $event"
        />
        <form-item
          type="select"
          class="tw-w-full md:tw-w-1/3"
          label="Report Type"
          :items="reports"
          item-text="name"
          item-value="id"
          :loading="loading"
          place-holder="Type Goes Here"
          @input="reportId = $event"
        />
      </div>
      <div
        class="tw-w-full tw-flex-col md:tw-flex-row tw-justify-between tw-flex tw-pb-3"
      >
        <div class="tw-w-full md:tw-w-1/2 tw-mb-5 md:tw-mb-0 md:tw-pr-1.5">
          <v-btn
            large
            class="tw-w-full py-3"
            color="primary"
            @click="loadAnalysisHistory"
          >
            <v-icon> mdi-magnify </v-icon>
            Credit Analysis
          </v-btn>
        </div>
        <div class="tw-w-full md:tw-w-1/2 md:tw-pl-1.5">
          <v-btn large class="tw-w-full py-3" color="success">
            <v-icon> mdi-eye </v-icon>
            Preview Analysis
          </v-btn>
        </div>
      </div>
    </div>
    <v-card class="tw-p-5" flat>
      <div
        class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-align-center"
      >
        <p class="tw-font-bold tw-text-lg">Analysis History</p>
        <form-item
          dense
          prepend-inner-icon="mdi-magnify"
          place-holder="Search Clients"
        />
      </div>
      <custom-table
        :columns="columns"
        :data-tables="analysisHistory"
        :loading="isAnalysisLoading"
      />
    </v-card> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      columns: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          value: "mail",
        },
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        {
          text: "Analysis Type",
          align: "start",
          value: "type",
        },
        {
          text: "",
          align: "end",
          value: "more",
        },
      ],
      loading: true,
      analysisId: null,
      clientId: null,
      reportId: null,
      isAnalysisLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      analysis: "credit/analysis",
      reports: "credit/reports",
      profiles: "profile/profiles",
      analysisHistory: "credit/analysisHistory",
    }),
  },
  mounted() {
    this.loading = true;
    this.loadAnalysis();
    this.loadProfiles();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      loadAnalysis: "credit/loadAnalysis",
      loadProfiles: "profile/loadProfiles",
      filterAnalysisHistory: "credit/filterAnalysisHistory",
    }),
    loadAnalysisHistory() {
      this.isAnalysisLoading = true;
      this.filterAnalysisHistory({
        analysisId: this.analysisId,
        clientId: this.clientId,
        reportId: this.reportId,
      });
      this.isAnalysisLoading = false;
    },
  },
};
</script>

<style></style>
